import React from "react";
import { connect } from "react-redux";
import {
  guardarAplicacionExterna,
  editarAplicacionExterna,
} from "../../components/aplicacionExterna/AplicacionExternaService";
import {
  URL,
  getAuthorizationHeader,
  idEmpresa,
  getAuthorizationHeaderUpload,
} from "../../constans/constants";
import { exportarArchivo } from "../lotes/LoteService";
import axios from "axios";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { logout } from "../login/SesionService"
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import cloudUploadIcon from '../../styles/icons/cloud_upload.svg' 

class ImportarLote extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
    this.state = {
      descripcion: "",
      numeroPatronal: [],
      gruposUsuarios: [],
      tipoDocumento: [],
      lote: [],
      id: "",
      date: date,
      file: null,
      uploadDisabled: false,
      numeroPatronalID: "",
      tipoDocumentoID: "",
      idGrupo: "",
      isCarpetaPorDefecto: false,
      carpetaPorDefecto: "",
      fileErrorMsg: "",
      path: "",
      mensaje: "",
      severity: "",
      mostrarMensajeFlash: false,
      submit: false,
      openPantallaCarga: false,
      validated: false,
      requiredNroMtess: false,
      grupoCheckBox: false
    };
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleDialogImport = (value) => {
    this.setState({
      resultImportPopUp: value
    })
  }

  onChangeFile(event) {
    event.preventDefault();
    this.setState({
      file: event.target.files[0],
      size: event.target.files[0].size,
      filename: event.target.files[0].name,
      fileErrorMsg: ""
    });
  }

  onDrop = (files) => {
    this.setState({
      file: files[0],
      filename: files[0].name,
      fileErrorMsg: ''
    });
  }

  handleChecked = (event) => {
    var name = event.target.name;
    var val = event.target.checked;
    this.setState({
      [name]: val,
      idGrupo: ""
    });
  }

  checkFileExtension = () =>{
    if(this.state.file == null)
      return false;
    var fileName = this.state.file.name
    var extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    if(extension === "zip")
      return true;
    return false;
  }

  subirArchivo = event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true });
    } else {
      this.setState({ validated: true });
      const  data = {
        description: this.state.descripcion,
        file: null,
        filename: this.state.filename,
        empresaId: idEmpresa(),
        idGrupo: this.state.idGrupo,
        numeroPatronalId: this.state.numeroPatronalID,
        documentTypeId: this.state.tipoDocumentoID,
        serverFolder: this.state.carpetaPorDefecto
      }
      if (this.state.uploadDisabled) {
        axios
          .post(URL + "lote/import/form/server", data, getAuthorizationHeader())
          .then((res) => {
            this.props.handleClose(true, res.data);
          }, error => {
            if (error.response.status === 401) {
              logout();
            }
            return error;
          });
      } else {
        if(this.checkFileExtension()){
          this.setState({ openPantallaCarga: true });
          let formData = new FormData();
          formData.append("file", this.state.file);
          axios
            .post(URL + "lote/upload/", formData, getAuthorizationHeaderUpload())
            .then((res) => {
              if (res.data.error == false) {
                data.file = res.data.datos;
                axios
                  .post(URL + "lote/import/form/client", data, getAuthorizationHeader())
                  .then((res) => {
                    this.props.handleClose(true, res.data);
                  }, error => {
                    if (error.response.status === 401) {
                      logout();
                    }
                    return error;
                  });
              }
            });

        } else {
          this.setState({
            submit: true,
            fileErrorMsg: "Debe subir un archivo en formato .zip con el/los documentos dentro",
            file: null,
            filename: ""
          });
        }
      }
    }
  };


  componentDidMount() {
    axios
      .get(
        URL + "tipoDocumento/listar/" + idEmpresa(),
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ tipoDocumento: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
    axios
      .get(
        URL + "numeroPatronal/listar/" + idEmpresa(),
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ numeroPatronal: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
    axios
      .get(
        URL + "grupos/getAll/",
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ gruposUsuarios: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  }

  validarExtensionDocumento = () => {
    var datos = this.state.tipoDocumento;
    var extension = datos.find(tipo => tipo.id == this.state.tipoDocumentoID)
    if (extension) {
      if (extension.formato == "XML") {
        return true
      } else {
        return false
      }
    }
    return false
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };

  handleChangeSelect = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    var documento = this.state.tipoDocumento.find(element => element.id == val);
    if (documento)
      this.setState({
        [name]: val,
        isCarpetaPorDefecto: documento.carpetaPorDefecto != "" ? true : false,
        carpetaPorDefecto: documento.carpetaPorDefecto,
        uploadDisabled: documento.carpetaPorDefecto != "" ? true : false
      })
  }



  render() {
    return (

      <Container>
        <Form onSubmit={this.subirArchivo} noValidate validated={this.state.validated}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}> Nuevo lote de recibos </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col xs={6} md={6}>

                <Dropzone onDrop={this.onDrop} disabled={this.state.uploadDisabled}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="carga-documento-section">
                      <div className="carga-documento-content">
                        <img src={cloudUploadIcon}></img>
                        <section className="container">
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Arrasta un archivo hasta aqui.</p>
                            <p className="text-small"> O si prefieres.. </p>
                            <a class="local-upload btn btn-lg btn-primary-outline" data-toggle="tooltip" data-placement="top"
                              data-original-title="Desktop"> Selecciona un archivo de tu dispositivo
                            </a>
                          </div>
                          <div style={{ marginBottom: "5px" }}>
                            <p className="form-date" style={{ color: "#3eba28" }}><small>{this.state.filename}</small></p>
                          </div>
                          <div style={{ marginTop: "5px" }}>
                            <p className="form-date" style={{ color: "#f90415" }}><small>{this.state.fileErrorMsg}</small></p>
                          </div>
                        </section>
                      </div>
                    </div>
                  )}
                </Dropzone>


              </Col>
              <Col xs={6} md={6}>
                <p className="form-date"> <strong> Fecha de Carga:</strong> {this.state.date} </p>
                <Form.Group className="form-group md-input" style={{ marginBottom: '35px' }}>
                  <div className="form-group md-input">
                    <textarea
                      className="form-control md-form-control"
                      name="descripcion"
                      placeholder=" "
                      rows="3"
                      onChange={this.handleInput}
                      value={this.state.descripcion}
                      required
                    />
                    <Form.Label class="md-label"> Descripcion </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>

                </Form.Group>
                <Form.Group className="form-group md-input">
                  <Form.Select className="form-control md-select" name="tipoDocumentoID"
                    onChange={this.handleChangeSelect} value={this.state.tipoDocumentoID} required>
                    <option key="" value="" selected></option>
                    {this.state.tipoDocumento.map((elemento) => (
                      <option key={elemento.id} value={elemento.id}>
                        {elemento.nombre}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Label class="md-label"> Tipo de Documento: </Form.Label>
                </Form.Group>

                {
                  this.state.isCarpetaPorDefecto &&
                  <Form.Group className="form-group md-input">
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        name="carpetaPorDefecto"
                        variant="outlined"
                        onChange={this.handleInput}
                        value={this.state.carpetaPorDefecto}
                        required
                      />
                      <Form.Label class="md-label"> Carpeta por defecto </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                }

                {
                  this.validarExtensionDocumento() &&

                  <Form.Group className="form-group md-input">
                    <Form.Select className="form-control md-select" name="numeroPatronalID" id="numeroPatronalID"
                      onChange={this.handleInput} value={this.state.numeroPatronalID} required>
                      <option key="" value="" selected></option>
                      {this.state.numeroPatronal.map((elemento) => (
                        <option value={elemento.id} key={elemento.id}>{elemento.nombre}</option>
                      ))}
                    </Form.Select>
                    <Form.Label class="md-label"> Nro. MTESS patronal </Form.Label>
                  </Form.Group>


                }

                {
                  this.state.grupoCheckBox &&

                  <Form.Group className="form-group md-input">
                    <Form.Select className="form-control md-select" name="idGrupo" id="idGrupo"
                      onChange={this.handleInput} value={this.state.idGrupo} required>
                      <option key="" value="" selected></option>
                      {this.state.gruposUsuarios.map((elemento) => (
                        <option value={elemento.idGrupo} key={elemento.idGrupo}>{elemento.descripcion}</option>
                      ))}
                    </Form.Select>
                    <Form.Label class="md-label"> Seleccione un grupo </Form.Label>
                  </Form.Group>
                }

                <Form.Group className="form-group md-input">
                  <Form.Check
                    type="checkbox"
                    label="Envio grupal de documentos" name="grupoCheckBox"
                    onChange={this.handleChecked}
                    checked={this.state.grupoCheckBox} />
                </Form.Group>

              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.props.handleClose(false, null)} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button color="primary" type="submit">
              Importar lote
            </Button>
          </Modal.Footer>
          <PantallaCarga open={this.state.openPantallaCarga} />
        </Form>
      </Container>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  guardarAplicacionExterna: (value) => dispatch(guardarAplicacionExterna(value)),
  editarAplicacionExterna: (value) => dispatch(editarAplicacionExterna(value)),
  exportarArchivo: (file) => dispatch(exportarArchivo(file))
});

export default connect(null, mapDispatchToProps)(ImportarLote);
