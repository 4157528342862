import * as React from 'react';
import { Spinner, Modal, ModalBody} from 'react-bootstrap'

class PantallaCarga extends React.Component {

  constructor(props) {
    super(props);
    this.center = {
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)'
    }

  }

  render() {
    var mensaje = this.props.mensaje
    return (
      <Modal
        size="sm"
        //keepMounted
        show={this.props.open}
        aria-describedby="progress"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <ModalBody>
          <div className="row justify-content-center p-3">
            <div className="col-auto">
              <Spinner animation="border" />
            </div>
            <div className="col-auto d-flex align-items-center">
              <h6>{mensaje != null ? mensaje : "Procesando"}</h6>
            </div>
          </div>

        </ModalBody>
      </Modal>

      /*
      <div >
        <Modal
        keepMounted
        show={this.props.open}
        aria-describedby="progress">
          <div style={this.center}>
          <Spinner animation="border" />
          </div>
        </Modal>
      </div>
      */
    );
  }
}

export default PantallaCarga;