
const categories = [
  {
    id: 'Administracion',
    rol: ['admin'],
    url: '/admin/usuarios',
    raiz: '/admin',
    icon: 'manage_accounts'
  },
  /*     {
        id: 'Configuracion',
        rol: 'admin',
        children: [
          { id: 'Tipos de Documentos', icon: <DescriptionIcon/>, url: '/admin/configuracion/tipo-documento', rol: 'admin' },
          { id: 'Aplicaciones Externas', icon: <ExitToAppIcon /> ,url: '/admin/configuracion/aplicaciones-externas', rol: 'admin'},
          { id: 'Número MTESS Patronal', icon: <DialpadIcon /> ,url: '/admin/configuracion/numero-patronal', rol: 'admin'},
        ],
      }, */
  {
    id: 'Notificacion',
    rol: ['admin'],
    url: '/notificacion/configuracion-correo',
    raiz: '/notificacion',
    icon: 'notifications'
  },
];
export default categories;