import axios from 'axios';
import {
  SET_EMPRESAS,
  SET_LOADING
} from "../../constans/action-types";
import { URL, getAuthorizationHeader } from '../../constans/constants';
import { logout } from '../login/SesionService';

const defaultPage = 0;
const defaultSize = 5;
export const setEmpresas = payload => ({ type: SET_EMPRESAS, payload });
export const setLoading = payload => ({ type: SET_LOADING, payload });


export const getEmpresas = (page = defaultPage, size = defaultSize, search = "") => {
  return dispatch => {
    return axios.get(URL + "empresa/listar?page=" + page + "&size=" + size + "&search=" + search, getAuthorizationHeader())
      .then(res => {
        dispatch(setEmpresas({
          page: res.data.page.pageable.pageNumber,
          totalCount: res.data.page.totalElements,
          empresas: res.data.page.content
        }));
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  };
};

export const cargarImagen = (id) => {
  return dispatch => {
    return axios.get(URL + "empresa/cargarImagen/" + id, getAuthorizationHeader())
  }
}

export const guardarEmpresa = (empresa) => {
  return dispatch => {
    dispatch(setLoading(true));
    const json = {
      nombre: empresa.nombre,
      direccion: empresa.direccion,
      ruc: empresa.ruc,
      estado: empresa.estado
    };
    return axios.post(URL + 'empresa/guardar', json, getAuthorizationHeader())
      .then(response => {
        dispatch(setLoading(false));
        return response.data;
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  }
}

export const obtenerListaEmpresas = () => {
  return axios.get(URL + "empresa/listarEmpresas", getAuthorizationHeader())
    .then((res) => {
      return res.data
    });
}

export const editarEmpresa = (empresa) => {
  return dispatch => {
    dispatch(setLoading(true));
    const json = {
      nombre: empresa.nombre,
      direccion: empresa.direccion,
      ruc: empresa.ruc,
      estado: empresa.estado
    };
    return axios.post(URL + 'empresa/modificar/' + empresa.id, json, getAuthorizationHeader())
      .then(response => {

        dispatch(setLoading(false));
        return response.data;
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  }
}
