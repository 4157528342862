import React from "react";
import axios from "axios";
import {URL,getAuthorizationHeader} from '../../constans/constants';
import { Col, Container, Modal, Row, Button,Table,TableCell } from "react-bootstrap"
import {logout} from '../login/SesionService'

class EmpresaDatos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      direccion: "",
      ruc: "",
      estado: true,
      administradores: [],
      gestores: [],
      logoURL: null
    };
  }

  handleClose = () => {
    this.props.handleClose(false);
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleEmpresaSubmit = () => {
    if (this.props.type == "edit") {
      const json = {
        nombre: this.state.nombre,
        direccion: this.state.direccion,
        ruc: this.state.ruc,
        id: this.state.id,
        estado: this.state.estado,
      };
      this.props.editarEmpresa(json).then(() => {
        this.handleClose();
        window.location.reload();
      });
    } else if (this.props.type == "add") {
      const json = {
        nombre: this.state.nombre,
        direccion: this.state.direccion,
        ruc: this.state.ruc,
        id: this.state.id,
        estado: this.state.estado,
      };
      this.props.guardarEmpresa(json).then(() => {
        this.handleClose();
      });
    }
  };

  componentDidMount() {
    this.setState({
      nombre: this.props.empresa.nombre,
      direccion: this.props.empresa.direccion,
      ruc: this.props.empresa.ruc,
      id: this.props.empresa.id,
      estado: this.props.empresa.activo,
    });
    axios.get(URL+'empresa/listar/gestores/'+this.props.empresa.id,getAuthorizationHeader())
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ gestores: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
      axios.get(URL+'empresa/listar/admin/'+this.props.empresa.id,getAuthorizationHeader())
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ administradores: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
      axios.get(URL + "empresa/cargarImagen/" + this.props.empresa.id, getAuthorizationHeader())
      .then(res => {
        if (res.data.error !== true) {
          this.setState({
            urlLogo:"data:image/png;base64," + res.data
          })
        }
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      })
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };
  render() {
    return (
      <Container>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#0c56d0" }}> {this.props.dialogTitle} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
        <Row>
          <Col>
          <p className="form-date">
                  {" "}
                  <h5><strong>Datos Generales</strong></h5>
                </p>
          </Col>
        </Row>
        <Row>
        <Col xs={12} md={6}>
        <div className="info-doc">
            <div className="title">Nombre:</div>
            <div className="text">{this.state.nombre}</div>
        </div>
        <div className="info-doc">
            <div className="title">Dirección:</div>
            <div className="text">{this.state.direccion}</div>
        </div>
        <div className="info-doc">
            <div className="title">RUC:</div>
            <div className="text">{this.state.ruc}</div>
        </div>
        <div className="info-doc">
            <div className="title">Estado:</div>
            <div className="text">{this.state.estado ? "Activo" : "Inactivo"}</div>
        </div>
          </Col>
          <Col xs={12} md={6}>
          <div className="info-doc">
            <div className="title">Logo:</div>
            <img
              id="logoEmpresaImage"
              src={this.state.urlLogo} 
              alt="--Sin Logo--"
              style={{marginTop:'10px'}}
              />
        </div>
          </Col>
        </Row>
        <Row>
          <Col>
          <p className="form-date">
                  {" "}
                  <h5><strong>Administradores Asociados</strong></h5>
                </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Usuario</th>
                      <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                  {this.state.administradores.map((elemento) => (
                    <tr key={elemento.nombre}>
                      <td component="th" scope="row">
                        {elemento.nombre}
                      </td>
                      <td>{elemento.usuario}</td>
                      <td>{elemento.email}</td>
                    </tr>
                  ))}
                </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
          <p className="form-date">
                  {" "}
                  <h5><strong>Gestores Asociados</strong></h5>
                </p>
          </Col>
        </Row>
        <Row>
          <Col>
          <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Usuario</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
                  {this.state.gestores.map((elemento) => (
                    <tr key={elemento.nombre}>
                      <td component="th" scope="row">
                        {elemento.nombre}
                      </td>
                      <td>{elemento.usuario}</td>
                      <td>{elemento.email}</td>
                    </tr>
                  ))}
                </tbody>
            </Table>
          </Col>
        </Row>
        </div>
        </Modal.Body>
        </Container>
    );
  }
}

export default EmpresaDatos;
