import React from "react";

import { connect } from "react-redux";
import {
  editarEmpresa,
  guardarEmpresa,
} from "../../components/empresas/EmpresaService";
import {
  getConfiguracionCorreo,
  guardarConfiguracionCorreo
} from "../../components/correo/CorreoService";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Form, Row, Col, Button } from "react-bootstrap";

class Configuracion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      host: "",
      puerto: "",
      replyTo: "",
      alias: "",
      disponible: false,
      username: "",
      contraseña: "",
      mecanicoAutenticacion: "",
      authEnabled: false,
      sslEnabled: false,
      mensajeFlash: "",
      mostrarMensajeFlash: false,
      mensajeFlashSeverity: "",
      confirmContraseña: "",
      openPantallaCarga: false,
      validated: false
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  checkedChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  componentDidMount = () => {
    getConfiguracionCorreo().then(res => {
      if (!res.error) {
        this.setState({
          host: res.datos.host,
          puerto: res.datos.puerto,
          replyTo: res.datos.replyTo,
          alias: res.datos.alias,
          disponible: res.datos.disponible,
          username: res.datos.username,
          contraseña: res.datos.contraseña,
          mecanicoAutenticacion: res.datos.mecanicoAutenticacion,
          authEnabled: res.datos.authEnabled,
          sslEnabled: res.datos.sslEnabled,
          confirmContraseña: res.datos.contraseña
        });
      }
    });

  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ openPantallaCarga: true });
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true, openPantallaCarga: false})
    }else{
      this.setState({ validated: true })
      const json = {
        host: this.state.host,
        puerto: this.state.puerto,
        replyTo: this.state.replyTo,
        alias: this.state.alias,
        disponible: this.state.disponible,
        username: this.state.username,
        contraseña: this.state.contraseña,
        mecanicoAutenticacion: this.state.mecanicoAutenticacion,
        authEnabled: this.state.authEnabled,
        sslEnabled: this.state.sslEnabled,
      };

      if(json.contraseña !== this.state.confirmContraseña){
        this.setState({
          mensajeFlashSeverity: "danger",
          mensajeFlash: "Las contraseñas no coinciden ",
          mostrarMensajeFlash: true,
          openPantallaCarga: false
        })
      }else{
        guardarConfiguracionCorreo(json).then(response => {
          if (!response.error) {
            this.setState({ mensajeFlashSeverity: "success" });
          } else {
            this.setState({ mensajeFlashSeverity: "danger" });
          }
          this.setState({
            mensajeFlash: response.mensaje,
            mostrarMensajeFlash: true,
            openPantallaCarga: false
          });
        });
      }
    }
  }

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };

  render() {
    return (
      <div className="dashboardContent">
        <div className="container-fluid">
          <MensajeFlash
            mensaje={this.state.mensajeFlash}
            open={this.state.mostrarMensajeFlash}
            severity={this.state.mensajeFlashSeverity}
            handleClose={this.handleCloseMensajeFlash} />
          <div className="sectionHeader">
            <h1 className="pageTitle"> Notificacion </h1>
          </div>
          <div className="contentPage card" style={{ marginTop: "20px" }}>
            <div className="form-content">
              <Row>
                <Col xs={6} >
                  <h6>
                    CONFIGURACIÓN CORREO
                  </h6>
                </Col>
              </Row>
              <Form
                ref="form"
                onSubmit={this.handleSubmit}
                noValidate
                validated={this.state.validated}
              >
                <Row style={{ marginTop: '20px' }}>
                  <Col xs={6} style={{ paddingBottom: "20px" }}>
                    <Form.Group className="form-group md-input">
                      <input
                        label="Host"
                        name="host"
                        placeholder=" "
                        className="form-control md-form-control"
                        onChange={this.handleChange}
                        value={this.state.host}
                        required
                      />
                      <Form.Label className="md-label">Host</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6} style={{ paddingBottom: "20px" }}>
                    <Form.Group className="form-group md-input" >
                      <input
                        label="Puerto"
                        name="puerto"
                        placeholder=" "
                        className="form-control md-form-control"
                        onChange={this.handleChange}
                        value={this.state.puerto}
                        required
                      />
                      <Form.Label className="md-label">Puerto</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6} style={{ paddingBottom: "20px" }}>


                    <Form.Group className="form-group md-input">
                      <Form.Control
                        type="email"
                        name="replyTo"
                        placeholder=" "
                        className="form-control md-form-control"
                        onChange={this.handleChange}
                        value={this.state.replyTo}
                        required
                      />

                      <Form.Label className="md-label">Responder a</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Direccion de email no valida</small></p>
                      </Form.Control.Feedback>
                    </Form.Group>
                  
                  </Col>
                  <Col xs={6} style={{ paddingBottom: "20px" }}>
                    <Form.Group className="form-group md-input">
                      <input
                        label="Alias"
                        name="alias"
                        placeholder=" "
                        className="form-control md-form-control"
                        onChange={this.handleChange}
                        value={this.state.alias}
                        required
                      />
                      <Form.Label className="md-label">Alias</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="form-group md-input">
                      <Form.Check
                        name="disponible"
                        color="primary"
                        label="Disponible"
                        checked={this.state.disponible}
                        onChange={this.checkedChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <h6>
                      AUTENTICACIÓN
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} style={{ paddingBottom: "20px" }}>
                    <Form.Group className="form-group md-input">
                      <input
                        label="Usuario"
                        name="username"
                        placeholder=" "
                        className="form-control md-form-control"
                        onChange={this.handleChange}
                        value={this.state.username}
                        required
                      />
                      <Form.Label className="md-label">Usuario</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6} style={{ paddingBottom: "20px" }}>
                    <Form.Group className="form-group md-input">
                      <input
                        label="Mecanismos de autenticación"
                        name="mecanicoAutenticacion"
                        placeholder=" "
                        className="form-control md-form-control"
                        onChange={this.handleChange}
                        value={this.state.mecanicoAutenticacion}
                        disabled={!this.state.authEnabled}
                        required
                      />
                      <Form.Label className="md-label">Mecanismos de autenticación</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6} style={{ paddingBottom: "20px" }}>
                    <Form.Group className="form-group md-input">
                      <input
                        label="Contraseña"
                        name="contraseña"
                        placeholder=" "
                        className="form-control md-form-control"
                        value={this.state.contraseña}
                        onChange={this.handleChange}
                        type="password"
                        disabled={!this.state.authEnabled}
                        required
                      />
                      <Form.Label className="md-label">Contraseña</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6} style={{ paddingBottom: "20px" }}>
                    <Form.Group className="form-group md-input">
                      <input
                        label="Confirmar contraseña"
                        name="confirmContraseña"
                        placeholder=" "
                        className="form-control md-form-control"
                        value={this.state.confirmContraseña}
                        onChange={this.handleChange}
                        disabled={!this.state.authEnabled}
                        type="password"
                        required
                      />
                      <Form.Label className="md-label">Confirmar contraseña</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="form-group md-input">
                      <Form.Check
                        name="authEnabled"
                        color="primary"
                        label="Habilitar autenticación"
                        checked={this.state.authEnabled}
                        onChange={this.checkedChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="form-group md-input">
                      <Form.Check
                        name="sslEnabled"
                        color="primary"
                        label="Habilitar SSL"
                        checked={this.state.sslEnabled}
                        onChange={this.checkedChange}
                        disabled={!this.state.authEnabled} />
                    </Form.Group>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                    marginTop: '20px',
                    marginBottom: '20px',
                    marginRight: '20px'
                  }}
                >
                  <Button color="primary" type="submit">
                    Guardar
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <PantallaCarga open={this.state.openPantallaCarga} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  guardarEmpresa: (value) => dispatch(guardarEmpresa(value)),
  editarEmpresa: (value) => dispatch(editarEmpresa(value)),
});

export default connect(null, mapDispatchToProps)(Configuracion);
