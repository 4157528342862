import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getEmpresas } from "../empresas/EmpresaService";
import "../../styles/Empresa.css";
import DocumentDialogContent from "./Empresa";
import EmpresaDatos from "../empresas/EmpresaDatos";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import  Navbar  from '../home/Navbar';
import { Modal, Button } from "react-bootstrap";
import visibility from '../../styles/icons/visibility.svg';
import editIcon from '../../styles/icons/edit.svg'

class Empresas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: "Registrar nueva Empresa",
      dialogType: "add",
      submitText: "Guardar",
      disabledInput: false,
      selectedEmpresa: {
        nombre: "",
        direccion: "",
        ruc: "",
        activo: true,
        id: "",
      },
      dialogIsOpen: false,
      dialogIsOpenVerDatos: false,
      mensajeFlash: "",
      mostrarMensajeFlash: false
    };
    this.tableRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }

  handleDialog = (value, mensaje = "") => {
    if (value === false) {
      this.tableRef.current.onQueryChange();  //Para actualizar la grilla
      if (mensaje != "") {
        this.setState({
          mostrarMensajeFlash: true,
          mensajeFlash: mensaje
        });
      }
    }
    this.setState({ dialogIsOpen: value });
  };

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };

  handleDialogVerDatos = (value) => {
    if (value === false) {
      this.tableRef.current.onQueryChange();  //Para actualizar la grilla
    }
    this.setState({
      dialogIsOpenVerDatos: value,
    });
  };

  handleNewEmpresa = () => {
    this.setState(
      {
        dialogTitle: "Registrar nueva Empresa",
        selectedEmpresa: {
          nombre: "",
          direccion: "",
          ruc: "",
          activo: true,
          id: "",
        },
        dialogType: "add",
        submitText: "Guardar",
        disabledInput: false,
      },
      () => {
        this.handleDialog(true);
      }
    );
  };

  handleVerdatos = () => {
    this.setState(
      {
        dialogTitle: "Detalles Empresa",
        selectedEmpresa: {
          nombre: "",
          direccion: "",
          ruc: "",
          activo: true,
          id: "",
        },
        dialogType: "add",
        submitText: "Guardar",
        disabledInput: false,
      },
      () => {
        this.handleDialogVerDatos(true);
      }
    );
  };

  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
            <MensajeFlash
              mensaje={this.state.mensajeFlash}
              severity={"success"}
              open={this.state.mostrarMensajeFlash}
              handleClose={this.handleCloseMensajeFlash} />
            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.dialogIsOpen}
              onHide={this.handleDialog}
              backdrop="static"
              keyboard="false">
              <DocumentDialogContent
                empresa={this.state.selectedEmpresa}
                handleClose={this.handleDialog}
                type={this.state.dialogType}
                submitText={this.state.submitText}
                disabledInput={this.state.disabledInput}
                dialogTitle={this.state.dialogTitle}
              />
            </Modal>
            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.dialogIsOpenVerDatos}
              onHide={this.handleDialogVerDatos}
              backdrop="static"
              keyboard="false">
              <EmpresaDatos
                empresa={this.state.selectedEmpresa}
                handleClose={this.handleDialogVerDatos}
                type={this.state.dialogType}
                submitText={this.state.submitText}
                disabledInput={this.state.disabledInput}
                dialogTitle={this.state.dialogTitle}
              />
            </Modal>
            <div className="sectionHeader">
              <h1 className="pageTitle"> Empresas  </h1>
              <div className="actions">
                <Button
                  color="primary"
                  onClick={this.handleNewEmpresa}
                >
                  Nueva Empresa
                </Button>
              </div>
            </div>
            <Navbar
              menuActual={window.location.hash}
              seccionActual={'administracion'}
            />
            <div className="contentPage card" style={{ padding: '0px' }}>
              <MaterialTable
                title="Empresas"
                tableRef={this.tableRef}
                columns={[
                  { title: "Nombre", field: "nombre" },
                  { title: "Direccion", field: "direccion" },
                  { title: "RUC", field: "ruc" },
                  { title: "Estado", field: "activo", type: "boolean" },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    this.props.getEmpresas(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.empresaData.empresas,
                        page: this.props.empresaData.page,
                        totalCount: this.props.empresaData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  {
                    icon: () => <img src={editIcon} />,
                    tooltip: 'Editar Empresa',
                    onClick: (event, rowData) => {
                      this.setState({
                        dialogTitle: "Editar Empresa",
                        selectedEmpresa: rowData,
                        dialogType: "edit",
                        submitText: "Editar",
                        disabledInput: false
                      }, () => {
                        this.handleDialog(true);
                      });
                    }
                  },
                  {
                    icon: () => <img src={visibility} />,
                    tooltip: 'Ver',
                    onClick: (event, rowData) => {
                      this.setState({
                        dialogTitle: "Detalles Empresa",
                        selectedEmpresa: rowData,
                        dialogType: "edit",
                        submitText: "Editar",
                        disabledInput: false
                      }, () => {
                        this.handleDialogVerDatos(true);
                      });
                    }
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold",
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEmpresas: (page, size, search) => dispatch(getEmpresas(page, size, search)),
});

const mapStateToProps = (state) => ({
  empresaData: state.empresaData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Empresas);
