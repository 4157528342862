import React from "react";
import axios from "axios";
import {URL,getAuthorizationHeader} from '../../constans/constants';
import { Col, Container, Modal, Row, Button,Table,TableCell } from "react-bootstrap"
import {logout} from '../login/SesionService'

class GrupoUsuarioDatos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descripcion: "",
      activo: true,
      id: "",
      usuarios: [],
    };
  }

  handleClose = () => {
    this.props.handleClose(false);
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  componentDidMount() {
    this.setState({                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
      descripcion: this.props.grupo.descripcion,
      activo: this.props.grupo.activo,
      id: this.props.grupo.id,
      usuarios: this.props.grupo.miembros 
    });
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };
  render() {
    return (
      <Container>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#0c56d0" }}> {this.props.dialogTitle} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
        <Row>
          <Col>
          <p className="form-date">
                  {" "}
                  <h5><strong>Datos Generales</strong></h5>
                </p>
          </Col>
        </Row>
        <Row>
        <Col xs={12} md={6}>
        <div className="info-doc">
            <div className="title">Descripcion:</div>
            <div className="text">{this.state.descripcion}</div>
        </div>
        <div className="info-doc">
            <div className="title">Estado:</div>
            <div className="text">{this.state.activo ? "Activo" : "Inactivo"}</div>
        </div>
          </Col>
        </Row>
        <Row>
          <Col>
          <p className="form-date">
                  {" "}
                  <h5><strong>Usuarios Asociados</strong></h5>
                </p>
          </Col>
        </Row>
        <Row>
          <Col>
          <div style={{overflow:"auto", height:"300px"}}>
            <Table striped bordered hover size="sm" responsive="md">
                <thead>
                    <tr>
                      <th>Nombre Y Apellido</th>
                      <th>Usuario</th>
                      <th>C.I.</th>
                    </tr>
                </thead>
                <tbody>
                  {this.state.usuarios.map((elemento) => (
                    <tr key={elemento.nombre}>
                      <td component="th" scope="row">
                        {elemento.nombreApellido}
                      </td>
                      <td>{elemento.usuario}</td>
                      <td>{elemento.cedula}</td>
                    </tr>
                  ))} 
                </tbody>
            </Table>
            </div>
          </Col>
        </Row>
        </div>
        </Modal.Body>
        </Container>
    );
  }
}

export default GrupoUsuarioDatos;
