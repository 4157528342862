
import React from 'react';
import axios from "axios";
import { connect } from 'react-redux';
import { editarNumeroPatronal, guardarNumeroPatronal } from '../../components/numeroPatronal/NumeroPatronalService';
import { URL, getAuthorizationHeader } from '../../constans/constants';
import { Redirect } from 'react-router-dom';
import { Accordion, Button } from 'react-bootstrap';
import arrowBack from '../../styles/icons/arrow_back.svg'


class VistaPDF extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      /*
      recibo: "",
      idLote: "",
      */
      fechaCarga: "",
      empleado: "",
      estado: "",
      estadoMTESS: false,
      redirect: "",
      comentario: null
    }
    let pantallaAnterior = this.setPantallaAnterior();
  }

  handleClose = () => {
    this.props.handleClose(false);
  }

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleEmpresaSubmit = () => {
    if (this.props.type === "edit") {
      const json = {
        numeroPatronal: this.state.numeroPatronal,
        descripcion: this.state.descripcion,
        id: this.state.id
      };
      this.props.editarNumeroPatronal(json).then(() => {
        this.handleClose();
        window.location.reload();
      });
    } else if (this.props.type === "add") {
      const json = {
        numeroPatronal: this.state.numeroPatronal,
        descripcion: this.state.descripcion,
        id: this.state.id
      };
      this.props.guardarNumeroPatronal(json).then(() => {
        this.handleClose();
      });
    }
  }

  setPantallaAnterior = () => {
    this.pantallaAnterior = (localStorage.getItem("urlAnterior")).substring(localStorage.getItem("urlAnterior").indexOf("#"))
  }


  componentDidMount() {
    /*
    this.setState({
      recibo: this.props.match.params.idRecibo,
      idLote: this.props.match.params.idLote
    });
    */
    var urlComment = URL + "lote/" +
      this.props.match.params.lote + "/recibo/" +
      this.props.match.params.recibo + "/comment" //+
    //(this.props.location.state.redirect.includes('rechazados') ? '?rechazado=true' : '?rechazado=false')

    axios
      .get(urlComment, getAuthorizationHeader())
      .then(res => {
        if (!res.data.errors)
          this.setState({ comentario: res.data.datos })
      })

  }

  handleInput = (e) => {
    var name = e.target.name
    var val = e.target.value
    this.setState({
      [name]: val
    })
  }

  descargarRecibo = () => {
    window.location.href = URL + "recibo/lote/" + this.props.match.params.lote + "/recibo/" + this.props.match.params.recibo + "/descargar";
  }

  render() {
    var request = URL + 'recibo/lote/' + this.props.match.params.lote + '/recibo/' + this.props.match.params.recibo
    /*
    if (this.state.redirect != '') {
      return (<Redirect to={{
        pathname: this.state.redirect, state: {
          estado: this.props.location.state.estado,
          rowData: this.props.location.state.rowDataLote
        }
      }} />)
    }
    */
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
            <div className="sectionHeader" style={{ paddingBottom: '20px' }}>
              <h1 className="pageTitle"> Recibo nro. {this.props.match.params.recibo} </h1>
              <div className="actions">

                <Button
                  variant="success"
                  onClick={this.descargarRecibo}
                >
                  Descargar Recibo
                </Button>

              </div>
            </div>
            <div className="contentPage card">
              <a onClick={() => window.location.href = this.pantallaAnterior} type="Button" className="backPage">
                <img src={arrowBack}></img>
                volver a la página anterior
              </a>
              {/*
              <a onClick={()=> window.history.back()} type="Button" className="backPage">
                <span className="material-icons-outlined"> arrow_back </span>
                volver a la página anterior
              </a>
*/}
              {
                this.state.comentario != null &&

                <Accordion defaultActiveKey='0' alwaysOpen style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header><h6>Comentario</h6></Accordion.Header>
                    <Accordion.Body>
                      <small>
                        {this.state.comentario}
                      </small>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              }
              <div id="container-file">
                <div className="iframeVisor">
                  <iframe
                    id="pdf-js-viewer"
                    //src={'../../../mirecibo/lib/web/viewer.html?file=' + request}
                    src={'../../../mirecibo/lib/web/viewer.html?file=' + request}
                    title="webviewer"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

}

const mapDispatchToProps = dispatch => ({
  guardarNumeroPatronal: value => dispatch(guardarNumeroPatronal(value)),
  editarNumeroPatronal: value => dispatch(editarNumeroPatronal(value))
});

export default connect(null, mapDispatchToProps)(VistaPDF);
