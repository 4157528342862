import * as React from 'react';
import { Spinner, Modal, ModalBody,ProgressBar} from 'react-bootstrap'

class ProgressBarRecibo extends React.Component {

  constructor(props) {
    super(props);
    this.center = {
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)'
    }

  }

  render() {
    var porcentaje = parseInt((this.props.cantidadProcesada / this.props.cantidadTotal) * 100)
    return (
      <Modal
        size="md"
        keepMounted
        show={this.props.open}
        aria-describedby="progress"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <ModalBody>
          <div className="row justify-content-center p-3" >
          <div>
              <ProgressBar animated now={porcentaje} label={`${porcentaje}%`} style={{height:"15px"}}/>
			  <p>{this.props.cantidadProcesada} de {this.props.cantidadTotal}</p>
            </div>
          </div>

        </ModalBody>
      </Modal>

    );
  }
}

export default ProgressBarRecibo;