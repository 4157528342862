import React from "react";
import { Modal, Row, Col, Container } from 'react-bootstrap'

class AplicacionExternaDatos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aplicacion: "",
      descripcion: "",
      url: "",
      usuario: "",
      pwd: "",
      id: "",
      apiKey:"",
      empresa: ""
    };
  }

  componentDidMount() {
    this.setState({
      aplicacion: this.props.aplicacionExterna.aplicacion,
      descripcion: this.props.aplicacionExterna.descripcion,
      url: this.props.aplicacionExterna.url,
      usuario: this.props.aplicacionExterna.usuario,
      id: this.props.aplicacionExterna.id,
      apiKey: this.props.aplicacionExterna.apiKey,
      empresa: this.props.aplicacionExterna.empresa
    });
  }

  render() {
    return (
      <Container>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#0c56d0" }}> {this.props.dialogTitle} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <p className="form-date">
                {" "}
                <strong>Datos basicos</strong>
              </p>
              <div className="info-doc">
                <div className="title">Aplicacion</div>
                <div className="text">{this.state.aplicacion}</div>
              </div>
              <div className="info-doc">
                <div className="title">Descripcion </div>
                <div className="text">{this.state.descripcion}</div>
              </div>
              <div className="info-doc">
                <div className="title">Empresa </div>
                <div className="text">{this.state.empresa.nombre}</div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <p className="form-date">
                {" "}
                <strong>Datos de aplicacion</strong>
              </p>
              <div className="info-doc">
                <div className="title">URL</div>
                <div className="text">{this.state.url}</div>
              </div>
              <div className="info-doc">
                <div className="title">Nombre de usuario </div>
                <div className="text">{this.state.usuario}</div>
              </div>

            </Col>
          </Row>
          <Row>
            <Col>
          <div className="info-doc">
                <div className="title">Api Key </div>
                <div className="text">{this.state.apiKey}</div>
              </div>
              </Col>
          </Row>
        </Modal.Body>
      </Container>
    );
  }
}

export default AplicacionExternaDatos;
