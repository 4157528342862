import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import "../../styles/Empresa.css";
import VistaPdf from '../documentViewer/VistaPdf'
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import { Link } from "react-router-dom";
import { getRecibos } from "./ReportesService"
import { idEmpresa, URL } from "../../constans/constants"
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import arrowBack from '../../styles/icons/arrow_back.svg';
import pdfIcon from '../../styles/icons/pdf.svg'

class ReporteRecibos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: "Ingrese Pin de Firma",
      dialogType: "add",
      submitText: "Guardar",
      disabledInput: false,
      selectedRecibo: {
        recibo: "",
        idLote: "",
        numeroLote: "",
        tipoDocumento: "",
        fechaCarga: "",
        empleado: "",
        estado: "",
        estadoMTESS: false
      },
      dialogIsOpen: false,
      dialogIsOpenVerDatos: false,
      dialogPinOpen: false,
      open: false,
      mostrarMensajeFlash: false,
      mensajeFlash: "",
      severityFlash: ""
    };
    let pantallaAnterior = null;
    this.setPantallaAnterior();
    this.tableRef = React.createRef();

  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }

  setPantallaAnterior = () => {
    localStorage.setItem("urlAnterior", window.location.href);
    this.pantallaAnterior = (localStorage.getItem("urlRaiz")).substring(localStorage.getItem("urlRaiz").indexOf("#"));
}

  handleDialog = (value) => {
    this.setState({
      dialogIsOpen: value,
    });
  };
  descargarRecibos = () => {
    if (this.props.match.params.estado) {
      window.location.href = URL + "reportes/recibos/descargar?usuario=" + this.props.match.params.id + "&idEmpresa=" + idEmpresa() + "&estado=" + this.props.match.params.estado;
    } else {
      window.location.href = URL + "reportes/recibos/descargar?usuario=" + this.props.match.params.id + "&idEmpresa=" + idEmpresa();
    }

  }
  descargarRecibosXls = () => {
    if (this.props.match.params.estado) {
      window.location.href = URL + "reportes/recibos/descargar/xls?usuario=" + this.props.match.params.id + "&idEmpresa=" + idEmpresa() + "&estado=" + this.props.match.params.estado;
    } else {
      window.location.href = URL + "reportes/recibos/descargar/xls?usuario=" + this.props.match.params.id + "&idEmpresa=" + idEmpresa();
    }

  }

  handleDialogPin = (value, mensaje = "", severity = "") => {
    this.setState({
      dialogPinOpen: value,
      mostrarMensajeFlash: true,
      mensajeFlash: mensaje,
      severityFlash: severity
    });
  };
  handleClose = (value) => {
    this.setState({
      open: value
    });
  };

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };

  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
            <Modal
              fullWidth={true}
              maxWidth="md"
              maxHeight="600px"
              show={this.state.dialogIsOpen}
              onHide={this.handleDialog}>
              <VistaPdf
                idLote={this.state.selectedRecibo.idLote}
                recibo={this.state.selectedRecibo}
                handleClose={this.handleDialogVerDatos}
                type={this.state.dialogType}
                submitText={this.state.submitText}
                disabledInput={this.state.disabledInput}
              />
            </Modal>
            <div className="sectionHeader" style={{ marginBottom: '20px' }}>
              <div className="actionTitle" >
                <a type='button' style={{ paddingTop: '7px' }}>
                  <img src={arrowBack} onClick={() => window.location.href = this.pantallaAnterior} />
                </a>
                <h1 className="pageTitle"> {"Recibos de " + this.props.match.params.colaborador} </h1>
              </div>

              <div className="actions">
                <a className="btn btn-primary" onClick={this.descargarRecibos}> Descargar  ZIP</a>
               <a className="btn btn-primary" style={{background:"#00b74a"}} onClick={this.descargarRecibosXls}> Descargar  XLS</a> 
              </div>
            </div>
            <div className="contentPage card" style={{ padding: '0px' }}>
              <MaterialTable
                title="Recibos"
                style={{ borderRadius: ".5rem" }}
                tableRef={this.tableRef}
                columns={[
                  { title: "Recibo", field: "recibo" },
                  { title: "Lote", field: "numeroLote" },
                  { title: "Concepto de Lote", field: "descripcionLote" },
                  { title: "Fecha", field: "fechaCarga" },
                  { title: "Tipo Documento", field: "tipoDocumento" },
                  { title: "Estado", field: "estado" }
                ]}
                data={(query) =>

                  new Promise((resolve, reject) => {
                    this.props.getRecibos(query.page, query.pageSize, query.search, this.props.match.params.id, this.props.match.params.estado).then(() => {
                      resolve({
                        data: this.props.reporteReciboData.reporteRecibo,
                        page: this.props.reporteReciboData.page,
                        totalCount: this.props.reporteReciboData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  rowData => ({
                    icon: () =>
                      <Link to={{
                        pathname: '/reporte/nro-recibo/'+rowData.recibo+'/nro-lote/'+ rowData.idLote+'/ver', 
                      }}>
                        <img src={pdfIcon} />
                      </Link>,
                    tooltip: 'Ver',
                    onClick: (rowData) => {
                    }
                  })
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  toolbar: true,
                  detailPanelColumnAlignment: "right",
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold"
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },
                }}
              />
            </div>
            <MensajeFlash
              mensaje={this.state.mensajeFlash}
              severity={this.state.severityFlash}
              open={this.state.mostrarMensajeFlash}
              handleClose={this.handleCloseMensajeFlash} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getRecibos: (page, size, search, usuario, estado) => dispatch(getRecibos(page, size, search, usuario, estado)),
});

const mapStateToProps = (state) => ({
  reporteReciboData: state.reporteReciboData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteRecibos);
