import axios from 'axios';
import Cookies from 'universal-cookie';
import { URL, getAuthorizationHeader, administradorEmpresas, verificarRol, gestorEmpresa, empleado, iscertLoguin } from '../../constans/constants';
import {
  SET_SESSION_DATA,
  SET_ROLES,
  GET_SESSION_DATE,
  SET_EMPRESAS_USER
} from "../../constans/action-types";

const cookies = new Cookies();

export const setSessionData = payload => ({ type: SET_SESSION_DATA, payload })
export const setRoles = payload => ({ type: SET_ROLES, payload });
export const getSessionData = payload => ({ type: GET_SESSION_DATE, payload });
export const setEmpresasUser = payload => ({ type: SET_EMPRESAS_USER, payload });

export const authenticateUser = (user) => {
  return dispatch => {
    const json = {
      j_username: user.username,
      j_password: user.password
    };
    return axios.post(URL + 'auth/administrador', json)
      .then(response => {
        if (response.data.error == false) {
          cookies.set('logged', true, { path: '/', maxAge: 2000 });
          cookies.set('jwt', response.data.datos.token, { path: '/', maxAge: 2000 });
          cookies.set('refreshToken', response.data.datos.refreshToken, { path: '/', maxAge: 2000 });
          cookies.set('admin', true, { path: '/', maxAge: 2000 });
          cookies.set('username', response.data.datos.username, { path: '/', maxAge: 2000 })
          dispatch(setSessionData({
            token: response.data.datos.token,
            logged: true,
            admin: true,
            username: response.data.datos.username
          }));
          dispatch(setRoles({
            rolesData: response.data.authorities
          }))
          return response.data
        } else {
          return response.data;
        }
      });
  }
}

export const verifyUserStatus = () => {
  return async function (dispatch) {
    const loggedAux = cookies.get('logged');
    if (loggedAux || loggedAux === 'true') {
      const response = await datosLogueado()
      var admin = (cookies.get('admin') == "true");
      var empresa = cookies.get('empresa');
      var jwtoken = cookies.get('jwt');
      var username = cookies.get('username');
      if (!admin && empresa == null) {
        cookies.set("empresa", response.data.datos.empresas[0].id, { path: '/' })
        cookies.set("nombreEmpresa", response.data.datos.empresas[0].nombre, { path: '/'})
      }
      var rolesAux = obtenerRolesEmpresa(cookies.get('empresa'), response.data.datos.empresas)
      localStorage.setItem("roles", JSON.stringify({ id: cookies.get('empresa'), roles: rolesAux }))
      dispatch(setEmpresasUser({
        nombreCompleto: response.data.datos.fullName,
        empresas: response.data.datos.empresas
      }))
      dispatch(setRoles({
        id: cookies.get('empresa'),
        rolesData: rolesAux
      }));
      dispatch(setSessionData({
        token: jwtoken,
        logged: true,
        admin: admin,
        username: username
      }));
    }
  }
}

export function authenticateLoginCliente(user) {
  return async function (dispatch) {
    cookies.set("logged", true, { path: "/", maxAge: 2000 });
    cookies.set("jwt", user.token, { path: "/", maxAge: 2000 });
    cookies.set("admin", user.admin, { path: "/", maxAge: 2000 });
    cookies.set("username", user.username, { path: "/", maxAge: 2000 });
    const response = await datosLogueado();
    var admin = cookies.get("admin") == "true";
    var empresa = cookies.get("empresa");
    if (!admin && empresa == null) {
      cookies.set("empresa", response.data.datos.empresas[0].id, { path: "/" });
      cookies.set("nombreEmpresa", response.data.datos.empresas[0].nombre, { path: '/'})
    }
    var rolesAux = await obtenerRolesEmpresa(
      cookies.get("empresa"),
      response.data.datos.empresas
    );
    await localStorage.setItem(
      "roles",
      JSON.stringify({ id: cookies.get("empresa"), roles: rolesAux })
    );
    dispatch(
      setEmpresasUser({
        nombreCompleto: response.data.datos.fullName,
        empresas: response.data.datos.empresas,
      }))
    dispatch(setRoles({
      id: cookies.get("empresa"),
      rolesData: rolesAux,
    }))
    dispatch(setSessionData({
      token: user.token,
      logged: true,
      admin: user.admin,
      username: user.username,
    })
    );
  };
}
export const   logout = () => {
  var admin = (cookies.get('admin') == "true");
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + cookies.get('jwt'),
    'refresh-token': cookies.get("refreshToken")
  }
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  localStorage.clear();
  if (!admin) {
    if (iscertLoguin) {
      axios
        .get(URL + "cerrarSesion")
        .then((response) => {
          window.location.href = response.data
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        });
    } 
    
    window.location.href = "#/login"
    
  } else {
    window.location.href = "#/admin/login"
  }

}



export function logged() {
  return async function (dispatch) {
    const response = await datosLogueado()
    var admin = (cookies.get('admin') == "true");
    var empresa = cookies.get('empresa');
    var jwtoken = cookies.get('jwt');
    var username = cookies.get('username');
    if (!admin && empresa == null) {
      cookies.set("empresa", response.data.datos.empresas[0].id, { path: '/' })
      cookies.set("nombreEmpresa", response.data.datos.empresas[0].nombre, { path: '/'})
    }
    var rolesAux = obtenerRolesEmpresa(cookies.get('empresa'), response.data.datos.empresas)
    localStorage.setItem("roles", JSON.stringify({ id: cookies.get('empresa'), roles: rolesAux }))
    dispatch(setEmpresasUser({
      nombreCompleto: response.data.datos.fullName,
      empresas: response.data.datos.empresas
    }))
    dispatch(setRoles({
      id: cookies.get('empresa'),
      rolesData: rolesAux
    }));
    dispatch(setSessionData({
      token: jwtoken,
      logged: true,
      admin: admin,
      username: username
    }));
  }
}

export function datosLogueado() {
  return axios.get(URL + 'usuario/logged', getAuthorizationHeader())
    .then(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    })
}

export const seleccionarEmpresa = (idEmpresa, userEmpresas) => {
  return dispatch => {
    cookies.set('empresa', idEmpresa, { path: '/' });
    cookies.set('nombreEmpresa', obtenerNombreEmpresa(idEmpresa, userEmpresas), { path: '/'})
    var rolesAux = obtenerRolesEmpresa(cookies.get('empresa'), userEmpresas)
    dispatch(setRoles({
      id: idEmpresa,
      rolesData: rolesAux
    }))
    localStorage.setItem("roles", JSON.stringify({ id: idEmpresa, roles: rolesAux }))
  }
}
export const obtenerRolesEmpresa = (id, userEmpresas) => {
  for (var x in userEmpresas) {
    if (userEmpresas[x].id == id) {
      return userEmpresas[x].roles
    }
  }
}

export const obtenerNombreEmpresa = (id, userEmpresas) =>{
  for (var x in userEmpresas) {
    if (userEmpresas[x].id == id) {
      return userEmpresas[x].nombre
    }
  }
}

export const sessionData = () => {
  return dispatch => {
    dispatch(getSessionData);
  };
}

export const getEmpresa = () => {
  var empresa = cookies.get('empresa');
  return axios.get(URL + 'empresa/obtener/' + empresa, getAuthorizationHeader())
    .then(response => {
      return response.data.datos;
    }, error => {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    })
}

export const getUsuarioLogueado = () => {
  return axios.get(URL + 'usuario/logged', getAuthorizationHeader())
    .then(response => {
      return response.data.datos;
    }, error => {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    })
}

export const obtenerURL = () => {
  var rolesData = JSON.parse(localStorage.getItem("roles"))
  if (rolesData != null && rolesData.hasOwnProperty("id")) {
    if (verificarRol(rolesData, administradorEmpresas)) {
      return "/usuarios"
    } else {
      if (verificarRol(rolesData, gestorEmpresa)) {
        return "/lote/nuevos"
      } else {
        if (verificarRol(rolesData, empleado)) {
          return "/recibos/pendientes"
        }
      }
    }
  }
}

export function loginUsuario(user) {
  const json = {
    j_username: user.username,
    j_password: user.password
  };
  return axios.post(URL + 'usuario/keycloak/login', json)
    .then(response => {
      return response.data;
    }, error => {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    });
}

export const authenticateUserKeycloak = (user) => {
  return async function (dispatch) {
    const responseLoguin = await loginUsuario(user)
    if (responseLoguin.error == false) {
      cookies.set("logged", true, { path: "/" });
      cookies.set("jwt", responseLoguin.datos.token, { path: "/" });
      cookies.set("admin", false, { path: "/" });
      cookies.set("username", responseLoguin.datos.username, { path: "/" });
      cookies.set("refreshToken", responseLoguin.datos.refreshToken, { path: "/" })
      const response = await datosLogueado();
      var admin = cookies.get("admin") == "true";
      var empresa = cookies.get("empresa");
      if (!admin && empresa == null) {
        cookies.set("empresa", response.data.datos.empresas[0].id, { path: "/" });
        cookies.set("nombreEmpresa", response.data.datos.empresas[0].nombre, { path: '/'})
      }
      var rolesAux = await obtenerRolesEmpresa(
        cookies.get("empresa"),
        response.data.datos.empresas
      );
      await localStorage.setItem(
        "roles",
        JSON.stringify({ id: cookies.get("empresa"), roles: rolesAux })
      );
      dispatch(
        setEmpresasUser({
          nombreCompleto: response.data.datos.fullName,
          empresas: response.data.datos.empresas,
        }))
      dispatch(setRoles({
        id: cookies.get("empresa"),
        rolesData: rolesAux,
      }))
      dispatch(setSessionData({
        token: user.token,
        logged: true,
        admin: user.admin,
        username: user.username,
      })
      );
    } else {
      return responseLoguin;
    }
  }
}

axios.interceptors.response.use(function (res) {
  return res;
}, function (error) {
  const errorStatus = [401, 400];
  const exceptionUrl = [URL+'usuario/keycloak/refreshToken', URL+'usuario/keycloak/logout'];
  const originalRequest = error.config; 
  
  if(errorStatus.includes(error.response.status) && exceptionUrl.includes(originalRequest.url)){
    logout()
  }
  
  
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const headers = { 'refresh-token': cookies.get("refreshToken") }
    return axios.get(URL + 'usuario/keycloak/refreshToken', { headers })
      .then(res => {
        if (res.status == 200) {
          cookies.set("jwt", res.data.token, { path: "/" });
          cookies.set("refreshToken", res.data.refreshToken, { path: "/" })
          originalRequest.headers['Authorization'] = 'Bearer ' + res.data.token;
          return axios(originalRequest);
        }else{
          logout()
        }
      })
  }
})







