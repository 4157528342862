import axios from 'axios';
import {
  SET_LOTES,
  SET_LOADING
} from "../../constans/action-types";
import { URL, getAuthorizationHeader, getAuthorizationHeaderUpload, getEstadoURL } from '../../constans/constants'
import Cookies from 'universal-cookie';
import {logout} from '../login/SesionService'

const cookies = new Cookies();
const defaultPage = 0;
const defaultSize = 5;
export const setLotes = payload => ({ type: SET_LOTES, payload });
export const setLoading = payload => ({ type: SET_LOADING, payload });

export const getLotes = (page = defaultPage, size = defaultSize, search) => {
  return dispatch => {
    var urlRequest = ""
    var estado = getEstadoURL()
    var idEmpresa = cookies.get('empresa');
    if ((search == null || search == '') && (idEmpresa == null || idEmpresa == undefined)) {
      urlRequest = URL + "lote/" + 'listar?page=' + page + "&size=" + size + "&estado=" + estado
    } else if ((search != null || search != '') && (idEmpresa == null || idEmpresa == undefined)) {
      urlRequest = URL + "lote/" + 'listar?page=' + page + "&size=" + size + "&search=" + search + "&estado=" + estado
    } else if ((search == null || search == '') && (idEmpresa != null)) {
      urlRequest = URL + "lote/" + 'listar?page=' + page + "&size=" + size + "&idEmpresa=" + idEmpresa + "&estado=" + estado
    } else if (search != null && idEmpresa != null) {
      urlRequest = URL + "lote/" + 'listar?page=' + page + "&size=" + size + "&search=" + search + "&idEmpresa=" + idEmpresa + "&estado=" + estado
    }
    return axios.get(urlRequest, getAuthorizationHeader())
      .then(res => {
        dispatch(setLotes({
          page: res.data.page.pageable.pageNumber,
          totalCount: res.data.page.totalElements,
          lotes: res.data.page.content
        }));
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      })
  };
};

export const eliminarLote = (id) => {
  return axios.delete(URL + "lote/eliminar/" + id, getAuthorizationHeader()).then(res => {
    return res.data
  }, error => {
    if (error.response.status === 401) {
     logout();
    }
    return error;
  });

}

export const exportarArchivo = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  axios
    .post(URL + "lote/upload/", formData, getAuthorizationHeaderUpload())
    .then((res) => {
      return res.data
    }, error => {
      if (error.response.status === 401) {
       logout();
      }
      return error;
    });
  }

  export const exportarDocumento = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(URL + "recibo/upload/", formData, getAuthorizationHeaderUpload())
      .then((res) => {
        return res.data
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
    }

  export const obtenerLote = (id) => {
    return axios.get(URL+"lote/obtener/"+id,getAuthorizationHeader())
    .then((res) =>{
      return res.data
    }, error => {
      if (error.response.status === 401) {
       logout();
      }
      return error;
    })
  } 
