import React from "react";
import { connect } from "react-redux";
import {
  registrarUsuario,
  editarUsuario
} from "../../components/usuario/UsuarioService";
import axios from "axios";
import { URL as URLService, getAuthorizationHeader, getAuthorizationHeaderUpload, aparienciaFirmaUsuario, admin, idEmpresa } from "../../constans/constants";
import Cookies from "universal-cookie";
import MensajeFlash from "../mensajeFlash/MensajeFlash";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Button, Container, Row, Col, Modal, Form, Alert } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import { logout } from "../login/SesionService";

export const obtenerIdEmpresa = () => {
  const cookies = new Cookies();
  return cookies.get("empresa");
};
export const esSesionAdmin = () => {
  const cookies = new Cookies();
  return cookies.get("admin") == "true";
};

class Usuario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: "",
      identificador: "",
      nombres: "",
      apellidos: "",
      cedula: "",
      email: "",
      empresas: [],
      rolesxempresa: [],
      empresaSelect: -1,
      rolesSelect: {},
      id: "",
      nroPersonalIps: "",
      nroTrabajoMtess: "",
      urlFirma: "",
      uriFirma: null,
      mostrarMensaje: false,
      mensaje: "",
      mensajeErrorAparienciaFirma: "",
      openPantallaCarga: false,
      validated: false,

      showValidacion: false,
      erroresValidacion: null,

      errorFirma: "",
      severity: "success"
    };
  }
  handleClose = (exito = false) => {
    if (exito === true) {
      this.props.handleClose(false, this.state.mensaje, this.state.severity);
    } else {
      this.props.handleClose(false, "", this.state.severity);
    }
  };

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensaje: false,
    });
  };


  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };
  obtenerRolesEmpresaAsignada = (empresaRoles) => {
    const arr = []
    arr.push(empresaRoles.find(elemento => elemento.id == idEmpresa()))
    return arr
  }

  handleUsuarioSubmit = event => {
    event.preventDefault();
    this.setState({ openPantallaCarga: true });
    const form = event.currentTarget;
    var rolEmpresa = [];
    if (esSesionAdmin()) {
      rolEmpresa = this.state.rolesxempresa
    } else {
      rolEmpresa = this.obtenerRolesEmpresaAsignada(this.state.rolesxempresa)
    }

    const json = {
      nombres: this.state.nombres,
      apellidos: this.state.apellidos,
      identificador: this.state.identificador,
      cedula: this.state.cedula,
      email: this.state.email,
      usuario: this.state.usuario,
      rolesxempresa: rolEmpresa,
      celular: this.state.celular,
      id: this.state.id,
      nroPersonalIps: this.state.nroPersonalIps,
      nroTrabajoMtess: this.state.nroTrabajoMtess,
      empresaId: obtenerIdEmpresa()
    };

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true, openPantallaCarga: false })
    } else {
      this.setState({ validated: true })

      if (this.props.type === "edit") {
        this.props.editarUsuario(json).then((respuesta) => {
          this.setState({ mensaje: respuesta.mensaje, severity: "success" });
          if (respuesta.error === true) {
            this.setState({
              mostrarMensaje: true,
              openPantallaCarga: false,
              severity: "danger"
            });
          } else {
            if (this.state.uriFirma != null) {
              var username = respuesta.datos.username
              var formData = new FormData()
              formData.append("file", this.state.uriFirma)
              axios.post(URLService + "usuario/aparienciaFirma/" + username, formData, getAuthorizationHeaderUpload())
            }
            this.handleClose(true);
          }
        }).catch(error => {
          this.setState({
            mensaje: "Error inesperado al realizar la acción",
            severity: 'danger',
            mostrarMensaje: true,
            openPantallaCarga: false,
          });
          this.handleClose(true)
        });
      } else if (this.props.type === "add") {
        this.props
          .registrarUsuario(json, obtenerIdEmpresa())
          .then((respuesta) => {
            if (respuesta.error) {
              this.setState({
                erroresValidacion: respuesta.datos,
                showValidacion: true,
                openPantallaCarga: false,
              });
            } else {
              this.setState({
                mensaje: respuesta.mensaje,
                severity: "success"
              });
              if (this.state.uriFirma != null) {
                var username = respuesta.datos.username
                var formData = new FormData()
                formData.append("file", this.state.uriFirma)
                axios.post(URLService + "usuario/aparienciaFirma/" + username, formData, getAuthorizationHeaderUpload())
              }
              this.handleClose(true);
            }

          }).catch(error => {
            this.setState({
              mensaje: "Error inesperado al realizar la acción",
              severity: 'danger',
              mostrarMensaje: true,
              openPantallaCarga: false,
            }); this.handleClose(true)
          });
      }
    }
  };

  componentDidMount() {
    if (this.props.type === "edit") {
      this.setState({
        nombres: this.props.usuario.nombres,
        apellidos: this.props.usuario.apellidos,
        identificador: this.props.usuario.identificador,
        cedula: this.props.usuario.cedula,
        email: this.props.usuario.email,
        celular: this.props.usuario.celular,
        usuario: this.props.usuario.usuario,
        id: this.props.usuario.id,
        nroPersonalIps: this.props.usuario.nroPersonalIps,
        nroTrabajoMtess: this.props.usuario.nroTrabajoMtess,
      });
      axios
        .get(URLService + "empresa/listarEmpresas", getAuthorizationHeader())
        .then((res) => {
          if (res.data.error == false) {
            this.setState({ empresas: res.data.datos });
          }
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        });
      axios
        .get(
          URLService + "usuario/obtenerRolesUsuarioEdit/" + this.props.usuario.id,
          getAuthorizationHeader()
        )
        .then((res) => {
          if (res.data.error == false) {
            this.setState({
              rolesxempresa: res.data.datos,
            });
            if (!esSesionAdmin()) {
              this.setState({
                empresaSelect: res.data.datos.findIndex(
                  (elemento) => elemento.id == obtenerIdEmpresa()
                ),
                rolesSelect: this.state.rolesxempresa.find(
                  (elemento) => elemento.id == obtenerIdEmpresa()
                ),
              });
            }
          }
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        });
      axios.get(URLService + "usuario/aparienciaFirma/" + this.props.usuario.id, getAuthorizationHeader())
        .then(res => {
          if (res.data.error !== true) {
            this.setState({
              urlFirma: "data:image/png;base64," + res.data
            })
          }
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        })
    } else {
      axios
        .get(URLService + "empresa/listarEmpresas", getAuthorizationHeader())
        .then((res) => {
          if (res.data.error == false) {
            this.setState({ empresas: res.data.datos });
          }
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        });
      axios
        .get(
          URLService + "usuario/obtenerRolesUsuarioEdit/" + "-1",
          getAuthorizationHeader()
        )
        .then((res) => {
          if (res.data.error == false) {
            this.setState({ rolesxempresa: res.data.datos });
          }
          if (!esSesionAdmin()) {
            this.setState({
              empresaSelect: res.data.datos.findIndex(
                (elemento) => elemento.id == obtenerIdEmpresa()
              ),
              rolesSelect: this.state.rolesxempresa.find(
                (elemento) => elemento.id == obtenerIdEmpresa()
              ),
            });
          }
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        });
    }
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };

  handleUploadAparienciaFirma = (event) => {
    try {
      Resizer.imageFileResizer(
        event.target.files[0],
        200, 150,
        "PNG",
        0, 0,
        (uri) => {
          this.setState({
            urlFirma: URL.createObjectURL(uri),
            uriFirma: uri
          })
        },
        "file",
      )

    } catch (error) {
      this.setState({
        mensajeErrorAparienciaFirma: "Hubo un problema al cargar la imagen",
      });
    }
  }

  handleChangeEmpresa = (event) => {
    let empresaSelect = this.state.rolesxempresa.findIndex(
      (elemento) => elemento.id == event.target.value
    );
    if (this.props.sessionData.admin) {
      this.setState({
        empresaSelect: empresaSelect,
        rolesSelect: this.state.rolesxempresa[empresaSelect],
      });
    } else {
      this.setState({
        empresaSelect: obtenerIdEmpresa(),
        rolesSelect: this.state.rolesxempresa.find(
          (elemento) => elemento.id == obtenerIdEmpresa()
        ),
      });
    }
  };

  rolesHijos = (rol) => {
    if (rol === "Firmar como Recursos Humanos (RRHH)") {
      return true;
    }
    if (rol === "Firmar como Representante Legal") {
      return true;
    }
    return false;
  };
  chkclick = (event) => {
    let rolesxempresa = this.state.rolesxempresa;
    rolesxempresa[this.state.empresaSelect].roles.forEach((elemento) => {
      if (elemento.id == event.target.value) {
        elemento.checked = event.target.checked;
        /*         if (
                  (event.target.value == 5 || event.target.value == 6) &&
                  event.target.checked
                ) {
                  rolesxempresa[this.state.empresaSelect].roles.find(
                    (e) => e.id == 4
                  ).checked = true;
                } else if (event.target.value == 4 && !event.target.checked) {
                  rolesxempresa[this.state.empresaSelect].roles.find(
                    (e) => e.id == 5
                  ).checked = false;
                  rolesxempresa[this.state.empresaSelect].roles.find(
                    (e) => e.id == 6
                  ).checked = false;
                } */
      }
    });
    this.setState({ rolesxempresa: rolesxempresa });

    let rolesSelect = this.state.rolesSelect;
    rolesSelect.roles.forEach((elemento) => {
      if (elemento.id == event.target.value) {
        elemento.checked = event.target.checked;
        /*         if (
                  (event.target.value == 5 || event.target.value == 6) &&
                  event.target.checked
                ) {
                  rolesSelect.roles.find((e) => e.id == 4).checked = true;
                } else if (event.target.value == 4 && !event.target.checked) {
                  rolesSelect.roles.find((e) => e.id == 5).checked = false;
                  rolesSelect.roles.find((e) => e.id == 6).checked = false;
                } */
      }
    });
    this.setState({ rolesSelect: rolesSelect });
  };
  onChangeMiembroActivo = (event) => {
    let rolesxempresa = this.state.rolesxempresa;
    rolesxempresa[this.state.empresaSelect].activo = event.target.checked;
    let rolesSelect = this.state.rolesSelect;
    rolesSelect.activo = event.target.checked;
    this.setState({
      rolesxempresa: rolesxempresa,
      rolesSelect: rolesSelect,
    });
  };

  mostrarErroresValidacion = (value) => {
    this.setState({ showValidacion: value });
  };

  render() {
    return (
      <Container>
        <Form noValidate validated={this.state.validated} onSubmit={this.handleUsuarioSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}> {this.props.dialogTitle} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                <Col xs={12} md={8}>
                  <p className="form-date">
                    {" "}
                    <strong>Completar Datos:</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        name="usuario"
                        variant="outlined"
                        onChange={this.handleInput}
                        value={this.state.usuario}
                        required
                      />
                      <Form.Label class="md-label"> Nombre de usuario </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        name="nombres"
                        value={this.state.nombres}
                        variant="outlined"
                        onChange={this.handleInput}
                        required
                      ></input>
                      <Form.Label class="md-label"> Nombres </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "30px" }}>
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        name="apellidos"
                        value={this.state.apellidos}
                        variant="outlined"
                        onChange={this.handleInput}
                        required />
                      <label class="md-label"> Apellidos </label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "30px" }}>
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        name="cedula"
                        value={this.state.cedula}
                        variant="outlined"
                        onChange={this.handleInput}
                        required
                      ></input>
                      <label class="md-label"> C.I. </label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "30px" }}>
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        name="celular"
                        value={this.state.celular}
                        variant="outlined"
                        onChange={this.handleInput}
                      ></input>
                      <label class="md-label"> Celular </label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  {aparienciaFirmaUsuario && <Form.Group className="form-group md-input" >
                    <Form.Label><strong>Apariencia de firma</strong></Form.Label>
                    <Form.Control type="file" style={{ marginBottom: '0px' }} onChange={this.handleUploadAparienciaFirma} />
                    <small style={{ color: 'red' }}>{this.state.mensajeErrorAparienciaFirma}</small>
                    <img
                      id="aparienciaFirmaImg"
                      src={this.state.urlFirma}
                      accept="image/png, image/jpeg"
                      style={{ marginTop: '5px' }}
                    />
                  </Form.Group>}
                </Col>
                <Col xs={6} md={4}>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "30px" }}>
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        name="identificador"
                        value={this.state.identificador}
                        variant="outlined"
                        onChange={this.handleInput}
                      ></input>
                      <label class="md-label"> Identificador </label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "30px" }}>
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        value={this.state.nroPersonalIps}
                        variant="outlined"
                        name="nroPersonalIps"
                        onChange={this.handleInput}
                      ></input>
                      <label class="md-label"> Nro. personal IPS </label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "30px" }}>
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        value={this.state.nroTrabajoMtess}
                        variant="outlined"
                        name="nroTrabajoMtess"
                        onChange={this.handleInput}
                      ></input>
                      <label class="md-label"> Nro. Trabajo MTESS </label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "30px" }}>
                    <div className="form-group md-input">
                      <input
                        className="form-control md-form-control"
                        type="email"
                        placeholder=" "
                        value={this.state.email}
                        name="email"
                        variant="outlined"
                        onChange={this.handleInput}
                        required
                      ></input>
                      <label class="md-label"> Email </label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  {esSesionAdmin() ? (
                    <Form.Group className="form-group md-input">
                      <Form.Select className="form-control md-select" name="empresaId" id="empresaId" required
                        onChange={this.handleChangeEmpresa}>
                        <option key="" value="" selected></option>
                        {this.state.empresas.map((elemento) => (
                          <option key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Label class="md-label"> Seleccionar empresa </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <p><small>Campo requerido</small></p>
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : null}

                  {this.state.empresaSelect != -1 && admin() ? (
                    <Form.Group className="form-group md-input">
                      <div className="form-group md-input">
                        <Form.Check
                          type="switch"
                          name="estado"
                          color="primary"
                          checked={this.state.rolesSelect.activo}
                          onChange={this.onChangeMiembroActivo}
                          label="Miembro Activo"
                        />
                      </div></Form.Group>) : null}

                </Col>
                <Col xs={6} md={4}>
                  <p className="form-date">
                    {" "}
                    <strong> Roles del perfil:</strong>
                  </p>
                  {this.state.empresaSelect != -1
                    ? this.state.rolesSelect.roles.map((elemento) => (
                      <div>
                        {/*                         {elemento.id === 5 || elemento.id === 6 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "20px",
                            }}
                          >
                            <Form.Group className="form-group md-input">
                              <div key={elemento.id} className="mb-3">
                                <Form.Check
                                  type='checkbox'
                                  id={elemento.id}
                                  name={elemento.nombre}
                                  label={elemento.nombre}
                                  onClick={this.chkclick}
                                  value={elemento.id}
                                  checked={elemento.checked}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        ) : ( */}
                        <Form.Group className="form-group md-input">
                          <div key={elemento.id} className="mb-3">
                            <Form.Check
                              type='checkbox'
                              id={elemento.id}
                              label={elemento.nombre}
                              onClick={this.chkclick}
                              value={elemento.id}
                              checked={elemento.checked}
                            />
                          </div>
                        </Form.Group>
                      </div>
                    ))
                    : null}
                </Col>
              </Row>

              {
                this.state.showValidacion &&

                <Alert
                  onClose={() => this.mostrarErroresValidacion(false)}
                  variant="danger"
                  dismissible>
                  <div>
                    <ul>
                    {
                      this.state.erroresValidacion.map((error) => 
                        <li>{error}</li>
                      )
                    }
                    </ul>
                  </div>

                </Alert >
              }

              {/*
              <MensajeFlash
                mensaje={this.state.mensaje}
                open={this.state.mostrarMensaje}
                handleClose={this.handleCloseMensajeFlash}
              />
*/}
              <PantallaCarga open={this.state.openPantallaCarga} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit">
              {this.props.submitText}
            </Button>
          </Modal.Footer>
        </Form>
        <PantallaCarga open={this.state.openPantallaCarga} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  registrarUsuario: (value) => dispatch(registrarUsuario(value)),
  editarUsuario: (value) => dispatch(editarUsuario(value)),
});
const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Usuario);
