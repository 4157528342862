import React from 'react';
import { connect } from 'react-redux';
import { editarNumeroPatronal, guardarNumeroPatronal } from '../../components/numeroPatronal/NumeroPatronalService'
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";

class NumeroPatronal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      numeroPatronal: "",
      descripcion: "",
      id: "",
      mostrarMensaje: false,
      mensaje: "",
      severity: "",
      openPantallaCarga: false,
      validated: false
    }
  }

  handleClose = () => {
    this.props.handleClose(false, this.state.mensaje, this.state.severity);
  }

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleNumeroPatronalSubmit = event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true })

    } else {
      this.setState({ openPantallaCarga: true });
      if (this.props.type === "edit") {
        const json = {
          numeroPatronal: this.state.numeroPatronal,
          descripcion: this.state.descripcion,
          id: this.state.id
        };
        this.props.editarNumeroPatronal(json).then((response) => {
          if (!response.error) {
            this.setState({
              mensaje: response.mensaje,
              severity: "success"
            })
          } else {
            this.setState({
              mensaje: response.mensaje,
              severity: "danger"
            })
          }
          this.handleClose();
        });
      } else if (this.props.type === "add") {
        const json = {
          numeroPatronal: this.state.numeroPatronal,
          descripcion: this.state.descripcion,
          id: this.state.id
        };
        this.props.guardarNumeroPatronal(json).then((response) => {
          if (!response.error) {
            this.setState({
              mensaje: response.mensaje,
              severity: "success"
            })
          } else {
            this.setState({
              mensaje: response.mensaje,
              severity: "danger"
            })
          }
          this.handleClose();
        });
      }
    }

  }

  componentDidMount() {
    this.setState({
      numeroPatronal: this.props.numeroPatronal.numero,
      descripcion: this.props.numeroPatronal.descripcion,
      id: this.props.numeroPatronal.id
    })
  }

  handleInput = (e) => {
    var name = e.target.name
    var val = e.target.value
    this.setState({
      [name]: val
    })
  }
  render() {
    return (
      <Container>
        <Form noValidate validated={this.state.validated} onSubmit={this.handleNumeroPatronalSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}>
              {" "}
              {this.props.dialogTitle}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col style={{ padding: "10px" }}>
                <Form.Group className="form-group md-input" controlId="formnumeroPatronal">
                  <div className="form-group md-input">
                    <Form.Control
                      required
                      type="number"
                      min={0}
                      className="form-control md-form-control"
                      name="numeroPatronal"
                      id="numeroPatronal"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.numeroPatronal}
                    />
                    <Form.Label class="md-label"> Número MTESS Patronal </Form.Label>
                    <Form.Control.Feedback type="invalid" style={{ marginBottom: "10px" }}>
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "10px" }}>
                <Form.Group className="form-group md-input" controlId="formdescripcion">
                  <div className="form-group md-input">
                    <textarea
                      className="form-control md-form-control"
                      type="text"
                      name="descripcion"
                      id="descripcion"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.descripcion}
                    />
                    <Form.Label class="md-label"> Descripción </Form.Label>
                    <Form.Control.Feedback type="invalid" style={{ marginBottom: "10px" }}>
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit">
              {this.props.submitText}
            </Button>
          </Modal.Footer>
        </Form>
        <PantallaCarga open={this.state.openPantallaCarga} />
      </Container>
    )
  }

}

const mapDispatchToProps = dispatch => ({
  guardarNumeroPatronal: value => dispatch(guardarNumeroPatronal(value)),
  editarNumeroPatronal: value => dispatch(editarNumeroPatronal(value))
});

export default connect(null, mapDispatchToProps)(NumeroPatronal);