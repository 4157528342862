import React from "react";
import { connect } from "react-redux";
import {
  cargarImagen,
  crearNuevoProveedor,
  editarProveedor,
  subirImagen,
} from "./ProveedorFirmaService";
import Resizer from "react-image-file-resizer";
import { Container, Form, Modal, Row, Col, Button } from "react-bootstrap";
import { logout } from "../login/SesionService";
import { psa } from "../../constans/constants";

class ProveedorFirma extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      clientSecret: "",
      clientId: "",
      schema: "",
      host: "",
      port: "",
      activo: "",
      codigoCliente: "",
      mostrarMensaje: false,
      mensaje: "",
      logo: null,
      urlLogo: "",
      openPantallaCarga: false,
      validated: false,
    };
  }

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensaje: false,
    });
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };

  handleClose = () => {
    this.props.handleClose(false);
  };

  handleUploadLogo = (event) => {
    try {
      Resizer.imageFileResizer(
        event.target.files[0],
        250,
        200,
        "PNG",
        0,
        0,
        (uri) => {
          this.setState({
            urlLogo: URL.createObjectURL(uri),
            logo: uri,
          });
        },
        "file"
      );
    } catch (error) {
      this.setState({
        mensaje: "Hubo un problema al cargar el nuevo logo",
        mostrarMensaje: true,
      });
    }
  };

  handleProveedorSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ validated: true, openPantallaCarga: false });
    } else {
      this.setState({ openPantallaCarga: true, validated: true });
      const data = {
        id: this.state.id,
        clientSecret: this.state.clientSecret,
        clientId: this.state.clientId,
        schema: this.state.schema,
        host: this.state.host,
        port: this.state.port,
        activo: this.state.activo,
        descripcion: this.state.descripcion,
        codigoCliente: this.state.codigoCliente,
      };
      if (this.props.type === "edit") {
        editarProveedor(data).then((response) => {
          if (response.error === true) {
            this.setState({
              mensaje: response.mensaje,
              mostrarMensaje: true,
              openPantallaCarga: false,
            });
          } else {
            if (this.state.logo != null) {
              var id = response.datos.id;
              var formData = new FormData();
              formData.append("file", this.state.logo);
              subirImagen(id, formData);
            }
            this.props.handleClose(false, response.mensaje);
          }
        });
      } else if (this.props.type === "add") {
        crearNuevoProveedor(data).then(
          (response) => {
            if (response.error === true) {
              this.setState({
                mostrarMensaje: true,
                mensaje: response.mensaje,
                openPantallaCarga: false,
              });
            } else {
              if (this.state.logo != null) {
                var id = response.datos.id;
                var formData = new FormData();
                formData.append("file", this.state.logo);
                subirImagen(id, formData);
              }
              this.props.handleClose(false, response.mensaje);
            }
          },
          (error) => {
            if (error.response.status === 401) {
              logout();
            }
            return error;
          }
        );
      }
    }
  };

  componentDidMount() {
    this.setState({
      id: this.props.proveedor.id,
      clientSecret: this.props.proveedor.clientSecret,
      clientId: this.props.proveedor.clientId,
      descripcion: this.props.proveedor.descripcion,
      schema: this.props.proveedor.schema,
      host: this.props.proveedor.host,
      port: this.props.proveedor.port,
      activo: this.props.proveedor.activo,
      codigoCliente: this.props.proveedor.codigoCliente,
    });
    if (this.props.type === "edit") {
      cargarImagen(this.props.proveedor.id).then(
        (res) => {
          if (res != null) {
            this.setState({
              urlLogo: "data:image/png;base64," + res,
            });
          }
        },
        (error) => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        }
      );
    }
  }
  chkclick = (event) => {
    let rolesxempresa = this.state.rolesxempresa;
    rolesxempresa[this.state.empresaSelect].roles.forEach((elemento) => {
      if (elemento.id == event.target.value) {
        elemento.checked = event.target.checked;
      }
    });
    this.setState({ rolesxempresa: rolesxempresa });

    let rolesSelect = this.state.rolesSelect;
    rolesSelect.roles.forEach((elemento) => {
      if (elemento.id == event.target.value) {
        elemento.checked = event.target.checked;
      }
    });
    this.setState({ rolesSelect: rolesSelect });
  };

  render() {
    return (
      <Container>
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleProveedorSubmit}
        >
          <Modal.Header>
            <Modal.Title style={{ color: "#0c56d0" }}>
              {" "}
              {this.props.dialogTitle}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group
                    className="form-group md-input"
                    style={{ marginBottom: "35px" }}
                  >
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="clientId"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.clientId}
                    />
                    <Form.Label class="md-label">Client ID</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <p>
                        <small>Client ID</small>
                      </p>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="form-group md-input"
                    style={{ marginBottom: "35px" }}
                  >
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="clientSecret"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.clientSecret}
                    />
                    <Form.Label class="md-label"> Client secret </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <p>
                        <small>Campo requerido</small>
                      </p>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="form-group md-input"
                    style={{ marginBottom: "35px" }}
                  >
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="descripcion"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.descripcion}
                    />
                    <Form.Label class="md-label"> Descripcion </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <p>
                        <small>Campo requerido</small>
                      </p>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group md-input">
                    <Form.Label>
                      <p className="form-date">
                        <strong>Logo del proveedor</strong>
                      </p>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={this.handleUploadLogo}
                      accept="images/*"
                    />
                  </Form.Group>
                  <img
                    id="logoProveedorImage"
                    src={this.state.urlLogo}
                    accept="image/png, image/jpeg"
                    style={{ marginTop: "5px" }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group
                    className="form-group md-input"
                    style={{ marginBottom: "35px" }}
                  >
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="schema"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.schema}
                    />
                    <Form.Label class="md-label"> Esquema </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <p>
                        <small>Campo requerido</small>
                      </p>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="form-group md-input"
                    style={{ marginBottom: "35px" }}
                  >
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="host"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.host}
                    />
                    <Form.Label class="md-label"> Host </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <p>
                        <small>Campo requerido</small>
                      </p>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="form-group md-input"
                    style={{ marginBottom: "35px" }}
                  >
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="port"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.port}
                    />
                    <Form.Label class="md-label">Puerto</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <p>
                        <small>Campo requerido</small>
                      </p>
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="form-group md-input"
                    style={{ marginBottom: "20px" }}
                  >
                    <Form.Select
                      className="form-control md-select"
                      name="codigoCliente"
                      onChange={this.handleInput}
                      value={this.state.codigoCliente}
                      required
                    >
                      <option key="" value="" selected></option>
                      {psa.map((elemento) => (
                        <option key={elemento.codigo} value={elemento.codigo}>
                          {elemento.nombre}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Label class="md-label"> Cliente </Form.Label>
                  </Form.Group>
                  <Form.Group
                    className="form-group md-input"
                    style={{ marginBottom: "35px" }}
                  >
                    <Form.Check
                      type="switch"
                      id="estadoEmpresa"
                      checked={this.state.activo}
                      onChange={this.handleChange}
                      name="activo"
                      label="Activo"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} variant="contained">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit">
              {this.props.submitText}
            </Button>
          </Modal.Footer>
        </Form>
      </Container>
    );
  }
}

export default ProveedorFirma;
