import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import axios from "axios";
import {
  URL,
  getAuthorizationHeader,
  idEmpresa,
  verificarRol,
  administradorEmpresas,
  gestorEmpresa,
  empleado,
  iscertLoguin,
  firmaRL,
  reportes
} from "../../constans/constants";

const PrivateRoute = ({
  sessionData,
  roles,
  component: Component,
  ...rest
}) => {
  const cookies = new Cookies();
  var logged = cookies.get("logged") == "true";
  if (logged) {
    var rolesData = JSON.parse(localStorage.getItem("roles"));
    if (
      rolesData != null &&
      rolesData.hasOwnProperty("id") &&
      rest.rol != null) {
      if (verificarRol(rolesData, rest.rol)) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
      } else {
        if (verificarRol(rolesData, gestorEmpresa)) {
          return <Redirect to="/lote/nuevos" />;
        } else {
          if (verificarRol(rolesData, empleado)) {
            return <Redirect to="/bandeja/pendientes" />;
          }else if(verificarRol(rolesData,administradorEmpresas)){
            return <Redirect to="/usuarios" />
          }else if(verificarRol(rolesData, firmaRL)){
            return <Redirect to="/lote/firmados" />
          }else if(verificarRol(rolesData,reportes)){
            return <Redirect to="/reporte/recibo-colaborador/todos" />
          }
        }
      }
    } else {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
  } else {
    if(iscertLoguin){
      axios.get(URL + "auth").then((res) => {
      window.location.replace(res.data);
    });
  }else{
    return <Redirect to="/login"/>
  }
  }
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
  roles: state.rolesData,
});

export default connect(mapStateToProps)(PrivateRoute);
