import {
 SET_LOADING,
  SET_ROLES, 
  SET_SESSION_DATA,
  SET_EMPRESAS,GET_SESSION_DATE,
  SET_TIPO_DOCUMENTO,
  SET_NUMERO_PATRONAL,
  SET_APLICACION_EXTERNA,
  SET_USUARIO,SET_EMPRESAS_USER,
  SET_LOTES,
  SET_RECIBOS,SET_REPORTE_USUARIO,
  SET_REPORTE_RECIBOS,
  SET_SIDE_BAR,
  SET_GRUPOS,
  SET_PROVEEDORES
} from "../constans/action-types";

const initialState = {
  sessionData:{
    token: "",
    logged: false,
    admin: false,
    username: ""
  },
  rolesData: {
    id: "",
    roles: []
  },
  empresaData: {
    page: 0,
    totalCount: 0,
    empresas: []
  },
  lotesData: {
    page: 0,
    totalCount: 0,
    lotes: []
  },
  recibosData: {
    page: 0,
    totalCount: 0,
    recibos: []
  },
  tipoDocumentoData: {
    page: 0,
    totalCount: 0,
    tipoDocumento: []
  },
  numeroPatronalData: {
    page: 0,
    totalCount: 0,
    numeroPatronal: []
  },
  aplicacionExternaData: {
    page: 0,
    totalCount: 0,
    aplicacionExterna: []
  },
  usuariosData: {
    page: 0,
    totalCount: 0,
    usuarios: []
  },
  loading: false,
  empresaUser: {
    nombreCompleto: "",
    empresas: []
  },
  reporteUsuarioData: {
    page: 0,
    totalCount: 0,
    reporteUsuario: []
  },
  reporteReciboData: {
    page: 0,
    totalCount: 0,
    reporteRecibo: []
  },
  gruposData: {
    page: 0,
    totalCount: 0,
    grupos: []
  },
  proveedoresData: {
    page: 0,
    totalCount: 0,
    proveedores: []
  },
  sideBar: true 
};

function rootReducer(state = initialState, action) {
  if (action.type === SET_EMPRESAS) {
    return Object.assign({}, state, {
      empresaData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        empresas: action.payload.empresas
      }
    });
  }
  else if (action.type === SET_TIPO_DOCUMENTO) {
    return Object.assign({}, state, {
      tipoDocumentoData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        tipoDocumento: action.payload.tipoDocumento
      }
    });
  }
  else if (action.type === SET_NUMERO_PATRONAL) {
    return Object.assign({}, state, {
      numeroPatronalData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        numeroPatronal: action.payload.numeroPatronal
      }
    });
  }
  else if (action.type === SET_APLICACION_EXTERNA) {
    return Object.assign({}, state, {
      aplicacionExternaData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        aplicacionExterna: action.payload.aplicacionExterna
      }
    });
  }
  else if (action.type === SET_USUARIO) {
    return Object.assign({}, state, {
      usuariosData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        usuarios: action.payload.usuarios
      }
    });
  }
  else if (action.type === SET_REPORTE_USUARIO) {
    return Object.assign({}, state, {
      reporteUsuarioData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        reporteUsuario: action.payload.reporteUsuario
      }
    });
  }
  else if (action.type === SET_REPORTE_RECIBOS) {
    return Object.assign({}, state, {
      reporteReciboData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        reporteRecibo: action.payload.reporteRecibo
      }
    });
  }
  else if (action.type === SET_LOTES) {
    return Object.assign({}, state, {
      lotesData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        lotes: action.payload.lotes
      }
    });
  }
  else if (action.type === SET_RECIBOS) {
    return Object.assign({}, state, {
      recibosData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        recibos: action.payload.recibos
      }
    });
  }
  else if (action.type === SET_GRUPOS) {
    return Object.assign({}, state, {
      gruposData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        grupos: action.payload.grupos
      }
    });
  }
  else if (action.type === SET_PROVEEDORES) {
    return Object.assign({}, state, {
      proveedoresData: {
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        proveedores: action.payload.proveedores
      }
    });
  }
  else if (action.type === SET_LOADING) {
    return Object.assign({}, state, {
      loading: action.payload
    });
  }
  else if (action.type === SET_SIDE_BAR) {
    return Object.assign({}, state, {
      sideBar: action.payload
    });
  }  
  else if (action.type === SET_ROLES) {
    return Object.assign({}, state, {
      rolesData: {
        id: action.payload.id,
        roles: action.payload.rolesData
      }
    });
  } else if (action.type === SET_SESSION_DATA) {
    return Object.assign({}, state, {
      sessionData: {
        token: action.payload.token,
        logged: action.payload.logged,
        admin: action.payload.admin,
        username: action.payload.username
      }
    });
  }
  else if (action.type === GET_SESSION_DATE) {
    return Object.assign({}, state);
  }
  else if(action.type === SET_EMPRESAS_USER){
    return Object.assign({}, state, {
      empresaUser: {
        nombreCompleto: action.payload.nombreCompleto,
        empresas: action.payload.empresas
      }
    });
  }
  return state;
};

export default rootReducer;