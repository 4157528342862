import React from "react";
import PropTypes from "prop-types";
import { logout } from "../login/SesionService";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { seleccionarEmpresa } from '../login/SesionService'
import { idEmpresa, admin, iscert } from '../../constans/constants'
import { botonSideBar } from "./SideBar"
import menuIcon from '../../styles/icons/menu.svg'


function Header(props) {
  const cookies = new Cookies();
  const { classes, onDrawerToggle } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    props.seleccionarEmpresa(val, props.empresasUser.empresas)
    window.location.reload(true);
  };
  const nomreUsuario = () => {
    if (props.sessionData.admin) {
      return <p className={classes.pMargin}>{props.sessionData.userName}</p>
    } else {
      return <p className={classes.pMargin}>{props.empresasUser.nombreCompleto}</p>
    }
  }

  const esAdmin = () => {
    if (idEmpresa() != null || idEmpresa() != undefined) {
      return true
    } else {
      return false
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout(props.sessionData.username);
  };



  return (
    <React.Fragment>
      <div className="leftside me-auto">
        <a className="sidebar-toggle btn" onClick={() => props.botonSideBar(props.sideBar)}>
          <span className="material-icons-outlined"> <img src={menuIcon}></img> </span>
        </a>
        {/*   <form className="searchForm" action="" method="post">
      <div className="input-group input-group-lg">
        <span className="input-group-text border-0" id="basic-addon2">
          <i className="material-icons-outlined"> search </i>
        </span>
        <input type="search" className="form-control" placeholder="Buscar.." aria-label="Search" aria-describedby="basic-addon2" />
      </div>
  </form> */}
      </div>
      <div>
        {/*  {localStorage.getItem("tokenIscert")===null?<Button className="primary" onClick={iscert}>Activar Firma</Button>:null} */}
      </div>
      <ul className="navbar-nav">
        {esAdmin() &&

          <li className="md-6">

            <select className="form-select form-select-lg md-6" onChange={handleInput} value={props.roles.id}>

              {props.empresasUser.empresas.map((elemento) => (

                
                <option key={elemento.id} value={elemento.id} >

                  {elemento.nombre}

                </option>

              ))}

            </select>

          </li>

        }
      </ul>

    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
  seleccionarEmpresa: (idEmpresa, userEmpresas) => dispatch(seleccionarEmpresa(idEmpresa, userEmpresas)),
  botonSideBar: (sideBar) => dispatch(botonSideBar(sideBar))
});
const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
  empresasUser: state.empresaUser,
  roles: state.rolesData,
  sideBar: state.sideBar
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
