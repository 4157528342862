import React, { Component } from "react";
import { connect } from "react-redux";
import {
  guardarAplicacionExterna,
  editarAplicacionExterna,
} from "../../components/aplicacionExterna/AplicacionExternaService";
import { idEmpresa } from "../../constans/constants";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap"; 
import { getEmpresa } from "../login/SesionService";

class AplicacionExterna extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aplicacion: "",
      descripcion: "",
      url: "",
      usuario: "",
      pwd: "",
      id: "",
      mensaje: "",
      severity: "",
      openPantallaCarga: false,
      validated: false,
      listaEmpresas: props.listaEmpresa,
      listaEmpresaSelected: props.listaEmpresaSelected,
    };
  }

  handleClose = () => {
    this.props.handleClose(false, this.state.mensaje, this.state.severity);
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleAplicacionExternaSubmit = event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true })

    } else {
      this.setState({ openPantallaCarga: true });
      if (this.props.type === "edit") {
        const json = {
          aplicacion: this.state.aplicacion,
          descripcion: this.state.descripcion,
          url: this.state.url,
          usuario: this.state.usuario,
          password: this.state.pwd,
          id: this.state.id,
          idEmpresa: this.props.isAdmin ? this.state.listaEmpresaSelected : idEmpresa()
        };
        this.props.editarAplicacionExterna(json).then((response) => {
          if (response.error) {
            this.setState({ mensaje: response.mensaje });
            this.setState({ severity: "danger" });
          } else {
            this.setState({ mensaje: response.mensaje });
            this.setState({ severity: "success" });
          }
          this.handleClose();
        });
      } else if (this.props.type === "add") {
        const json = {
          aplicacion: this.state.aplicacion,
          descripcion: this.state.descripcion,
          url: this.state.url,
          usuario: this.state.usuario,
          password: this.state.pwd,
          idEmpresa: this.props.isAdmin ? this.state.listaEmpresaSelected : idEmpresa()
        };
        this.props.guardarAplicacionExterna(json).then((response) => {
          if (response.error) {
            this.setState({ mensaje: response.mensaje });
            this.setState({ severity: "danger" });
          } else {
            this.setState({ mensaje: response.mensaje });
            this.setState({ severity: "success" });
          }
          this.handleClose();
        });
      }
    }
  };

  componentDidMount() {
    if (this.props.type === "edit") {
      this.setState({ 
        aplicacion: this.props.aplicacionExterna.aplicacion,
        descripcion: this.props.aplicacionExterna.descripcion,
        url: this.props.aplicacionExterna.url,
        usuario: this.props.aplicacionExterna.usuario,
        pwd: this.props.aplicacionExterna.password,
        id: this.props.aplicacionExterna.id,
        listaEmpresaSelected: this.props.aplicacionExterna.empresa.id
      });
    }
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };

  handleChangeSelect = (e) => {
    this.setState({ listaEmpresaSelected: e.target.value })
  }

  render() {
    return (
      <Container fluid>
        <Form ref="form" noValidate validated={this.state.validated} onSubmit={this.handleAplicacionExternaSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}>
              {" "}
              {this.props.dialogTitle}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {
            (this.props.isAdmin && this.props.listaEmpresas != null) ?
              <Row>
              <Col xs={6} style={{ paddingTop: "10px" }}>
                <Form.Group className="form-group md-input">
                    <Form.Select className="form-control md-select" name="idGrupo" id="idGrupo"
                      onChange={this.handleChangeSelect} value={this.state.listaEmpresaSelected} defaultValue={this.state.listaEmpresaSelected} required>
                      {this.props.listaEmpresas.map((elemento) => (
                        <option value={elemento.id} key={elemento.id}>{elemento.nombre}</option>
                      ))}
                    </Form.Select>
                  <Form.Label class="md-label"> Seleccione una empresa </Form.Label>
                  </Form.Group>
              </Col>
              <Col xs={6} style={{ paddingTop: "10px" }}>
              <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="aplicacion"
                      id="aplicacion"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.aplicacion}
                      required
                    />
                    <Form.Label class="md-label"> Nombre Aplicación </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            :
            <Row>
              <Col style={{ padding: "10px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="aplicacion"
                      id="aplicacion"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.aplicacion}
                      required
                    />
                    <Form.Label class="md-label"> Nombre Aplicación </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            }
            <Row>
              <Col style={{ padding: "10px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="descripcion"
                      id="descripcion"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.descripcion}
                      required
                    />
                    <Form.Label class="md-label"> Descripción </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "10px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <input
                      className="form-control md-form-control"
                      type="url"
                      name="url"
                      id="url"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.url}
                      required
                    />
                    <Form.Label class="md-label"> URL </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6} style={{ paddingTop: "10px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="usuario"
                      id="usuario"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.usuario}
                      required
                    />
                    <Form.Label class="md-label"> Usuario </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col xs={6} style={{ paddingTop: "10px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <input
                      className="form-control md-form-control"
                      type="password"
                      name="pwd"
                      id="pwd"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.pwd}
                      required
                    />
                    <Form.Label class="md-label"> Contraseña </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit">
              {this.props.submitText}
            </Button>
          </Modal.Footer>
        </Form>
        <PantallaCarga open={this.state.openPantallaCarga} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  guardarAplicacionExterna: (value) =>
    dispatch(guardarAplicacionExterna(value)),
  editarAplicacionExterna: (value) => dispatch(editarAplicacionExterna(value)),
});

export default connect(null, mapDispatchToProps)(AplicacionExterna);
