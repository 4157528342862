import React from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { URL, getAuthorizationHeader,admin } from "../../constans/constants";
import { obtenerRolesEmpresaDatos,obtenerRolesEmpresa } from "./UsuarioService";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { logout } from "../login/SesionService";

export const esSesionAdmin = () => {
  const cookies = new Cookies();
  return cookies.get("admin");
};

export const obtenerIdEmpresa = () => {
  const cookies = new Cookies();
  return cookies.get("empresa");
};

class UsuarioDatos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: "",
      identificador: "",
      nombres: "",
      apellidos: "",
      cedula: "",
      email: "",
      id: "",
      celular: "",
      roles: [],
      nroPersonalIps: "",
      nroTrabajoMtess: "",
      datosEmpresas: [],
      empresaId: "",
      roles: []
    };
  }

  handleClose = () => {
    this.props.handleClose(false);
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  componentDidMount() {
    if(admin()){
      obtenerRolesEmpresa(this.props.usuario.id).then((res) => {
        if (!res.error) {
            this.setState({
              datosEmpresas: res.datos,
              empresaId: res.datos[0],
              roles: res.datos[0].roles,
              nombreApellido: this.props.usuario.nombreApellido,
              apellidos: this.props.usuario.apellidos,
              identificador: this.props.usuario.identificador,
              usuario: this.props.usuario.usuario,
              cedula: this.props.usuario.cedula,
              email: this.props.usuario.email,
              celular: this.props.usuario.celular,
              nroPersonalIps: this.props.usuario.nroPersonalIps,
              nroTrabajoMtess: this.props.usuario.nroTrabajoMtess,
            })
        }
      })
    }else{
      obtenerRolesEmpresaDatos(this.props.usuario.id).then((res) => {
        if (!res.error) {
            this.setState({
              datosEmpresas: res.datos,
              empresaId: res.datos.find((i) => i.id == obtenerIdEmpresa()),
              roles: res.datos.find((i) => i.id == obtenerIdEmpresa()).roles,
              nombreApellido: this.props.usuario.nombreApellido,
              apellidos: this.props.usuario.apellidos,
              identificador: this.props.usuario.identificador,
              usuario: this.props.usuario.usuario,
              cedula: this.props.usuario.cedula,
              email: this.props.usuario.email,
              celular: this.props.usuario.celular,
              nroPersonalIps: this.props.usuario.nroPersonalIps,
              nroTrabajoMtess: this.props.usuario.nroTrabajoMtess,
            })
        }
      })
    }

  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;

    var empresa = this.state.datosEmpresas.find((elemento) => elemento.id.toString() === val);

    this.setState({
      [name]: val,
      roles: empresa.roles
    });
  };
  render() {
    return (
      <div className="userContent" style={{ marginLeft: "10px", marginTop: "10px", marginBottom: "10px", marginRight: '10px', backgroundColor: "#EDF2F9", borderRadius: '0.6rem' }}>
        <div className="userInfo" style={{ marginLeft: '15px' }}>
          <Row className="userInfo-row" style={{ marginBottom: '20px' }}>
            <Col xs={6} md={4} style={{ marginTop: "10px" }}>
              <div className="item-info">
                <span><strong>Nro. personal IPS: </strong> </span> {this.state.nroPersonalIps}
              </div>
            </Col>
            <Col xs={6} md={4} style={{ marginTop: "10px" }}>
              <div className="item-info">
                <span><strong>Nro. telefono: </strong></span> {this.state.celular}
              </div>
            </Col>
            <Col xs={6} md={4} style={{ marginTop: "10px" }}>
              <div className="item-info">
                <span><strong>Nro Trabajo MTESS: </strong></span>
                {this.state.nroTrabajoMtess}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4} style={{ marginBottom: '10px' }}>
              <Form.Group className="form-group md-input">
                <Form.Select className="form-control md-select" name="empresaId" id="empresaId" value={this.state.empresaId}
                  onChange={this.handleInput}>
                  {this.state.datosEmpresas.map((elemento) => (
                    <option key={elemento.id} value={elemento.id}>
                      {elemento.nombre}
                    </option>
                  ))}
                </Form.Select>
                <Form.Label class="md-label"> Seleccionar empresa </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <p><strong>Roles Asignados</strong></p>
            </Col>
          </Row>
          <Row xs="auto" style={{ marginBottom: '10px' }}>
            {this.state.roles &&
              this.state.roles.map((elemento) => (
                <Col>
                  <Form.Group>
                    <Form.Check
                      type='checkbox'
                      onClick={this.chkclick}
                      key={elemento.id}
                      checked={elemento.checked}
                      value={elemento.id}
                      color="default"
                      style={{ color: "#1565D8" }}
                      label={elemento.nombre}
                    />
                  </Form.Group>
                </Col>
              ))
            }
          </Row>
        </div>
      </div>
    );
  }
}

export default UsuarioDatos;
