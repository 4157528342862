import React, { Component } from "react";
import { connect } from "react-redux";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Button, Container, Row, Col, Modal, Form,Table } from "react-bootstrap";
import { getUsuario } from "../usuario/UsuarioService";
import MaterialTable, { MTableToolbar } from 'material-table';
import {idEmpresa} from "../../constans/constants";
import {registrarGrupo, editarGrupo} from "../usuario/UsuarioService";
import delete_forever from '../../styles/icons/delete.svg';
import add_circle from '../../styles/icons/add_circle.svg';

class GrupoUsuario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descripcion: "",
      activo: true,
      id: "",
      usuarios: [],
      mensaje: "",
      severity: "",
      usuariosAux: [],
      openPantallaCarga: false,
      validated: false
    };
    this.tableRef = React.createRef();
  }

  handleClose = () => {
    this.props.handleClose(false, this.state.mensaje, this.state.severity);
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleAplicacionExternaSubmit = event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true })

    } else {
      this.setState({ openPantallaCarga: true });
      if (this.props.type === "edit") {
        const json = {
          descripcion: this.state.descripcion,
          activo: this.state.activo,
          id: this.state.id,
          usuarios: this.state.usuariosAux, 
          idEmpresa: idEmpresa()
        };
        this.props.editarGrupo(json).then((response) => {
          if (response.error) {
            this.setState({ mensaje: response.mensaje });
            this.setState({ severity: "danger" });
          } else {
            this.setState({ mensaje: response.mensaje });
            this.setState({ severity: "success" });
          }
          this.handleClose();
        });
      } else if (this.props.type === "add") {
        const json = {
            descripcion: this.state.descripcion,
            activo: this.state.activo,
            usuarios: this.state.usuariosAux,
            idEmpresa: idEmpresa()
        };
        this.props.registrarGrupo(json).then((response) => {
          if (response.error) {
            this.setState({ mensaje: response.mensaje });
            this.setState({ severity: "danger" });
          } else {
            this.setState({ mensaje: response.mensaje });
            this.setState({ severity: "success" });
          }
          this.handleClose();
        });
      }
    }
  };
  agregarUsuario(usuariosData){
    var usuarioAuxA = this.state.usuariosAux
    usuariosData.map((elemento) => {
      if(!usuarioAuxA.some(elementoAux => (elementoAux.id === elemento.id))){
        usuarioAuxA.push(elemento)
      }
  });
    this.setState({usuariosAux: usuarioAuxA})
    this.tableRef.current.onQueryChange();
  }

  eliminarUsuario(usuariosData){
    var usuarioAuxA = this.state.usuariosAux
    usuariosData.map((elemento) => {
      usuarioAuxA = usuarioAuxA.filter(elementoAux => (elementoAux.id != elemento.id))
  });
    this.setState({usuariosAux: usuarioAuxA})
    this.tableRef.current.onQueryChange();
  }
  componentDidMount() {
    if (this.props.type === "edit") {
      this.setState({
        descripcion:this.props.grupo.descripcion,
        activo: this.props.grupo.activo,
        id: this.props.grupo.id,
        usuariosAux: this.props.grupo.miembros,
      });
    }
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };
  render() {
    return (
      <Container fluid>
        <Form ref="form" noValidate validated={this.state.validated} onSubmit={this.handleAplicacionExternaSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}>
              {" "}
              {this.props.dialogTitle}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col  xs={6} style={{ padding: "10px" }}>
              <p className="form-date" style={{paddingBottom:"10px"}}>
                  {" "}
                  <h5><strong>Datos del Grupos</strong></h5>
                </p>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <input
                      className="form-control md-form-control"
                      type="text"
                      name="descripcion"
                      id="descripcion"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.descripcion}
                      required
                    />
                    <Form.Label class="md-label"> Descripción </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                {/* {(this.props.type === "add")?<Row>
                <Col>                  
                <Form.Group className="form-group md-input"  style={{ marginBottom: '35px' }}>
                    <Form.Check
                      type="switch"
                      id="estadoGrupo"
                      checked={this.state.activo}
                      onChange={this.handleChange}
                      name="activo"
                      label="Activo"
                    />
                  </Form.Group>
                  </Col>
            </Row>:null} */}
            <Row>
          <Col>
            <div style={{overflow:"auto", height:"300px"}}>
            <Table striped bordered hover size="sm" responsive="md">
                <thead>
                    <tr>
                      <th>Nombre Y Apellido</th>
                      <th>Usuario</th>
                      <th>C.I.</th>
                    </tr>
                </thead>
                <tbody>
                  {this.state.usuariosAux.map((elemento) => (
                    <tr key={elemento.nombre}>
                      <td component="th" scope="row">
                        {elemento.nombreApellido}
                      </td>
                      <td>{elemento.usuario}</td>
                      <td>{elemento.cedula}</td>
                    </tr>
                  ))} 
                </tbody>
            </Table>
            </div>
          </Col>
        </Row>
              </Col>
              <Col xs={6} style={{padding: "10px"}}>
              <p className="form-date">
                  {" "}
                  <h5><strong>Usuarios</strong></h5>
                </p>
                <MaterialTable
                style={{ borderRadius: ".5rem" }}
                tableRef={this.tableRef}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                columns={[
                  {
                    title: "Nombres y Apellidos", field: "nombreApellido", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Usuario", field: "usuario", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "C.I.", field: "cedula", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  }
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    this.props.getUsuario(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.usuariosData.usuarios,
                        page: this.props.usuariosData.page,
                        totalCount: this.props.usuariosData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  {
                    tooltip: 'Agregar Usuarios al Grupo',
                    icon: ()=><img src={add_circle} alt="Agregar"/>,
                    onClick: (evt, data) => this.agregarUsuario(data)
                  },
                  {
                    tooltip: 'Eliminar Usuarios del Grupo',
                    icon: ()=><img src={delete_forever} alt="Eliminar"/>,
                    onClick: (evt, data) => this.eliminarUsuario(data)
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  toolbar: true,
                  detailPanelColumnAlignment: "right",
                  searchFieldVariant: "standard",
                  searchFieldAlignment: "right",
                  selection: "true",
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold",
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },

                }}
              />
                </Col>
            </Row>
       
               
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit">
              {this.props.submitText}
            </Button>
          </Modal.Footer>
        </Form>
        <PantallaCarga open={this.state.openPantallaCarga} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  registrarGrupo: (value) => dispatch(registrarGrupo(value)),
  editarGrupo: (value) => dispatch(editarGrupo(value)),
  getUsuario: (page, size, search) => dispatch(getUsuario(page, size, search)),
});

const mapStateToProps = (state) => ({
    usuariosData: state.usuariosData,
  });

export default connect(mapStateToProps, mapDispatchToProps)(GrupoUsuario);
