
import {
  administradorEmpresas, gestorEmpresa,
  empleado, firmaRL, firmaRRHH, reportes
} from './constans/constants'
const categories = [
  {
    id: 'Administracion',
    rol: [administradorEmpresas],
    url: '/administracion/usuarios',
    raiz: '/administracion',
    icon: 'manage_accounts'
  },
  {
    id: 'Configuracion',
    rol: [gestorEmpresa],
    url: '/configuracion/tipo-documento',
    raiz: '/configuracion',
    icon: 'settings'
  },
  {
    id: 'Lotes',
    rol: [gestorEmpresa, firmaRL, firmaRRHH],
    url: '/lote/nuevos',
    raiz: '/lote',
    icon: 'folder_zip'
  },
  {
    id: 'Bandeja',
    rol: [empleado],
    url: '/bandeja/pendientes',
    raiz: '/bandeja',
    icon: 'inbox'
  },
  {
    id: 'Reportes',
    rol: [gestorEmpresa,reportes],
    url: '/reporte/recibo-colaborador/todos',
    raiz: '/reporte',
    icon: 'summarize'
  }
];
export default categories;