import React from "react";
import { cargarImagen } from "./ProveedorFirmaService";
import { Col, Container, Modal, Row } from "react-bootstrap"
import { logout } from '../login/SesionService'

class ProveedorFirmaDatos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            clientSecret: "",
            clientId: "",
            descripcion: "",
            schema: "",
            host: "",
            port: "",
            activo: "",
            urlLogo: null
        };
    }

    handleClose = () => {
        this.props.handleClose(false);
    };

    handleEmpresaSubmit = () => {
        if (this.props.type == "edit") {
            const json = {
                nombre: this.state.nombre,
                direccion: this.state.direccion,
                ruc: this.state.ruc,
                id: this.state.id,
                estado: this.state.estado,
            };
            this.props.editarEmpresa(json).then(() => {
                this.handleClose();
                window.location.reload();
            });
        } else if (this.props.type == "add") {
            const json = {
                nombre: this.state.nombre,
                direccion: this.state.direccion,
                ruc: this.state.ruc,
                id: this.state.id,
                estado: this.state.estado,
            };
            this.props.guardarEmpresa(json).then(() => {
                this.handleClose();
            });
        }
    };

    componentDidMount() {
        this.setState({
            id: this.props.proveedor.id,
            clientSecret: this.props.proveedor.clientSecret,
            clientId: this.props.proveedor.clientId,
            descripcion: this.props.proveedor.descripcion,
            schema: this.props.proveedor.schema,
            host: this.props.proveedor.host,
            port: this.props.proveedor.port,
            activo: this.props.proveedor.activo
        });
        cargarImagen(this.props.proveedor.id)
            .then(res => {
                if (res != null) {
                    this.setState({
                        urlLogo: "data:image/png;base64," + res
                    })
                }
            }, error => {
                if (error.response.status === 401) {
                    logout();
                }
                return error;
            })
    }

    render() {
        return (
            <Container>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: "#0c56d0" }}> {this.props.dialogTitle} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="info-doc">
                                    <div className="title">Client ID:</div>
                                    <div className="text">{this.state.clientId}</div>
                                </div>
                                <div className="info-doc">
                                    <div className="title">Descripcion:</div>
                                    <div className="text">{this.state.descripcion}</div>
                                </div>
                                <div className="info-doc">
                                    <div className="title">Client Secret:</div>
                                    <div className="text">{this.state.clientSecret}</div>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="info-doc">
                                    <div className="title">Esquema:</div>
                                    <div className="text">{this.state.schema}</div>
                                </div>
                                <div className="info-doc">
                                    <div className="title">Host:</div>
                                    <div className="text">{this.state.host}</div>
                                </div>
                                <div className="info-doc">
                                    <div className="title">Puerto:</div>
                                    <div className="text">{this.state.port}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="info-doc">
                                    <div className="title">Logo:</div>
                                    <img
                                        id="logoProveedorImg"
                                        src={this.state.urlLogo}
                                        alt="--Sin Logo--"
                                        style={{ marginTop: '10px' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Container>
        );
    }
}

export default ProveedorFirmaDatos;
