import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getLotes } from "./LoteService";
import "../../styles/Empresa.css";
import { Link } from "react-router-dom";
import Navbar from "../home/Navbar";
import visibility from '../../styles/icons/visibility.svg';


class LotesPublicar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecibo: {
        nombre: "",
        fechaImportacion: "",
        descripcion: "",
        cantDocumentos: "",
        tipoDocumento: "",
        estado: "",
        estadoMTESS: false
      },
    };
    localStorage.setItem("urlRaiz", window.location.href);
    this.tableRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }
  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
            <div className="sectionHeader">
              <h1 className="pageTitle"> Lotes Para Publicar </h1>
            </div>
            <Navbar
              menuActual={window.location.hash}
              seccionActual={'lotes'}
            />
            <div className="contentPage card" style={{ padding: "0px" }}>
              <MaterialTable
                title="Lotes Para Publicar"
                style={{ borderRadius: ".5rem" }}
                tableRef={this.tableRef}
                columns={[
                  {
                    title: "Lote", field: "numero", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Fecha de importación", field: "fechaImportacion", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Descripción", field: "descripcion", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Cant. Documentos", field: "cantDocumentos", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Tipo de documento", field: "tipoDocumento", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Estado", field: "estado", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Estado MTESS", field: "estadoMTESS", type: "boolean", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                ]}
                data={(query) =>

                  new Promise((resolve, reject) => {
                    this.props.getLotes(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.lotesData.lotes,
                        page: this.props.lotesData.page,
                        totalCount: this.props.lotesData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  rowData => (
                    {
                      icon: () => <Link to={{pathname: '/lote/recibos/publicar/'+rowData.id, state: { rowData, estado: 'publicar' } }} style={{ textDecoration: 'none' }}><img src={visibility} /></Link>,
                      tooltip: 'Ver',
                      onClick: (rowData) => {
                      }
                    })
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  toolbar: true,
                  detailPanelColumnAlignment: "right",
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold"
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getLotes: (page, size, search) => dispatch(getLotes(page, size, search)),
});
const mapStateToProps = (state) => ({
  lotesData: state.lotesData,
});

export default connect(mapStateToProps, mapDispatchToProps)(LotesPublicar);
