import axios from 'axios';
import {
    SET_NUMERO_PATRONAL,
    SET_LOADING
  } from "../../constans/action-types";
  import {URL,getAuthorizationHeader} from '../../constans/constants';
  import Cookies from 'universal-cookie';
  import {logout} from '../login/SesionService'


  const cookies = new Cookies();

  const defaultPage = 0;
  const defaultSize = 5;
  export const setNumeroPatronal = payload => ({ type: SET_NUMERO_PATRONAL, payload });
  export const setLoading = payload => ({ type: SET_LOADING, payload });

  export const getNumeroPatronal = (page = defaultPage,size = defaultSize, search) => {
    return dispatch => {
      var urlRequest = ""
      var idEmpresa = cookies.get('empresa');
      if((search == null || search == '') && (idEmpresa == null || idEmpresa == undefined)){
        urlRequest = URL+"numeroPatronal/" + 'listar?page=' + page+"&size=" + size
      }else if((search != null || search != '') && (idEmpresa == null || idEmpresa == undefined)){
        urlRequest = URL+"numeroPatronal/" + 'listar?page=' + page+"&size=" + size +"&search=" + search
      }else if((search == null || search == '') && (idEmpresa != null)){
        urlRequest = URL+"numeroPatronal/" + 'listar?page=' + page+"&size=" + size +"&idEmpresa="+idEmpresa
      }else if(search != null && idEmpresa != null){
        urlRequest = URL+"numeroPatronal/" + 'listar?page=' + page+"&size=" + size +"&search=" + search+"&idEmpresa="+idEmpresa
      }
      return axios.get(urlRequest,getAuthorizationHeader())
        .then(res => {
          dispatch(setNumeroPatronal({
            page: res.data.page.pageable.pageNumber,
            totalCount: res.data.page.totalElements,
            numeroPatronal: res.data.page.content
          }));
        }, error => {
          if (error.response.status === 401) {
           logout();
          }
          return error;
        })
    };
  };
  export const guardarNumeroPatronal = (numeroPatronal) => {
    return dispatch => { 
      var idEmpresa = cookies.get('empresa')
      dispatch(setLoading(true));
      const json = {
        numeroPatronal: numeroPatronal.numeroPatronal,
        descripcion: numeroPatronal.descripcion,
        empresaId: idEmpresa
      };
      return axios.post(URL+ 'numeroPatronal/agregar', json,getAuthorizationHeader())
        .then(response => {
            return response.data
        }, error => {
          if (error.response.status === 401) {
           logout();
          }
          return error;
        })
      }
  };

  export const editarNumeroPatronal = (numeroPatronal) => {
    return dispatch => { 
      dispatch(setLoading(true));
      const json = {
        numeroPatronal: numeroPatronal.numeroPatronal,
        descripcion: numeroPatronal.descripcion
      };
      return axios.post(URL+ 'numeroPatronal/modificar/'+numeroPatronal.id, json,getAuthorizationHeader())
        .then(response => {
          dispatch(setLoading(false));
          return response.data
        }, error => {
          if (error.response.status === 401) {
           logout();
          }
          return error;
        })
      }
  }

  export const eliminarNumeroPatronal = (id) => {
    return dispatch => { 
      dispatch(setLoading(true));
      return axios.delete(URL+ 'numeroPatronal/'+id,getAuthorizationHeader())
        .then(response => {
          dispatch(setLoading(false));
          return response.data;
        }, error => {
          if (error.response.status === 401) {
           logout();
          }
          return error;
        });
      }
  }
