import axios from "axios"
import { getAuthorizationHeader, URL } from "../../constans/constants"
import { logout } from "../login/SesionService";

export const getTipoEventos = ( empresaId ) => {
  return axios.get( URL + "notificacion/tipos/" + empresaId, getAuthorizationHeader())
      .then( res => {
        return res.data;
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
}

export const getPlantillas = ( empresaId ) => {
  return axios.get( URL + "notificacion/plantillas/" + empresaId, getAuthorizationHeader())
      .then( res => {
        return res.data;
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
}

export const guardarPlantilla = (json) => {
  return axios.post(URL+ 'notificacion/guardarPlantilla', json,getAuthorizationHeader())
    .then(response => {
      return response.data;
    }, error => {
      if (error.response.status === 401) {
       logout();
      }
      return error;
    });
}

export const getConfiguracionCorreo = () => {
  return axios.get( URL + "notificacion/configuracionCorreo", getAuthorizationHeader())
      .then( res => {
        return res.data;
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
}

export const guardarConfiguracionCorreo = (json) => {
  return axios.post(URL+ 'notificacion/guardarConfiguracionCorreo', json,getAuthorizationHeader())
    .then(response => {
      return response.data;
    }, error => {
      if (error.response.status === 401) {
       logout();
      }
      return error;
    });
}