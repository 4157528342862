import React from "react";
import Alert from "react-bootstrap/Alert";
import checkCircle from '../../styles/icons/check-circle.svg'
import xCircle from '../../styles/icons/x-circle.svg'
/* 
const Alert = React.forwardRef(
    function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); */

/* function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
} */

class MensajeFlash extends React.Component {

    constructor(props){
        super(props);
    }
    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.handleClose();
    };

    icono = () =>{
        if(this.props.severity == "success"){
            return (<img src={checkCircle} width={24} height={24} style={{paddingRight:'7px'}}></img>)
        }
        else{
            return(<img src={xCircle} width={24} height={24} style={{paddingRight:'7px'}}></img>)
        }
    }

    render (){
        return (
            <div>
                <Alert 
                style={{height:"65px"}}
                show={this.props.open}
                onClose={this.handleClose}
                variant={this.props.severity} dismissible>
                <p class="font-weight-bold">{this.icono()}{this.props.mensaje}</p>
                </Alert >
            </div>
        );
    }
}

MensajeFlash.defaultProps = {
    severity: 'error',
    open: true
};

export default MensajeFlash;