import React, { Component } from 'react'
import { Container, Modal, Row, Col, Button, Form } from 'react-bootstrap'
import { obtenerDatosUsuario, getAparienciaFirma, subirAparienciaFirma } from './UsuarioService';
import Resizer from "react-image-file-resizer";
import { Cookies } from 'react-cookie';

class DatosUsuarioModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datosUsuario: "",
            empresa: "",
            roles: [],
            aparienciaFirma: "",
            uriAparienciaFirma: "",
            isAparienciaNueva: false,
            isErrorAparienciaFirma: false,
            mensajeErrorAparienciaFirma: "",
        };
    }

    componentDidMount() {
        const cookies = new Cookies()
        let datosUsuario, aparienciaFirma
        obtenerDatosUsuario(this.props.nombreUsuario).then(resp => {
            if (!resp.error) {
                datosUsuario = resp.datos
                getAparienciaFirma(datosUsuario.id).then(resp => {
                    aparienciaFirma = "data:image/png;base64," + resp
                    this.setState({
                        datosUsuario: datosUsuario,
                        empresa: cookies.get('nombreEmpresa'),
                        roles: this.getRolesAsignados(),
                        aparienciaFirma: aparienciaFirma
                    })

                })
            }
        })

    }

    getRolesAsignados = () => {
        let aux = JSON.parse(localStorage.getItem("roles"))
        let roles = []
        aux.roles.map((elemento) => {
            roles.push(elemento.nombre)
        })
        return roles
    }

    handleUploadAparienciaFirma = () =>{
        var formData = new FormData()
        formData.append("file", this.state.uriAparienciaFirma)
        subirAparienciaFirma(formData, this.props.nombreUsuario)
        .then((resp =>{
            this.setState({
                isErrorAparienciaFirma: resp.error,
                mensajeErrorAparienciaFirma: resp.mensaje
            })
        }))
    }

    handleChangeAparienciaFirma = (event) => {
        try {
            Resizer.imageFileResizer(
                event.target.files[0],
                200, 150,
                "PNG",
                0, 0,
                (uri) => {
                    this.setState({
                        aparienciaFirma: URL.createObjectURL(uri),
                        uriAparienciaFirma: uri,
                        isAparienciaNueva: true
                    })
                },
                "file",
            )

        } catch (error) {
            this.setState({
                mensajeErrorAparienciaFirma: "Hubo un problema al cargar la imagen",
            });
        }
    }

    render() {
        return (
            <Container>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.dialogTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={8} md={4}>
                            <div className="info-doc">
                                <div className="title">Nombres y apellidos</div>
                                <div className="text">{this.state.datosUsuario.nombreApellido}</div>
                            </div>
                            <div className="info-doc">
                                <div className="title">Nombre de usuario </div>
                                <div className="text">{this.state.datosUsuario.usuario}</div>
                            </div>
                            <div className="info-doc">
                                <div className="title">Cedula de identidad </div>
                                <div className="text">{this.state.datosUsuario.cedula}</div>
                            </div>
                            <div className="info-doc">
                                <div className="title">Identificador </div>
                                <div className="text">{this.state.datosUsuario.identificador}</div>
                            </div>
                            <div className="info-doc">
                                <div className="title">E-mail</div>
                                <div className="text">{this.state.datosUsuario.email}</div>
                            </div>
                        </Col>
                        <Col xs={8} md={4}>
                            <div className="info-doc">
                                <div className="title">Nro. IPS Patronal </div>
                                <div className="text">{this.state.datosUsuario.nroPersonalIps}</div>
                            </div>
                            <div className="info-doc">
                                <div className="title">Nro. Trabajo MTESS </div>
                                <div className="text">{this.state.datosUsuario.nroTrabajoMtess}</div>
                            </div>
                            <div className="info-doc">
                                <div className="title">Empresa </div>
                                <div className="text">{this.state.empresa}</div>
                            </div>
                            <div className="info-doc">
                                <div className="title">Telefono </div>
                                <div className="text">{this.state.datosUsuario.celular}</div>
                            </div>
                            <div className="info-doc">
                                <div className="title">Roles asignados</div>
                                <div className="text">
                                    <ul>
                                        {this.state.roles.map((elemento) => {
                                            return <li>{elemento}</li>
                                        })}
                                    </ul>
                                </div>
                            </div>

                        </Col>
                        <Col xs={8} md={4}>
                            <div className="info-doc">
                                <div className="title">Apariencia de firma</div>
                                <img
                                    id="aparienciaFirmaImg"
                                    src={this.state.aparienciaFirma}
                                    accept="image/png, image/jpeg"
                                    style={{ marginTop: '5px' }}
                                />
                            </div>
                            <div className="info-doc">
                                <div className="title">Cambiar apariencia actual</div>
                                    <Form.Control type="file" accept="image/png, image/jpeg"
                                        style={{ marginBottom: '0px' }}
                                        onChange={this.handleChangeAparienciaFirma}
                                    />
                                    <small style={this.state.isErrorAparienciaFirma ? {color: 'red', bold: true} : {color : 'green', bold: true}}>
                                         {this.state.mensajeErrorAparienciaFirma}
                                    </small>
                                
                            </div>
                        </Col>


                    </Row>
                </Modal.Body>
                {
                    this.state.isAparienciaNueva &&
                    <Modal.Footer>
                        <Button color="primary" onClick={this.handleUploadAparienciaFirma}>
                            Subir apariencia nueva
                        </Button>
                    </Modal.Footer>
                }
            </Container>
        )
    }
}

export default DatosUsuarioModal;
