import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import DocumentDialogContent from "./ProveedorFirma";
import { Modal, Button } from "react-bootstrap";
import { getProveedoresFirmas } from "./ProveedorFirmaService";
import ProveedorFirmaDatos from "./ProveedorFirmaDatos";
import  Navbar  from '../home/Navbar';
import visibility from '../../styles/icons/visibility.svg';
import editIcon from '../../styles/icons/edit.svg'

class ProveedoresFirma extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogTitle: "Registrar nuevo proveedor de firma",
            dialogType: "add",
            submitText: "Guardar",
            disabledInput: false,
            selectedProveedor: {
                id: "",
                clientSecret: "",
                clientId: "",
                descripcion: "",
                schema: "",
                host: "",
                port: "",
                activo: true,
            },
            dialogIsOpen: false,
            dialogIsOpenVerDatos: false,
            mensajeFlash: "",
            mostrarMensajeFlash: false
        }
        this.tableRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            this.tableRef.current && this.tableRef.current.onQueryChange();
        }
    }

    handleDialog = (value, mensaje = "") => {
        if (value === false) {
            this.tableRef.current.onQueryChange();  //Para actualizar la grilla
            if (mensaje != "") {
                this.setState({
                    mostrarMensajeFlash: true,
                    mensajeFlash: mensaje
                });
            }
        }
        this.setState({ dialogIsOpen: value });
    };

    handleCloseMensajeFlash = () => {
        this.setState({
            mostrarMensajeFlash: false,
        });
    };

    handleDialogVerDatos = (value) => {
        if (value === false) {
            this.tableRef.current.onQueryChange();  //Para actualizar la grilla
        }
        this.setState({
            dialogIsOpenVerDatos: value,
        });
    };

    handleNewProveedor = () => {
        this.setState(
          {
            dialogTitle: "Nuevo proveedor de firma",
            selectedProveedor: {
                id: "",
                clientSecret: "",
                clientId: "",
                descripcion: "",
                schema: "",
                host: "",
                port: "",
                activo: true,
            },
            dialogType: "add",
            submitText: "Guardar",
            disabledInput: false,
          },
          () => {
            this.handleDialog(true);
          }
        );
      };

    render() {
        return (
            <div>
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                />
                <div className="dashboardContent">
                    <div className="container-fluid">
                        <MensajeFlash
                            mensaje={this.state.mensajeFlash}
                            severity={"success"}
                            open={this.state.mostrarMensajeFlash}
                            handleClose={this.handleCloseMensajeFlash} />
                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.dialogIsOpen}
                            onHide={this.handleDialog}
                            backdrop="static"
                            keyboard="false">
                            <DocumentDialogContent
                                proveedor={this.state.selectedProveedor}
                                handleClose={this.handleDialog}
                                type={this.state.dialogType}
                                submitText={this.state.submitText}
                                disabledInput={this.state.disabledInput}
                                dialogTitle={this.state.dialogTitle}
                            />
                        </Modal>
                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.dialogIsOpenVerDatos}
                            onHide={this.handleDialogVerDatos}
                            backdrop="static"
                            keyboard="false">
                            <ProveedorFirmaDatos
                                proveedor={this.state.selectedProveedor}
                                handleClose={this.handleDialogVerDatos}
                                type={this.state.dialogType}
                                submitText={this.state.submitText}
                                disabledInput={this.state.disabledInput}
                                dialogTitle={this.state.dialogTitle}
                            />
                        </Modal>
                        <div className="sectionHeader">
                            <h1 className="pageTitle"> Proveedores de firma digital </h1>
                            <div className="actions">
                                <Button
                                    color="primary"
                                    onClick={this.handleNewProveedor}
                                >
                                    Nuevo proveedor de firmas
                                </Button>
                            </div>
                        </div>
                        <Navbar
                            menuActual={window.location.hash}
                            seccionActual={'administracion'}
                        />
                        <div className="contentPage card" style={{ padding: '0px' }}>
                            <MaterialTable
                                title="Proveedores"
                                tableRef={this.tableRef}
                                columns={[
                                    { title: "Cliente ID", field: "clientId" },
                                    { title: "Descripcion", field: "descripcion" },
                                    { title: "Estado", field: "activo", type: "boolean" },
                                ]}
                                data={(query) =>
                                    new Promise((resolve, reject) => {
                                        this.props.getProveedoresFirmas(query.page, query.pageSize, query.search)
                                            .then(() => {
                                                resolve({
                                                    data: this.props.proveedorData.proveedores,
                                                    page: this.props.proveedorData.page,
                                                    totalCount: this.props.proveedorData.totalCount,
                                                });
                                            });
                                    })
                                }
                                actions={[
                                    {
                                        icon: () => <img src={editIcon} />,
                                        tooltip: 'Editar datos',
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                dialogTitle: "Editar datos de proveedor",
                                                selectedProveedor: rowData,
                                                dialogType: "edit",
                                                submitText: "Editar",
                                                disabledInput: false
                                            }, () => {
                                                this.handleDialog(true);
                                            });
                                        }
                                    },
                                    {
                                        icon: () => <img src={visibility} />,
                                        tooltip: 'Ver',
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                dialogTitle: "Detalles del proveedor",
                                                selectedProveedor: rowData,
                                                dialogType: "edit",
                                                submitText: "Editar",
                                                disabledInput: false
                                            }, () => {
                                                this.handleDialogVerDatos(true);
                                            });
                                        }
                                    },
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                    sorting: false,
                                    paginationType: "stepped",
                                    search: true,
                                    showTextRowsSelected: false,
                                    showTitle: false,
                                    headerStyle: {
                                        background: "#edf2f9",
                                        fontFamily: "Open Sans", fontWeight: "bold",
                                    }
                                }}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: "No hay resultados que mostrar",
                                    },
                                    header: {
                                        actions: "Acciones",
                                    },
                                    pagination: {
                                        labelDisplayedRows: "{from}-{to} de {count}",
                                        nextTooltip: "Página Siguiente",
                                        previousTooltip: "Página Anterior",
                                        firstTooltip: "Primera Página",
                                        lastTooltip: "Última Página",
                                        labelRowsSelect: "Filas",
                                    },
                                    toolbar: {
                                        searchPlaceholder: "Buscar",
                                        searchTooltip: "Buscar",
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => ({
    getProveedoresFirmas: (page, size, search) => dispatch(getProveedoresFirmas(page, size, search)),
});

const mapStateToProps = (state) => ({
    proveedorData: state.proveedoresData
});

export default connect(mapStateToProps, mapDispatchToProps)(ProveedoresFirma);