import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getRecibosPorUsuario, publicarLote } from "./RecibosService";
import "../../styles/Empresa.css";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import Navbar from '../home/Navbar';
import { Link, useLocation} from "react-router-dom";
import visibility from '../../styles/icons/visibility.svg';

class RecibosPendientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: "Ingrese Pin de Firma",
      dialogType: "add",
      submitText: "Guardar",
      disabledInput: false,
      selectedRecibo: {
        recibo: "",
        fechaCarga: "",
        empleado: "",
        estado: "",
        estadoMTESS: false
      },
      dialogIsOpen: false,
      dialogIsOpenVerDatos: false,
      dialogPinOpen: false,
      open: false,
      mostrarMensajeFlash: false,
      mensajeFlash: "",
      severityFlash: "",
    };
    localStorage.setItem("urlRaiz", window.location.href);
    this.tableRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }
  handleDialog = (value) => {
    this.setState({
      dialogIsOpen: value,
    });
  };

  handleDialogPin = (value, mensaje = "", severity = "") => {
    this.setState({
      dialogPinOpen: value,
      mostrarMensajeFlash: true,
      mensajeFlash: mensaje,
      severityFlash: severity
    });
  };
  handleClose = (value) => {
    this.setState({
      open: value
    });
  };

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };

  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
            <div className="sectionHeader">
              <h1 className="pageTitle"> Recibos Pendientes</h1>
            </div>
            <Navbar
              menuActual={window.location.hash}
              seccionActual={'bandeja'}
            />
            <MensajeFlash
              mensaje={this.state.mensajeFlash}
              severity={this.state.severityFlash}
              open={this.state.mostrarMensajeFlash}
              handleClose={this.handleCloseMensajeFlash} />
            <div className="contentPage card" style={{ padding: '0px' }}>
              <MaterialTable
                title="Recibos Pendientes"
                style={{ borderRadius: ".5rem" }}
                tableRef={this.tableRef}
                columns={[
                  { title: "Lote-Recibo", field: "loteRecibo" },
                  { title: "Fecha", field: "fecha" },
                  { title: "Empresa", field: "empresa" },
                  { title: "Concepto(del Lote de liquidación)", field: "concepto" },
                  { title: "Estado", field: "estado" }
                ]}
                data={(query) =>

                  new Promise((resolve, reject) => {
                    this.props.getRecibosPorUsuario(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.recibosData.recibos,
                        page: this.props.recibosData.page,
                        totalCount: this.props.recibosData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  rowData => (
                    {
                      icon: () => <Link to={{pathname: localStorage.getItem('urlRaiz').includes('bandeja') 
                        ? '/bandeja/recibo/view/Pendientes/'+rowData.idLote+'/'+rowData.idRecibo 
                        : '/recibo/view/Pendientes/'+rowData.idLote+'/'+rowData.idRecibo }}
                        style={{ textDecoration: 'none' }}><img src={visibility}/></Link>,
                      tooltip: 'Ver',
                      onClick: (rowData) => {
                      }
                    }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  toolbar: true,
                  detailPanelColumnAlignment: "right",
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold"
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getRecibosPorUsuario: (page, size, search) => dispatch(getRecibosPorUsuario(page, size, search)),
});

const mapStateToProps = (state) => ({
  recibosData: state.recibosData,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecibosPendientes);
