import React, { Component } from 'react';
import { Form, Modal, Spinner } from 'react-bootstrap';
import "../../styles/Login.css";
import logo from "../../images/efirmalogo.gif";
import imgLogin from "../../images/loginImage.png";
import { inicioSesionEfimra } from "./ProveedorFirmaService";


export default class LoginPsa extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLogin: false
    };
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };

  handleAuthentication = (event) => {
    event.preventDefault();
    const data = {
      j_username: this.state.username,
      j_password: this.state.password,
    };
    this.setState({isLogin:true})
    inicioSesionEfimra(this.props.data.id, data)
      .then(resp => {
        this.props.handleClose(true, resp)
      })
  };

  decodeBase64(base64data) {
    let base64ToString = Buffer.from(base64data, "base64").toString()
    return base64ToString;
  }


  render() {
    return (
      <>
        <Modal.Header closeButton />
        <Modal.Body>
          <Form className="loginForm" onSubmit={this.handleAuthentication} style={{ textAlign: 'center' }}>
            <div style={{ marginTop: "5px" }}>
              <img src={"data:image/jpeg;base64," + this.decodeBase64(this.props.data.logo)} className="img-fluid img-brand" />
              <h3> Iniciar Sesión </h3>
            </div>

            <div className="form-group md-input" style={{ marginTop: "20px" }}>
              <Form.Group>
                <input
                  id="username"
                  label="Correo electronico"
                  name="username"
                  autoComplete="username"
                  placeholder="Correo electronico"
                  onChange={this.handleInput}
                  value={this.state.username}
                  className="form-control md-form-control"
                  required
                />
              </Form.Group>
            </div>
            <div className="form-group md-input">
              <Form.Group>
                <input
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  placeholder="Contraseña"
                  onChange={this.handleInput}
                  value={this.state.password}
                  className="form-control md-form-control"
                  required
                />
              </Form.Group>
            </div>
            <div className="form-group md-input" style={{ marginTop: "20px" }}>
              <button 
                type="submit"
                class="btn btn-lg btn-primary"
                disabled= {this.state.isLogin}
              >
                {this.state.isLogin ? 'Iniciando...' : 'Iniciar'}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </>
    )
  }
}
