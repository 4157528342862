import Cookies from 'universal-cookie';
import imageSign from '../images/default-signature-background-2.png'
import pdfReference from '../pdf-referencia.pdf'
import imageColaboradorApariencia from '../images/firmaColaborador.png'
import axios from "axios";
import { logout } from "../components/login/SesionService";

const cookies = new Cookies();

//export const URL = "https://equifax.mirecibo.com.py/mirecibo/"
export const URL = window.location.origin+"/mirecibo/";
//export const URL = "http://localhost:8090"+"/mirecibo/";

export const iscertLoguin = false;
export const aparienciaFirma = true;
export const aparienciaFirmaUsuario = true;

export const PDF = 2;
export const XML = 1;
export const loteNuevo = "01";
export const loteFirmado = "02";
export const lotePublicar = "03";
export const lotePublicado = "04";
export const loteCerrado = "05";

export const reciboPendiente = "01";
export const reciboConfirmados = "02";
export const reciboFirmados = "03";
export const reciboRechazados = "04"

export const reportePendientes = "01"
export const resporteConfirmado = "02"
export const reporteFirmado = "03"
export const reporteRechazado = "04"


/*--------Roles---------------*/
export const administradorEmpresas = "01";
export const empleado = "02";
export const auditor = "03";
export const gestorEmpresa = "04";
export const firmaRRHH = "06";
export const firmaRL = "05";
export const reportes = "07"
/*--------FinRoles---------------*/


export const timeoutMilisegundos = 80000;

export const firmaColaboradorRechazar = "01"
export const firmaColaborador = "02"
export const aceptar = "03"
export const aceptarRechazar = "04"

export const logoutURL = URL + 'usuario/keycloak/logout'
export const refreshTokenURL = URL + 'usuario/keycloak/refreshToken'

export const idEmpresa = () => cookies.get("empresa");
export const username = () => cookies.get("username");
export const logueado = () => (cookies.get('logged') == "true");
export const admin = () => (cookies.get('admin') == "true");


/*------Mostrar Contraseña------*/
export const mostrarModificarContrasenha = true;


export const getAuthorizationHeader = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  }
}

export const getAuthorizationHeaderUpload = () => {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  }
}



export const getEstadoURL = () => {
  if (window.location.href.indexOf("nuevos") > -1) {
    return loteNuevo;
  }
  if (window.location.href.indexOf("firmados") > -1) {
    return loteFirmado;
  }
  if (window.location.href.indexOf("publicar") > -1) {
    return lotePublicar;
  }
  if (window.location.href.indexOf("publicados") > -1) {
    return lotePublicado;
  }
  if (window.location.href.indexOf("cerrados") > -1) {
    return loteCerrado;
  }
}

export const getEstadoURLReporte = () => {
  if (window.location.href.indexOf("pendientes") > -1) {
    return reportePendientes;
  }
  if (window.location.href.indexOf("aceptados") > -1) {
    return resporteConfirmado;
  }
  if (window.location.href.indexOf("firmados") > -1) {
    return reporteFirmado;
  }
  if (window.location.href.indexOf("rechazados") > -1) {
    return reporteRechazado;
  }
}
export const getEstadoReciboURL = () => {
  if (window.location.href.indexOf("pendientes") > -1) {
    return reciboPendiente;
  }
  if (window.location.href.indexOf("confirmados") > -1) {
    return reciboConfirmados;
  }
  if (window.location.href.indexOf("firmados") > -1) {
    return reciboFirmados;
  }
  if (window.location.href.indexOf("rechazados") > -1) {
    return reciboRechazados;
  }
}

export const verificarRol = (rolData, rolAsignado) => {
  var mostarVista = false;

  if (rolAsignado == undefined)
    return false

  if (rolAsignado == "admin")
    return true;

  if (rolData != null) {
    rolData.roles.map(function (rol) {
      if (rolAsignado.includes(rol.codigo)) {
        mostarVista = true;
      }
    })
  }
  return mostarVista;
}
export const urlImageSign = imageSign
export const pdfReferencia = pdfReference
export const firmaColaboradorApariencia = imageColaboradorApariencia

export const breadcrumBandeja = [
  {
    nombre: 'Recibos Pendientes',
    url: '/bandeja/pendientes',
    rol: [empleado]
  },
  {
    nombre: 'Recibos Confirmados',
    url: '/bandeja/confirmados',
    rol: [empleado]
  },
  {
    nombre: 'Recibos Firmados',
    url: '/bandeja/firmados',
    rol: [empleado]
  },
  {
    nombre: 'Recibos Rechazados',
    url: '/bandeja/rechazados',
    rol: [empleado]
  }
]

export const breadcrumLotes = [
  {
    nombre: 'Nuevos',
    url: '/lote/nuevos',
    rol: [gestorEmpresa, administradorEmpresas, firmaRRHH]
  },
  {
    nombre: 'Firmados',
    url: '/lote/firmados',
    rol: [gestorEmpresa, administradorEmpresas, firmaRL]
  },
  {
    nombre: 'Para publicar',
    url: '/lote/publicar',
    rol: [gestorEmpresa, administradorEmpresas]
  },
  {
    nombre: 'Publicados',
    url: '/lote/publicados',
    rol: [gestorEmpresa, administradorEmpresas]
  },
  {
    nombre: 'Cerrados',
    url: '/lote/cerrados',
    rol: [gestorEmpresa, administradorEmpresas]
  }

]

export const breadcrumConfig = [
  {
    nombre: 'Tipos de Documentos',
    url: '/configuracion/tipo-documento',
    rol: [gestorEmpresa, firmaRRHH]
  },
  {
    nombre: 'Aplicaciones Externas',
    url: '/configuracion/aplicaciones-externas',
    rol: [gestorEmpresa, firmaRRHH]
  },
  {
    nombre: 'Numero MTESS Patronal',
    url: '/configuracion/numero-patronal',
    rol: [gestorEmpresa, firmaRRHH]
  },
  {
    nombre: 'Plantillas de Correo',
    url: '/configuracion/plantilla-correo',
    rol: [gestorEmpresa]
  }
]

export const breadcrumAdmin = () => {
  var items
  !admin() ?
    items = [{ nombre: 'Usuarios', url:  '/administracion/usuarios', rol: [administradorEmpresas, gestorEmpresa, firmaRRHH] },
    { nombre: "Grupos de usuarios", url: '/administracion/grupos', rol: [administradorEmpresas, gestorEmpresa, firmaRRHH] }]
    :
    items = [{ nombre: 'Usuarios', url: '/admin/usuarios', rol: ['admin'] }, 
    { nombre: 'Empresas', url: '/admin/empresas', rol: ['admin'] }, 
    { nombre: 'Proveedores de firma', url: '/admin/proveedores-firma', rol: ['admin'] },
    { nombre: 'Aplicaciones externas', url: '/admin/configuracion/aplicaciones-externas', rol: ['admin'] }
  ]
  return items;
}

export const breadcrumReportes = [
  {
    nombre: 'Todos',
    url: '/reporte/recibo-colaborador/todos',
    rol: [gestorEmpresa,reportes]
  },
  {
    nombre: 'Recibos Pendientes',
    url: '/reporte/recibo-colaborador/pendientes',
    rol: [gestorEmpresa,reportes]
  },
  {
    nombre: 'Recibos Firmados por Colaborador',
    url: '/reporte/recibo-colaborador/firmados',
    rol: [gestorEmpresa,reportes]
  },
  {
    nombre: 'Recibos Rechazados',
    url: '/reporte/recibo-colaborador/rechazados',
    rol: [gestorEmpresa,reportes]
  },
  {
    nombre: 'Recibos Confirmados',
    url: '/reporte/recibo-colaborador/aceptados',
    rol: [gestorEmpresa,reportes]
  }
]

export const iscert = () => {
  console.log("entro iscert")
  axios.get(URL + "auth/1").then((res) => {
    window.location.replace(res.data);
  }, error => {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  });
}

export const EFIRMA = "01";
export const IScert = "02";

export const psa = [
  {
    codigo: "01",
    nombre: "EFIRMA"
  },
  {
    codigo: "02",
    nombre: "IScert"
  }
]