import React from "react";
import { Redirect } from "react-router-dom"
import { connect } from "react-redux";
import { enviarComentarioFirma, getRecibos, publicarLote, getReciboComment, aceptarEmpleado,getRecibo } from "./RecibosService";
import "../../styles/Empresa.css";
import Pin from "../recibos/PinEmpleado"
import { URL, aceptar, aceptarRechazar, firmaColaborador, firmaColaboradorRechazar } from '../../constans/constants';
import { rechazarLote } from '../recibos/RecibosService';
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'
import PinEmpleado from "../recibos/PinEmpleado";
import axios from "axios";
import { logout } from "../login/SesionService";
import SeleccionPca from "../pca/SeleccionPca";
import LoginPsa from "../pca/LoginPsa";
import checkCircle from '../../styles/icons/check-circle.svg'
import xCircle from '../../styles/icons/x-circle.svg'
import arrowBack from '../../styles/icons/arrow_back.svg'

class ReciboEmpleado extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: "Firmar recibo",
      dialogType: "add",
      submitText: "Guardar",
      disabledInput: false,
      tipoFirma: "",
      dialogIsOpen: false,
      dialogIsOpenVerDatos: false,
      dialogPinOpen: localStorage.getItem("tokenIscert") != null ? true : false,
      open: false,
      openComentarioFirma: false,
      comentarioFirma: "",
      openMsgAceptarFirma: false,

      openMsgVentana: false,
      msgVentana: "",
      error: false,


      openPantallaCarga: false,
      msgPantallaCarga: "",

      openRechazar: false,
      mensajeRechazar: "",
      redirect: false,
      exito: false,
      isRechazado: false,
      validated: false,
      openSeleccionPca: false,
      openLoginPSA: false,

      formatoDocumento: "",
      loteRecibo:"",
      fecha:"",
      concepto:"",
      enableComentarioFirma: false,
      codFirmaColaborador:""

    };
    this.tableRef = React.createRef();
    const psaActivos = null;
    let isRedirect = true;
    let pantallaAnterior = null;
    this.setPantallaAnterior(); 
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }

  }

  componentDidMount() {
    getRecibo(this.props.match.params.idLote,this.props.match.params.idRecibo).then((res) =>{
      this.psaActivos = res.datos.psaActivos
      this.setState({formatoDocumento:res.datos.formatoDocumento,
      loteRecibo: res.datos.loteRecibo,
      fecha:res.datos.fecha,
      concepto:res.datos.concepto,
      codFirmaColaborador:res.datos.codFirmaColaborador,
      tipoFirma:res.datos.codFirmaColaborador,
      enableComentarioFirma:res.datos.enableComentarioFirma})
  })
  /*
    if (this.props.match.params.estado == "Firmados" || this.props.match.params.estado == "Confirmados") {
      getReciboComment(this.props.match.params.idLote, this.props.match.params.idRecibo).then(res => {
        if (!res.error && res.mensaje != "") {
          this.setState({ comentario: res.mensaje })
        }
      })
    }
  */
  }

  setPantallaAnterior = () => {
      this.pantallaAnterior = (localStorage.getItem("urlRaiz")).substring(localStorage.getItem("urlRaiz").indexOf("#"));
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };

  handleDialog = (value) => {
    this.setState({
      dialogIsOpen: value,
    });
  };

  
  handleClosePsa = () =>{
    this.setState({openSeleccionPca: false})
  }

/*  
  handleSeleccionPca = (value, data) => {
    this.datosPsa = data;
    this.setState({
      openSeleccionPca: value,
      openLoginPSA: data != null ? true : false
    });
  }
*/

  handleMsgVentana = (value) => {
    this.setState({ msgVentana: "", openMsgVentana: value, redirect: this.isRedirect })
  }

  handleDialogPin = (open, data) => {
    if (localStorage.getItem("tokenIscert") != null) {
      this.setState({ dialogPinOpen: open })
      if (data != null) {
        this.isRedirect = true;
        this.setState({
          msgVentana: data.mensaje,
          openMsgVentana: true,
          exito: !data.error,
          error: data.error
        })
      }
      localStorage.removeItem("tokenIscert")
      localStorage.removeItem("codigoClienteFirma")
      localStorage.removeItem("proveedorFirma")
    } else {
      this.setState({ openSeleccionPca: true })
    }
  }

  handleLoginPSA = (value, data) => {
    if (data != null) {
      this.isRedirect = false;
      if (data.error) {
        this.setState({
          openLoginPSA: false,
          openMsgVentana: true,
          error: true,
          msgVentana: data.mensaje,
        })
      } else {
        localStorage.setItem("tokenIscert", data.datos.access_token)
        localStorage.setItem("codigoClienteFirma", this.datosPsa.id)
        localStorage.setItem("proveedorFirma", this.datosPsa.codigoCliente)
        this.setState({
          openLoginPSA: false,
          openMsgVentana: true,
          error: false,
          msgVentana: "Autenticado con exito",
        })
      }
    } else {
      this.setState({
        openLoginPSA: value
      })
    }
  }

  /*
  handleFirmaFinalizada = (value) => {
    if (this.props.match.params.estado == "Pendientes") {
      this.setState({
        openFirmaFinalizada: value
      })
    }
  }

   
  iscert = () => {
    axios.get(URL + "auth/1").then((res) => {
      window.location.replace(res.data);
    }, error => {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    });
  }
  */

  enviarComentario = (event) => {
    event.preventDefault();
    this.setState({ validated: true, openComentarioFirma: false,  openPantallaCarga: true,  msgPantallaCarga: "Enviando comentario"})
    var data = {
      batchId: this.props.match.params.idLote,
      payrollId: this.props.match.params.idRecibo,
      appearance: "",
      redirectUri: "",
      employeeComment: this.state.comentarioFirma
    }

    enviarComentarioFirma(data).then(res => {
      this.setState({ redirect: true })
    })
  }

  handleOpenComentarioFirma = (value) => {
    this.setState({
      openMsgVentana: false,
      openComentarioFirma: value
    })
    if (!value)
      this.setState({ redirect: true })
  }

  handleClose = (value) => {
    this.setState({
      open: value
    });
  };
  rechazarLote = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true })
    } else {
      rechazarLote(this.props.match.params.idLote, this.props.match.params.idRecibo, this.state.mensajeRechazar).then((response => {
        this.isRedirect = true
        this.setState({
          openRechazar: false,
          openMsgVentana: true,
          msgVentana: response.mensaje,
          isRechazado: true,
        })
      }))
    }
  }

  aceptarRecibo = () => {
    this.setState({ openPantallaCarga: true, openMsgAceptarFirma: false })
    aceptarEmpleado(this.props.match.params.idLote, this.props.match.params.idRecibo)
      .then((response) => {
        this.isRedirect = true
        this.setState({
          openPantallaCarga: false,
          openMsgVentana: true,
          msgVentana: response.data.mensaje,
          exito: !response.data.error
        })
      })
  }

  descargarRecibo = () => {
    window.location.href = URL + "recibo/lote/" + this.props.match.params.idLote + "/recibo/" + this.props.match.params.idRecibo + "/descargar";
  }

  visualizarBotones = () => {
    if (this.props.match.params.estado == "Firmados") {
      return null
    }
    if (this.props.match.params.estado == "Confirmados") {
      return null
    }
    if (this.props.match.params.estado == "Pendientes" && [firmaColaborador, firmaColaboradorRechazar].includes(this.state.tipoFirma)) {
      return (<Button variant="primary" onClick={this.handleDialogPin}>
        Firmar
      </Button>
      )
    }
    if (this.props.match.params.estado == "Rechazados") {
      return null
    }
  }
  botonRechazar = () => {
    if (this.props.match.params.estado == "Pendientes" && [firmaColaboradorRechazar, aceptarRechazar].includes(this.state.tipoFirma)) {
      return (<Button variant="danger" onClick={this.handleClickOpen}>
        Rechazar
      </Button>
      )
    }
  }
  botonAceptarSinFirma = () => {
    if (this.props.match.params.estado == "Pendientes" && [aceptar, aceptarRechazar].includes(this.state.tipoFirma)) {
      return (<Button variant="primary" onClick={() => this.handleDialogConfirmarSinFirma(true)}>
        Aceptar
      </Button>
      )
    }
  }

  handleDialogConfirmarSinFirma = (value) => {
    this.setState({
      openMsgAceptarFirma: value,
    })
  }

  publicarLote = () => {
    publicarLote(this.props.match.params.idLote).then((response) => {
      this.setState({
        openMsgVentana: true,
        msgVentana: response.mensaje
      })
      this.handleClose(false);
    });
    this.tableRef.current.onQueryChange();
  }
  urlPDF = () => {
    var request = URL + 'recibo/lote/' + this.props.match.params.idLote + '/recibo/' + this.props.match.params.idRecibo;
    return request
  }

  handleClickOpen = () => {
    this.setState({ openRechazar: true });
  };

  handleCloseRechazar = () => {
    this.setState({
      openRechazar: false,
    });
  };

  render() {
    if (this.state.redirect)
      return (<Redirect to={"/bandeja/pendientes"} />)
    else
      return (
        <div>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <div className="dashboardContent">
            <div className="container-fluid">
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.dialogPinOpen}
                onHide={this.handleDialogPin}
                backdrop="static"
                keyboard="false"
              >
                <PinEmpleado
                  estado={this.props.match.params.estado}
                  idLote={this.props.match.params.idLote}
                  idRecibo={this.props.match.params.idRecibo}
                  loteRecibo={this.state.loteRecibo}
                  fecha={this.state.fecha}
                  concepto={this.state.concepto}
                  type={this.state.dialogType}
                  formatoDocumento={this.state.formatoDocumento}
                  submitText={this.state.submitText}
                  disabledInput={this.state.disabledInput}
                  handleClose={this.handleDialogPin}
                  dialogTitle={this.state.dialogTitle}
                />

              </Modal>
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.openSeleccionPca}
                onHide={this.handleClosePsa}
                backdrop="static"
                keyboard="false"
              >
                <SeleccionPca
                  handleClose={this.handleClosePsa}
                  url={window.location.href}
                  listaPsa={this.psaActivos}
                />

              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.openMsgVentana}
                backdrop="static"
                keyboard="false"
              >
                <Modal.Body>
                  {
                    <div>
                      <Row className="justify-content-center">
                        <Col className="text-center text-md-right">
                          {this.state.error
                            ? <img src={xCircle} style={{margin: '40px'}}></img>
                            : <img src={checkCircle} style={{margin: '40px'}}></img>
                          }
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col className="text-center text-md-right">
                          <p><strong>{this.state.msgVentana}</strong></p>
                        </Col>
                      </Row>
                    </div>
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick=
                    {

                      this.state.exito && this.state.enableComentarioFirma ?
                        () => this.handleOpenComentarioFirma(true)
                        :
                        () => this.handleMsgVentana(false)
                    }>
                    Aceptar
                  </Button>
                </Modal.Footer>

              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.openMsgAceptarFirma}
                backdrop="static"
                keyboard="false"
              >
                <Modal.Header style={{ color: "#0c56d0" }}>Aceptar recibo</Modal.Header>
                <Modal.Body>
                  <p className="form-date">
                    {" "}
                    El documento se confirmará como recibido
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => this.handleDialogConfirmarSinFirma(false)} variant="light">
                    Cancelar
                  </Button>
                  <Button variant="primary" onClick={this.aceptarRecibo}>
                    Aceptar recibo
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.openComentarioFirma}
                onHide={this.handleOpenComentarioFirma}
                backdrop="static"
                keyboard="false"
              >
                <Modal.Header>
                  <Modal.Title style={{ color: "#0c56d0" }}>Comentario de firma</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={this.state.validated} onSubmit={this.enviarComentario}>
                  <Modal.Body>
                    <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                      <Form.Control
                        as="textarea"
                        autofocus
                        className="form-control md-form-control"
                        name="comentarioFirma"
                        placeholder=" "
                        rows="5"
                        onChange={this.handleInput}
                        value={this.state.comentarioFirma}
                      />
                      <Form.Label class="md-label"> Agregue aqui un comentario opcional </Form.Label>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => this.handleOpenComentarioFirma(false)} variant="light">
                      Cancelar
                    </Button>
                    <Button color="primary" type="submit">
                      Enviar comentario
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.openRechazar}
                onHide={this.handleCloseRechazar}
                backdrop="static"
                keyboard="false"
              >
                <Modal.Header>
                  <Modal.Title style={{ color: "#0c56d0" }}>Rechazar recibo</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={this.state.validated} onSubmit={this.rechazarLote}>
                  <Modal.Body>
                    <p className="form-date">
                      {" "}
                      ¿Desea rechazar el recibo? Esta accion no se podra deshacer
                    </p>
                    <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                      <textarea
                        className="form-control md-form-control"
                        name="mensajeRechazar"
                        placeholder=" "
                        rows="5"
                        onChange={this.handleInput}
                        value={this.state.mensajeRechazar}
                        required />
                      <Form.Label class="md-label"> Motivo del rechazo: </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <small>Campo requerido</small>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.handleCloseRechazar} variant="contained" color="primary">
                      Cancelar
                    </Button>
                    <Button color="primary" type="submit">
                      Rechazar recibo
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>

              <Modal
                aria-labelledby="contained-modal-title-vcenter"
                show={this.state.openLoginPSA}
                onHide={() => this.handleLoginPSA(false, null)}
                backdrop="static"
                keyboard="false"
              >
                <LoginPsa
                  handleClose={this.handleLoginPSA}
                  data={this.datosPsa}
                />
              </Modal>
              <div className="sectionHeader" style={{ paddingBottom: '20px' }}>
                <h1 className="pageTitle"> Recibo {this.state.loteRecibo} </h1>
                <div className="actions">

                  {this.visualizarBotones()}

                  {this.botonAceptarSinFirma()}


                  {this.botonRechazar()}


                  <Button
                    variant="success"
                    onClick={this.descargarRecibo}
                  >
                    Descargar Recibo
                  </Button>

                </div>
              </div>

              <PantallaCarga open={this.state.openPantallaCarga} mensaje={this.state.msgPantallaCarga} />

              <div className="contentPage card">
              
              <a onClick={() => window.location.href = this.pantallaAnterior} className="backPage" type='button'>
              <img src={arrowBack}></img>
                  volver a la página anterior
              </a>
{/*
                <a onClick={() => this.props.history.goBack()} className="backPage" type='button'>
                  <span className="material-icons-outlined"> arrow_back </span>
                  volver a la página anterior
                </a>
*/}
                <div id="container-file">
                  <div className="iframeVisor">
                    <iframe
                      id="pdf-js-viewer"
                      src={'../../../mirecibo/lib/web/viewer.html?file=' + this.urlPDF()}
                      // src={'../../../mirecibo/lib/web/viewer.html?file=' + this.urlPDF()}
                      title="webviewer"
                      frameborder="0"
                      className="pdfIframe"
                      height={"100%"}
                      width={"100%"}

                    />
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div >
      );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getRecibos: (page, size, search, idLote) => dispatch(getRecibos(page, size, search, idLote)),
});

const mapStateToProps = (state) => ({
  recibosData: state.recibosData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReciboEmpleado);
