import React from "react";
import "../../styles/Empresa.css";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { actualizaPassword } from "./UsuarioService";
import { Button, Form } from 'react-bootstrap';

class ActualizarPasswrd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            password: '',
            repeatedPassword: '',
            mostrarMensaje: false,
            mensaje: '',
            openPantallaCarga: false,
            severity: '',
            validated: false
        };
    }

    handleInput = (e) => {
        var name = e.target.name;
        var val = e.target.value;
        this.setState({
            [name]: val,
        });
    };

    handleCloseMensajeFlash = () => {
        this.setState({
            mostrarMensaje: false,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({validated: true})
        } else {
            if (this.state.password == this.state.repeatedPassword) {
                this.setState({ openPantallaCarga: true })
                actualizaPassword(this.state.password).then(res => {
                    this.setState({
                        password: '',
                        repeatedPassword: '',
                        openPantallaCarga: false,
                        mensaje: res.status == 200 ? 'Contraseña actualizada correctamente' : res.data,
                        severity: res.status == 200 ? 'success' : 'danger',
                        mostrarMensaje: true,
                        validated: false
                    })
                })
            } else {
                this.setState({
                    severity: 'danger',
                    mensaje: "Los campos de contraseña no coinciden",
                    mostrarMensaje: 'true',
                    validated:false
                })
            }
        }
    };

    render() {
        return (
            <div className="dashboardContent">
                <div className="container-fluid">
                    <MensajeFlash
                        mensaje={this.state.mensaje}
                        open={this.state.mostrarMensaje}
                        severity={this.state.severity}
                        handleClose={this.handleCloseMensajeFlash} />

                    <div className="sectionHeader">
                        <h1 className="pageTitle"> Actualizar contraseña </h1>
                    </div>
                    <div className="contentPage card"
                        style={{ padding: '0px', marginTop: '30px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg">
                                </div>
                                <div className="col-lg text-center" style={{ marginTop: '40px', marginBottom: '40px' }}>
                                    <Form ref="form" noValidate validated={this.state.validated}
                                        onSubmit={this.handleSubmit}>
                                        <Form.Group className="form-group md-input">
                                            <div className="form-group md-input" style={{ marginBottom: '30px' }}>
                                                <input
                                                    className="form-control md-form-control"
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder=" "
                                                    onChange={this.handleInput}
                                                    value={this.state.password}
                                                    required
                                                />
                                                <Form.Label class="md-label"> Contraseña nueva </Form.Label>
                                                <Form.Control.Feedback type="invalid">
                                                    <small>Campo requerido</small>
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="form-group md-input" >
                                            <div className="form-group md-input" style={{ marginBottom: '30px' }}>
                                                <input
                                                    className="form-control md-form-control"
                                                    type="password"
                                                    name="repeatedPassword"
                                                    id="repeatedPassword"
                                                    placeholder=" "
                                                    onChange={this.handleInput}
                                                    value={this.state.repeatedPassword}
                                                    required
                                                />
                                                <Form.Label class="md-label"> Repita la contraseña nueva </Form.Label>
                                                <Form.Control.Feedback type="invalid">
                                                    <small>Campo requerido</small>
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="form-group md-input">
                                            <Button autoFocus color="primary" type="submit">
                                                Actualizar contraseña
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="col-lg">
                                </div>
                                <PantallaCarga open={this.state.openPantallaCarga} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ActualizarPasswrd;
