import {firmarRRHH,firmarRL,firmaEmpleado} from '../recibos/RecibosService';
import { fabric } from "fabric";
import $ from "jquery";
import {EFIRMA} from "../../constans/constants";

let fabricCanvas = null;
let groups = [];
const base64 = null;
let _ = require("underscore");

export const inicializar = (canvas) => {
  var bg = canvas.toDataURL("image/png");
  fabricCanvas = new fabric.Canvas(canvas.id, {
    selection: false,
  });
  fabricCanvas.setHeight(canvas.clientHeight);
  fabricCanvas.setWidth(canvas.clientWidth);
  fabricCanvas.on("object:modified", boundSignatureRect);
  fabricCanvas.on("mouse:up", onMouseUp);
  fabricCanvas.setBackgroundImage(
    bg,
    fabricCanvas.renderAll.bind(fabricCanvas)
  );
};
const boundSignatureRect = (event) => {
  var group = event.target;
  var originalState = group.state;
  if (isOutBound(group)) {
    group.top = 100;
    group.left = 350;
    group.scaleX = 0.48;
    group.scaleY = 0.48;
    group.setCoords();
  }
};

export const reload = (config) => {
  var group = _.find(groups, function (group) {
    return group.id === config.id;
  });

  if (!group) {
    console.error(
      "Signature with id " +
        config.id +
        " not found, use signatureCanvas#create for create one."
    );
    return;
  }

  update(config, group);
};

export const joinDetails = (title, details) => {
  var text = _.isEmpty(title) ? "" : title;
  _.forEach(details, function (detail) {
    var value = detail.value ? detail.value : detail.id;
    text = text + value + "\n";
  });

  return text;
};
export const stringify=(typeDetails,signature, stampFile,base64)=> {
  var base = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
  signature.detailType = typeDetails
  if(!signature?.appearance){
    signature.appearance = get(signature.id)
  }
  if (signature?.appearance && stampFile) {
    signature.stamp = {content: base, type: stampFile.type};
    return JSON.stringify(signature);
  } else {
      return JSON.stringify(signature);
  }
}
export const stringifyForIscert = async(signature,base64)=>{
  var coords = signature.appearance.coords;
  var data = {
          location: {
              page:  signature.page.toString(),
              frame:{
                  lowerLeft:{
                      x: coords.bl.x,
                      y: signature.appearance.canvas.height - coords.bl.y,
                  },
                  upperRight:{
                      x: signature.appearance.width,
                      y: signature.appearance.height
                  }
              }
          },
          image:{
              value: base64
          }
      };
  const json = await JSON.stringify(data)
  return json;
}

export const loadSignature = (signatureConf,currentUser,currentEmpresa, isLote) => {
  if (signatureConf) {
      var signature = parse(signatureConf);
      _.forEach(signature.details, function (detail) {
          if(isLote){
              switch(detail.id){
                  case '${nombre_empresa}':
                      detail.value = currentEmpresa.nombre;
                      break;
                  case '${ruc}':
                      detail.value = currentEmpresa.ruc;
                      break;
/*                   case '${ubicacion}':
                      detail.value = sessionService.configuration.region === 'PY' ? 'Paraguay' : 'Uruguay';
                      break; */

              }
          }else{
              switch(detail.id){
                  case '${nombre_apellidos}':
                      detail.value = currentUser.fullName;
                      break;
                  case '${ci}':
                      detail.value = currentUser.ci;
                      break;
/*                   case '${lugar}':
                      detail.value = sessionService.configuration.region === 'PY' ? 'Paraguay' : 'Uruguay';
                      break; */

              }
          }
      });
      return signature;
  }
  return undefined;
}

export const parse =(json)=> {
  var item = JSON.parse(json);
  stampToDataUrl(item);
  return item;
}
function stampToDataUrl(item) {
  if (item.stamp) {
      item.stampUrl = URL.createObjectURL(base64toBlob(item.stamp.content, item.stamp.type));
  } /* else {
      item.stampUrl = STAMP_URL;
  } */
}

function base64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);

      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
}


const isOutBound = (object) => {
  if (!fabricCanvas || !object) return true;

  var rect = object.getBoundingRect();
  return (
    rect.left < 0 ||
    rect.top < 0 ||
    rect.left + rect.width > fabricCanvas.getWidth() ||
    rect.top + rect.height > fabricCanvas.getHeight()
  );
};

const onMouseUp = () => {
  if (getActive()) {
    //$rootScope.$emit('group:selected');
  }
};

export const createContainer = () => {
  var container = document.createElement("div");
  container.id = "printableContainer";
  $(container).addClass("container-canvas");
  return container;
};

export const deletePage = (pdfPage) => {
  $("#printableContainer").remove();

  if (pdfPage != null) {
    pdfPage.cleanup();
  }
};

export const create = (config) => {
  var group = _.find(groups, function (group) {
    return group.id === config.id;
  });
  if (group) {
    remove(group.id);
  }
  add(config);
};

export const createGostCanvasAndGetAppareance = async (signature,json,tipo,handleclose, handleFirmasPDF)=>{
  init('previewContainer', {height: signature.appearance.width + 60, width: signature.appearance.canvas.width});
  //$('.signature-preview-modal .modal-dialog').width(signature.appearance.canvas.width + 50);
  var config = {
      id: signature.id,
      top: 0,
      hasControls: false,
      borderColor: signature.appearance.borderColor,
      width: signature.appearance.width,
      left: 0,
      text: joinDetails(signature.freeText, signature.details),
      stampUrl: hasStamp(signature) ? signature.stampUrl : undefined
  };
  json = await generateSignCanvas(config,signature,json,tipo,handleclose,handleFirmasPDF)
  return json;
}

function hasStamp(signature) {
  return signature.detailType.id === 1 || signature.detailType.id === 3 || signature.detailType.id === 4
}

function init(parentId, viewport) {
  fabricCanvas = new fabric.Canvas(parentId, {
    selection: false,
  });
  var bg = fabricCanvas.toDataURL("image/png");
  fabricCanvas.setHeight(viewport.height);
  fabricCanvas.setWidth(viewport.width);
  fabricCanvas.on("object:modified", boundSignatureRect);
  fabricCanvas.on("mouse:up", onMouseUp);
  fabricCanvas.setBackgroundImage(
    bg,
    fabricCanvas.renderAll.bind(fabricCanvas)
  );
}

const firmar = (json, tipo,handleClose, handleFirmasPDF)=>{

  
  if(tipo == 'nuevo'){
    firmarRRHH(json).then((response) => {

        handleFirmasPDF('RRHH')
        handleClose(response)
    });
  }else if(tipo == 'firmado'){
    firmarRL(json).then((response) => {
      handleFirmasPDF('RL')
      handleClose(response)
      
    });
  }else if(tipo == 'Pendientes'){
    firmaEmpleado(json).then((response) => {
      handleClose(response)
    });
  }
  
}

function add(config) {
  config.left = config.left !== undefined ? config.left : 350;
  config.top = config.top !== undefined ? config.top : 100;
  config.width = config.width !== undefined ? config.width : 100;
  render(config);
}

function render(config) {
  //  $rootScope.$emit('render:start');

  if (!_.isEmpty(config.stampUrl)) {
    renderStamp(config);
  } else {
    renderTextAndGroup(config);
  }
}

function renderStamp(config) {
  var objects = [];
  fabric.Image.fromURL(config.stampUrl, function (img) {
    objects.push(img.scale(1.0));
    renderTextAndGroup(config, objects);
  });
}

function renderTextAndGroup(config, objects) {
  objects = objects ? objects : [];
  renderText(config, objects);
  renderGroup(config, objects);
  //    $rootScope.$emit('render:end');
}

function renderText(config, objects) {
  var img = _.find(objects, function (obj) {
    return obj.get("type") === "image";
  });
  if (_.isEmpty(config.text)) return;
  var text = new fabric.Text(config.text, {
    top: img ? img.height : 0,
    fontFamily: "Courier New",
    fontSize: img ? img.width / 10 : 16,
    textAlign: "center",
    fill: "#000000",
  });

  if (img) {
    if (img.width < text.width) {
      img.left = Math.abs(img.width - text.width) / 2;
    } else {
      text.left = Math.abs(img.width - text.width) / 2;
    }
  }

  objects.push(text);
}

function renderGroup(config, objects) {
  var groupConfig = {
    left: config.left,
    top: config.top,
    borderColor: config.borderColor,
    cornerColor: config.borderColor,
    hasRotatingPoint: false,
    lockUniScaling: true,
    transparentCorners: false,
    borderScaleFactor: 2,
    hasControls: config.hasControls !== undefined ? config.hasControls : true,
  };

  var group = new fabric.Group(objects, groupConfig);
  if (!objects.length > 1) {
    arrange(group, config);
  } else {
    group.scaleToWidth(config.width, true);
  }
  fabricCanvas.add(group);
  //fabricCanvas.setActiveObject(group);
  fabricCanvas.renderAll();
  groups.push({ id: config.id, wrapped: group });
}

async function generateSignCanvas(config,signature,json,tipo,handleClose, handleFirmasPDF){
  config.left = config.left !== undefined ? config.left : 150;
  config.top = config.top !== undefined ? config.top : 100;
  config.width = config.width !== undefined ? config.width : 100;
  var objects = [];
  if(config.stampUrl){
      const imgResult = await loadImageAsync(config.stampUrl);
      objects.push(imgResult);
      json = await serviceInternalFunction(objects,config,signature,json,tipo,handleClose, handleFirmasPDF);
      return json;
  }else{
      json = await serviceInternalFunction(objects,config,signature,json,tipo,handleClose, handleFirmasPDF);
      return json;
  }
}

async function loadImageAsync(url) {
  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(url, (imgResult) => {
      if (imgResult) {
        resolve(imgResult.scale(1.0));
      } else {
        reject(new Error("No se pudo cargar la imagen"));
      }
    });
  });
}

async function serviceInternalFunction(objects, config,signature,json,tipo,handleClose, handleFirmasPDF){
  var img = await  _.find(objects, function(obj) { return obj.get('type') === 'image' });
  var text = new fabric.Text(config.text, {
      top: img ? img.height : 0,
      fontFamily: 'Courier New',
      fontSize: img ? img.width/10 : 16,
      textAlign: 'center',
      fill: '#000000',
      //originX: 'center',
      //originY: 'center'
  });
  if(img){
      if(img.width<text.width){
          img.left = Math.abs(img.width-text.width)/2
      }else{
          text.left = Math.abs(img.width-text.width)/2
      }
  }
  objects.push(text);
  var groupConfig = {
      borderColor: config.borderColor,
      cornerColor: config.borderColor,
      hasRotatingPoint: false,
      lockUniScaling: true,
      transparentCorners: false,
      borderScaleFactor: 2,
      hasControls: config.hasControls !== undefined ? config.hasControls : true
  };
  var group = new fabric.Group(objects, groupConfig);
  var base64 = "";
  if(localStorage.getItem("proveedorFirma") == EFIRMA){
    base64 =  group.toDataURL({format: 'png'})
  }else{
    base64 =  group.toDataURL({format: 'png'}).replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
  }
  json.appearance = await stringifyForIscert(signature,base64);
 return json;
}
/**
 * Organiza los objetos dentro del grupo dado, con una disposición (layout)
 * por defecto.
 ***/
function arrange(group, config) {
  var text = _.find(group.getObjects(), function (obj) {
    return obj.get("type") === "text";
  });
  var stamp = _.find(group.getObjects(), function (obj) {
    return obj.get("type") === "image";
  });

  if (text && stamp) {
    text.fontSize = 40;
    // Tamaño de imagen fijado para poder colocar texto debajo de la misma
    stamp.scaleToWidth(500, true);
    group.scaleToWidth(config.width, true);
    //Imagen de sello centrada hacia arriba
    stamp.top = -group.height / 2 + stamp.height / 3;
    //Texto centrado debajo de imagen
    text.top = stamp.oCoords.bl.y;
  } else if (stamp && !text) {
    group.scaleToWidth(config.width, true);
  }

  if (isOutBound(group) || isNaN(group.left) || isNaN(group.top)) {
    group.left = 150;
    group.top = 100;
  }
}

function update(config, group) {
  var boundingRect = group.wrapped.getBoundingRect();
  config.left = boundingRect.left;
  config.top = boundingRect.top;
  config.width = boundingRect.width;
  fabricCanvas.remove(group.wrapped);
  groups = _.reject(groups, function (item) {
    return item.id === group.id;
  });
  render(config);
}

export function remove(id) {
  var group = _.find(groups, function (group) {
    return group.id === id;
  });

  if (group) {
    fabricCanvas.remove(group.wrapped);
    groups = _.reject(groups, function (item) {
      return item.id === id;
    });
  }

  return group;
}

function isEmpty() {
  return groups.length === 0;
}

function createOrReload(config) {
  var group = _.find(groups, function (group) {
    return group.id === config.id;
  });

  if (group) {
    reload(config, group);
  } else {
    add(config);
  }
}

function setActive(id) {
  var group = _.find(groups, function (group) {
    return group.id === id;
  });

  if (group) {
    fabricCanvas.setActiveObject(group.wrapped);
    fabricCanvas.renderAll();
  }
}

function contains(id) {
  return (
    _.find(groups, function (group) {
      return group.id === id;
    }) !== undefined
  );
}

function getActive() {
  var id = undefined;
  var group = _.find(groups, function (group) {
    return group.wrapped === fabricCanvas.getActiveObject();
  });

  if (group) {
    id = group.id;
  }

  return id;
}

export const get = (id) => {
  var group = _.find(groups, function (group) {
    return group.id === id;
  });
  if (!group) return undefined;
  var boundingRect = group.wrapped.getBoundingRect();
  return {
    id: group.id,
    borderColor: group.wrapped.borderColor,
    canvas: {
      width: fabricCanvas.getWidth(),
      height: fabricCanvas.getHeight(),
    },
    width: boundingRect.width,
    height: boundingRect.height,
    top: boundingRect.top,
    left: boundingRect.left,
    coords: group.wrapped.oCoords,
  };
};

function toDataURL(id) {
  var group = _.find(groups, function (group) {
    return group.id === id;
  });
  if (!group) return undefined;
  return group.wrapped
    .toDataURL({ format: "png" })
    .replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
}

function clear() {
  groups = [];

  if (fabricCanvas) {
    fabricCanvas.clear();
  }
}

function destroy() {
  clear();
  fabricCanvas = undefined;
}

function onSelect(scope, callback) {
  subscribe("group:selected", scope, callback);
}

function onRenderStart(scope, callback) {
  subscribe("render:start", scope, callback);
}

function onRenderEnd(scope, callback) {
  subscribe("render:end", scope, callback);
}

function subscribe(event, scope, callback) {
  //  var handler = $rootScope.$on(event, callback);
  // $scope.$on('$destroy', handler);
}
