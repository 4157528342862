export const SET_LOADING = "SET_LOADING";
export const SET_PROCESSES = "SET_PROCESSES";
export const SET_CONFIG_TEST = "SET_CONFIG_TEST";
export const SET_CONFIG_PROD = "SET_CONFIG_PROD";
export const SET_USERS = "SET_USERS";
export const SET_ROLES = "SET_ROLES";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_SESSION_DATA = "SET_SESSION_DATA";
export const SET_EMPRESAS = "SET_EMPRESAS";
export const GET_SESSION_DATE = "GET_SESSION_DATE";
export const SET_TIPO_DOCUMENTO = "SET_TIPO_DOCUMENTO";  
export const SET_FORMATO_DOCUMENTO =  "SET_FORMATO_DOCUMENTO";  
export const SET_NUMERO_PATRONAL = "SET_NUMERO_PATRONAL";
export const SET_APLICACION_EXTERNA = "SET_APLICACION_EXTERNA";
export const SET_USUARIO = "SET_USUARIO";
export const SET_EMPRESAS_USER = "SET_EMPRESAS_USER";
export const SET_EMPRESA_SELECT = "SET_EMPRESA_SELECT";
export const SET_LOTES = "SET_LOTES";
export const SET_RECIBOS = "SET_RECIBOS"; 
export const SET_REPORTE_USUARIO = "SET_REPORTE_USUARIO"
export const SET_REPORTE_RECIBOS = "SET_REPORTE_RECIBOS"
export const SET_SIDE_BAR = "SET_SIDE_BAR"     
export const SET_GRUPOS = "SET_GRUPOS"
export const SET_PROVEEDORES = "SET_PROVEEDORES"             