import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import "../../styles/Empresa.css";
import { Link } from "react-router-dom";
import Navbar from "../home/Navbar";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import { getReportesUsuarios } from './ReportesService'
import {reporteRechazado } from '../../constans/constants';
import visibility from '../../styles/icons/visibility.svg';

class ReportesRechazados extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReportesEmpleados: {
        usuario: "",
        identificador: "",
        nombres: "",
        apellidos: "",
        cedula: "",
        email: "",
        id: "",
      },
      dialogIsOpen: false,
      mostrarMensajeFlash: false,
      open: false,
      mensajeFlash: "",
      severity: ""
    };
    localStorage.setItem("urlRaiz", window.location.href);
    this.tableRef = React.createRef();

  }
  handleDialog = (value, mensaje = "", severity = "") => {
    if (value === false) {
      this.tableRef.current.onQueryChange();  //Para actualizar la grilla
      this.setState({
        mensajeFlash: mensaje,
        severity: severity
      });
      if (mensaje != "") {
        this.setState({
          mostrarMensajeFlash: true
        });
      }
    }
    this.setState({ dialogIsOpen: value });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }
  handleImportarLote = () => {
    this.handleDialog(true);
    this.tableRef.current.onQueryChange();
  }
  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };



  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
            <div className="sectionHeader">
              <h1 className="pageTitle"> Recibos Rechazados</h1>
            </div>
            <Navbar
              menuActual={window.location.hash}
              seccionActual={'reportes'}
            />
            <div className="contentPage card" style={{ padding: '0px' }}>
              <MaterialTable
                title="Recibos Por Colaborador"
                style={{ borderRadius: ".5rem" }}
                tableRef={this.tableRef}
                columns={[
                  { title: "Nombres y Apellidos", field: "nombreApellido" },
                  { title: "Usuario", field: "usuario" },
                  { title: "C.I.", field: "cedula" },
                  { title: "Cantidad", field: "cantidadRecibo" },
                ]}
                data={(query) =>

                  new Promise((resolve, reject) => {
                    this.props.getReportesUsuarios(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.reporteUsuarioData.reporteUsuario,
                        page: this.props.reporteUsuarioData.page,
                        totalCount: this.props.reporteUsuarioData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  rowData => (
                    {
                      icon: () => <Link to={{ pathname: '/reporte/'+rowData.nombreApellido+'/recibos/'+rowData.id +'/estado/'+ reporteRechazado }}
                        style={{ textDecoration: 'none' }}><img src={visibility} /></Link>,
                      tooltip: 'Ver',
                      onClick: (rowData) => {
                      }
                    })
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  toolbar: true,
                  detailPanelColumnAlignment: "right",
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold"
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },
                }}
              />
            </div>
            <MensajeFlash
              mensaje={this.state.mensajeFlash}
              severity={this.state.severity}
              open={this.state.mostrarMensajeFlash}
              handleClose={this.handleCloseMensajeFlash} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getReportesUsuarios: (page, size, search) => dispatch(getReportesUsuarios(page, size, search))
});
const mapStateToProps = (state) => ({
  reporteUsuarioData: state.reporteUsuarioData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportesRechazados);
