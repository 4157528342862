import React, { useState, forwardRef } from "react";
import logo from "../../images/Logo.svg";
import RoutesAdmin from "../../RoutesAdmin";
import RoutesUsu from "../../Routes";
import { connect } from "react-redux";
import { verificarRol, admin } from "../../constans/constants";
import avatar from "../../images/avatar.png";
import { logout } from "../login/SesionService";
import { Link, useLocation } from "react-router-dom";
import Cookies from 'universal-cookie';
import { actualizaPassword } from '../usuario/UsuarioService';
import { Modal, Button } from "react-bootstrap";
import DatosUsuarioModal from "../usuario/DatosUsuarioModal";
import { username,mostrarModificarContrasenha } from "../../constans/constants";
import { esSesionAdmin } from "../usuario/Usuario";
import logoutIcon from '../../styles/icons/logout.svg'

const Routes = [];

export function tipoNavigation(props) {
  if (admin()) {
    return RoutesAdmin;
  } else {
    return RoutesUsu;
  }
}


function Navigator(props) {
  const cookies = new Cookies();
  const [menu, setMenu] = useState({});
  const [modalDatosUsuario, setModalDatosUsuario] = React.useState(false);
  const { className, classes, ...other } = props;
  const location = useLocation();
  const Routes = tipoNavigation(props.sessionData);
  const handleLogout = () => {
    logout(props.sessionData.username);
  };
  const nomreUsuario = () => {
    if (props.sessionData.admin) {
      return <p >{cookies.get('username')}</p>;
    } else {
      return (
        <p >{props.empresasUser.nombreCompleto}</p>
      );
    }
  };
  const urlLocation = (raiz) => {
    if (location.pathname.includes(raiz)) {
      return "sidebar-item active";
    } else {
      return "sidebar-item";
    }
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalDatosUsuario}
        onHide={()=> setModalDatosUsuario(false)}
        keyboard="false">
        <DatosUsuarioModal
          nombreUsuario={username()}
          handleClose={()=> setModalDatosUsuario(false)}
          dialogTitle='Datos de usuario'
        />
      </Modal>

      <nav className={props.sideBar ? "sidebar" : "sidebar collapse"}>
        <div className="navContent">
          <a href="/" className="brand">
            <img src={logo} className="img-fluid" alt="Logotipo MiRecibo"></img>
          </a>
          <ul className="sidebar-nav">
            {Routes.map(({ id, url, rol, raiz, icon }) => {
              if (verificarRol(props.roles, rol)) {
                return (<li className={urlLocation(raiz)}>
                  <Link to={url} className="sidebar-link">
                    {/*  <MaterialIcon icon={icon}/> */}
                    {id}
                  </Link>
                </li>)
              }
            })}
          </ul>
          <div className="sidebar-footer">
            <div className="user-dropdown dropup">
              <a
                className="profile-item dropdown-toggle"
                id="dropdownUser1"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="profile-photo">
                  <img
                    onClick={() => !esSesionAdmin() && setModalDatosUsuario(true)}
                    src={avatar}
                    className="img-fluid"
                    alt="Foto de perfil"
                    type="Button"
                  ></img>
                </div>
                <div className="profile-name">
                  <span className="name"> {nomreUsuario()}</span>

                  {mostrarModificarContrasenha?<a type="button" style={{ color: '#1565D8' }} onClick={() => actualizaPassword()}> Cambiar contraseña </a>:null}

                </div>
              </a>
            </div>

            <a type="Button" onClick={logout} className="sidebar-link">
              <span className="link-icon material-icons-outlined"> <img src={logoutIcon}></img> </span>
              Cerrar sesión
            </a>
          </div>
        </div>
      </nav>
    </>

  );
}

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
  roles: state.rolesData,
  empresasUser: state.empresaUser,
  sideBar: state.sideBar
});
export default connect(mapStateToProps)(Navigator);
