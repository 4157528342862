import axios from 'axios';
import Cookies from 'universal-cookie';
import {
    SET_REPORTE_USUARIO,
    SET_REPORTE_RECIBOS,
    SET_LOADING,
  } from "../../constans/action-types";
  import {URL,getAuthorizationHeader, getAuthorizationHeaderUpload,getEstadoURLReporte} from '../../constans/constants';
  import { logout } from '../login/SesionService';

  const cookies = new Cookies();
  const defaultPage = 0;
  const defaultSize = 5;
  export const setReporteUsuarios = payload => ({ type: SET_REPORTE_USUARIO, payload });
  export const setReporteRecibo = payload => ({ type: SET_REPORTE_RECIBOS, payload });
  export const setLoading = payload => ({ type: SET_LOADING, payload });

  export const getReportesUsuarios = (page = defaultPage,size = defaultSize, search) => {
    return dispatch => {
      var urlRequest = ""
      var idEmpresa = cookies.get('empresa');
      var estado = getEstadoURLReporte();
    if((search == null || search == '') && (idEmpresa != null) && (estado == null || estado== '')){
        urlRequest = URL+"reportes/listar/recibosEmpleados?page=" + page+"&size=" + size +"&idEmpresa="+idEmpresa
      }else if(search != null && idEmpresa != null  && (estado == null || estado== '')){
        urlRequest = URL+"reportes/listar/recibosEmpleados?page=" + page+"&size=" + size +"&search=" + search+"&idEmpresa="+idEmpresa
      }else if(search != null && idEmpresa != null  && (estado != null || estado!= '')){
        urlRequest = URL+"reportes/listar/recibosEmpleados?page=" + page+"&size=" + size +"&search=" + search+"&idEmpresa="+idEmpresa+"&estado="+estado
      }
      return axios.get(urlRequest,getAuthorizationHeader())
        .then(res => {
          dispatch(setReporteUsuarios({
            page: res.data.page.pageable.pageNumber,
            totalCount: res.data.page.totalElements,
            reporteUsuario: res.data.page.content
          }));
        }, error => {
          if (error.response.status === 401) {
           logout();
          }
          return error;
        });
    };
  };

  export const getRecibos = (
    page = defaultPage,
    size = defaultSize,
    search,
    usuario,
    estado
  ) => {
    return (dispatch) => {
      var urlRequest = "";
      var idEmpresa = cookies.get("empresa");
      if ((search == null || search == "") && idEmpresa != null && (estado == null || estado== '')) {
        urlRequest =
          URL +
          "reportes/listar/recibos?page=" +
          page +
          "&size=" +
          size +
          "&idEmpresa=" +
          idEmpresa +
          "&usuario=" +
          usuario;
      } else if (search != null && idEmpresa != null && (estado == null || estado== '')) {
        urlRequest =
          URL +
          "reportes/listar/recibos?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search +
          "&idEmpresa=" +
          idEmpresa +
          "&usuario=" +
          usuario;
      }else if(search != null && idEmpresa != null  && (estado != null || estado!= '')){
        urlRequest =
        URL +
        "reportes/listar/recibos?page=" +
        page +
        "&size=" +
        size +
        "&search=" +
        search +
        "&idEmpresa=" +
        idEmpresa +
        "&usuario=" +
        usuario+"&estado="+estado;
      }
      return axios.get(urlRequest, getAuthorizationHeader()).then((res) => {
        dispatch(
          setReporteRecibo({
            page: res.data.page.pageable.pageNumber,
            totalCount: res.data.page.totalElements,
            reporteRecibo: res.data.page.content,
          })
        );
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
    };
  };
