import React from "react";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Modal, Container, Button, Row, Card, Col } from "react-bootstrap";
import { seleccionarPsa } from "./ProveedorFirmaService";
import default_psa from '../../images/default_psa.png';
import xCircle from '../../styles/icons/x-circle.svg'

class SeleccionPca extends React.Component {
  constructor(props) {
    super(props);
    if (props.listaPsa.length === 1)
      this.handleSelectPsa(props.listaPsa[0])
    else {
      this.state = {
        openPantallaCarga: false
      };
    }

  }

  handleFirmasPDF = (value) => {
    this.setState({ tipoFirma: value })
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  decodeBase64(base64data) {
    let base64ToString = Buffer.from(base64data, "base64").toString()
    return base64ToString;
  }

  handleSelectPsa = (elemento) => {
    
    localStorage.setItem("codigoClienteFirma", elemento.id)
    localStorage.setItem("proveedorFirma", elemento.codigoCliente)
    localStorage.setItem("redirectURLFirma", (this.props.url).substring(this.props.url.indexOf("#")))
    
    seleccionarPsa(elemento).then(res => {
      window.location.replace(res);
    })

  }

  handleClose = () => {
    this.props.handleClose();
  };

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  }

  render() {
    if (this.props.listaPsa.length === 0)
      return (
        <Container>
          <Modal.Body>
            <div>
              <Row className="justify-content-center">
                <Col className="text-center text-md-right">
                <img src={xCircle} style={{margin: '40px'}}></img>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="text-center text-md-right">
                  <p><strong>No se encontraron proveedores de firma activos</strong></p>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Aceptar
            </Button>
          </Modal.Footer>
        </Container>
      );
    else
      return (
        <Container>
          <PantallaCarga open={this.state.openPantallaCarga} />
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}> Seleccione Proveedor de Firma </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {this.props.listaPsa.map((elemento) => (
                <Card className="text-center" style={{ width: '18rem', height: '20rem' }}>
                  <Card.Body style={{ display: "flex", alignItems: "center" }}>
                    {
                      elemento.logo != null
                        ? <img className="mx-auto d-block" src={"data:image/jpeg;base64," + this.decodeBase64(elemento.logo)} />
                        : <img className="mx-auto d-block" src={default_psa} />
                    }
                  </Card.Body>
                  <Card.Footer>
                    <Button onClick={() => this.handleSelectPsa(elemento)} variant="primary">{elemento.descripcion}</Button>
                  </Card.Footer>
                </Card>
              ))}
            </Row>
          </Modal.Body>
        </Container>
      );
  }
}

export default SeleccionPca;
