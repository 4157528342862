
import React from 'react';
import Home from './components/home/Home'
import {BrowserRouter, HashRouter} from 'react-router-dom'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import './styles/material-icons/Material-Icons.css'
import "@fontsource/open-sans"; // Defaults to weight 400
import "@fontsource/open-sans/400.css"; // Specify weight
import "@fontsource/open-sans/400-italic.css"; // Specify weight and style
/* import '../src/styles/styles.min.css' */
import "./styles/styles.scss"
import './App.css';
import { connect } from 'react-redux';
import { verifyUserStatus } from './components/login/SesionService';


class App extends React.Component {

  constructor(props){
    super(props);
    this.props.verifyUserStatus();
  }

  render() {
    return (
      <div>
        <HashRouter>
        <Home />
        </HashRouter>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  verifyUserStatus: value => dispatch(verifyUserStatus(value))
});

const mapStateToProps = state => ({
  sessionData: state.sessionData
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
