import axios from "axios";
import { SET_RECIBOS, SET_LOADING } from "../../constans/action-types";
import {
  URL,
  getAuthorizationHeader,
  getEstadoURL,
  getEstadoReciboURL,
} from "../../constans/constants";
import Cookies from "universal-cookie";
import { stringifyForIscert } from "../tiposDocumento/AparienciaFirma"

const cookies = new Cookies();
const defaultPage = 0;
const defaultSize = 5;
export const setRecibos = (payload) => ({ type: SET_RECIBOS, payload });
export const setLoading = (payload) => ({ type: SET_LOADING, payload });

export const getRecibos = (
  page = defaultPage,
  size = defaultSize,
  search,
  idLote
) => {
  return (dispatch) => {
    var urlRequest = "";
    var idEmpresa = cookies.get("empresa");
    if (
      (search == null || search == "") &&
      (idEmpresa == null || idEmpresa == undefined)
    ) {
      urlRequest =
        URL +
        "recibo/" +
        "listar?page=" +
        page +
        "&size=" +
        size +
        "&idLote=" +
        idLote;
    } else if (
      (search != null || search != "") &&
      (idEmpresa == null || idEmpresa == undefined)
    ) {
      urlRequest =
        URL +
        "recibo/" +
        "listar?page=" +
        page +
        "&size=" +
        size +
        "&search=" +
        search +
        "&idLote=" +
        idLote;
    } else if ((search == null || search == "") && idEmpresa != null) {
      urlRequest =
        URL +
        "recibo/" +
        "listar?page=" +
        page +
        "&size=" +
        size +
        "&idEmpresa=" +
        idEmpresa +
        "&idLote=" +
        idLote;
    } else if (search != null && idEmpresa != null) {
      urlRequest =
        URL +
        "recibo/" +
        "listar?page=" +
        page +
        "&size=" +
        size +
        "&search=" +
        search +
        "&idEmpresa=" +
        idEmpresa +
        "&idLote=" +
        idLote;
    }
    return axios.get(urlRequest, getAuthorizationHeader()).then((res) => {
      dispatch(
        setRecibos({
          page: res.data.page.pageable.pageNumber,
          totalCount: res.data.page.totalElements,
          recibos: res.data.page.content,
        })
      );
    });
  };
};

export const getReciboByLoteAndId = (reciboId, loteId) => {
  return axios.get(URL + 'recibo/' + reciboId + '/lote/' + loteId + '/info', getAuthorizationHeader())
    .then(res => res.data)
}

export const getRecibosPorUsuario = (
  page = defaultPage,
  size = defaultSize,
  search
) => {
  return (dispatch) => {
    var urlRequest = "";
    var estado = getEstadoReciboURL();
    if (
      (search == null || search == "")
    ) {
      urlRequest =
        URL +
        "recibo/bandeja/" +
        "listar?page=" +
        page +
        "&size=" +
        size +
        "&estado=" +
        estado
        +"&idEmpresa="+cookies.get("empresa");
    } else if (
      (search != null || search != "")
    ) {
      urlRequest =
        URL +
        "recibo/bandeja/" +
        "listar?page=" +
        page +
        "&size=" +
        size +
        "&search=" +
        search +
        "&estado=" +
        estado+"&idEmpresa="+cookies.get("empresa");
    }
    return axios.get(urlRequest, getAuthorizationHeader()).then((res) => {
      dispatch(
        setRecibos({
          page: res.data.page.pageable.pageNumber,
          totalCount: res.data.page.totalElements,
          recibos: res.data.page.content,
        })
      );
    });
  };
};

export const firmarRRHH = async(datosFirma) => {
  const json = {
    keystoreId: datosFirma.keystore,
    pin: datosFirma.pin,
    appearance: datosFirma.appearance,
    iscertToken: localStorage.getItem("tokenIscert"),
    psa: localStorage.getItem("codigoClienteFirma")
  };
  const response = await axios
  .put(
    URL + "lote/sign/RRHH/" + datosFirma.idLote+"/"+datosFirma.idRecibo,
    json,
    getAuthorizationHeader()
  )
  return response
};

export const firmarRL = async(datosFirma) => {
  const json = {
    keystoreId: datosFirma.keystore,
    pin: datosFirma.pin,
    iscertToken: localStorage.getItem("tokenIscert"),
    psa: localStorage.getItem("codigoClienteFirma")
  };
  const response = await axios
    .put(
      URL + "lote/sign/RL/" + datosFirma.idLote+"/"+datosFirma.idRecibo,
      json,
      getAuthorizationHeader()
    )
    return response
};

export const firmaEmpleado = (datosFirma) => {
  const json = {
    keystoreId: datosFirma.keystoreId,
    pin: datosFirma.pin,
    appearance: datosFirma.appearance,
    idLote: datosFirma.idLote,
    idRecibo: datosFirma.idRecibo,
    iscertToken: localStorage.getItem("tokenIscert"),
    psa: localStorage.getItem("codigoClienteFirma")
  };
  return axios
    .post(
      URL + "recibo/signature/iscert/init",
      json,
      getAuthorizationHeader()
    )
    .then((response) => {
      return response.data;
    });
};

export const aceptarEmpleado = (lote, recibo) => {
  const json = {
    keystoreId: "",
    pin: "",
    appearance: "",
    idLote: lote,
    idRecibo: recibo
  };
  return axios
    .post(URL + "recibo/accept", json, getAuthorizationHeader())
    .then((response) => {
      return response;
    });
}

export const publicarLote = (idLote) => {
  return axios
    .put(URL + "lote/publicar/" + idLote, null, getAuthorizationHeader())
    .then((response) => {
      return response.data;
    });
};

export const aceptarRRHHLote = (idLote) => {
  return axios
    .put(URL + "lote/aceptarRRHH/" + idLote, null, getAuthorizationHeader())
    .then((response) => {
      return response.data;
    });
};

export const aceptarRLLote = (idLote) => {
  return axios
    .put(URL + "lote/aceptarRL/" + idLote, null, getAuthorizationHeader())
    .then((response) => {
      return response.data;
    });
};

export const descargarLote = (idLote) => {
  return axios
    .get(URL + "lote/descargar/" + idLote, getAuthorizationHeader())
    .then((response) => {
      return response.data;
    });
};

export const rechazarLote = (loteId, reciboId, details) => {
  const json = {
    reciboId: reciboId,
    loteId: loteId,
    details: details
  }
  return axios
    .post(URL + "recibo/reject", json, getAuthorizationHeader())
    .then((response) => {
      return response.data;
    });
}

export const enviarLoteMtess = (idlote, username) => {
  var formData = new FormData();
  formData.append("loteId", idlote)
  formData.append("username", username)
  return axios
    .post(URL + "lote/sendToMtess", formData, getAuthorizationHeader())
    .then((res) => res.data)

}

export const enviarComentarioFirma = (json) => {
  return axios
    .post(URL + "lote/sign/comment", json, getAuthorizationHeader())
    .then((res) => res.data)
}

export const getReciboComment = (loteId, idRecibo) => {
  return axios
    .get(URL + "lote/" + loteId + "/recibo/" + idRecibo + "/comment", getAuthorizationHeader())
    .then(res => res.data)
}

export const getEnviarNotificacion = (loteId) => {
  return axios
    .get(URL + "notificacion/enviarRecordatorio/" + loteId, getAuthorizationHeader())
    .then(res => res.data)
}

export const getRecibo = (loteId,idRecibo) => {
  return axios
    .get(URL + "recibo/obtener/"+loteId+"/"+idRecibo, getAuthorizationHeader())
    .then(res => res.data)
}

