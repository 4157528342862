import {
    SET_SIDE_BAR
  } from "../../constans/action-types";

export const setSideBar = payload => ({ type: SET_SIDE_BAR, payload });

export function botonSideBar(sideBar){
    return dispatch => {
        dispatch(setSideBar(!sideBar))
}}
