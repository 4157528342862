import React from "react";
import axios from 'axios';
import { URL, getAuthorizationHeader } from '../../constans/constants';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import {logout} from '../login/SesionService'


class TiposDocumentoDatos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      nombreTipDocumento: '',
      descripcion: '',
      formatoImportacion: '',
      metaDatoFormato: '',
      origenLote: '',
      firmaEmpresa: false,
      accionesEmpleado: '',
      isComentarioColaborador: false,
      tipoEnvioMtess: '',
      isCronJobActive: false,
      intervalo: 0,
      dias: 0,
      horas: 0,
      minutos: 0

    };
  }


  handleClose = () => {
    this.props.handleClose(false);
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };


  componentDidMount() {
    axios.get(URL + "tipoDocumento/obtener/" + this.props.id, getAuthorizationHeader())
      .then((res) => {
        this.setState({
          nombreTipDocumento: res.data.datos.nombre,
          descripcion: res.data.datos.descripcion,
          formatoImportacion: res.data.datos.formato.extension,
          metaDatoFormato: res.data.datos.metaFromato,
          origenLote: res.data.datos.origenLote.nombre,
          firmaEmpresa: res.data.datos.firmaEmpresa,
          accionesEmpleado: res.data.datos.firmaEmpleado.nombre,
          isComentarioColaborador: res.data.datos.employeeComment,
          tipoEnvioMtess: res.data.datos.tipoEnvioMtess,
          isCronJobActive: res.data.datos.cronJobActive,
          intervalo: res.data.datos.remindDaysAfterPublish,
          dias: res.data.datos.remindFrecuencyDays,
          horas: res.data.datos.remindFrecuencyHours,
          minutos: res.data.datos.remindFrecuencyMinutes,
        })
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      })
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };

  render() {
    return (
      <Container>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#0c56d0" }}> {this.props.dialogTitle} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col xs={12} md={6}>
                <p className="form-date">
                  {" "}
                  <strong>Datos basicos</strong>
                </p>
                <div className="info-doc">
                  <div className="title">Nombre tipo documento:</div>
                  <div className="text">{this.state.nombreTipDocumento}</div>
                </div>
                <div className="info-doc">
                  <div className="title">Descripcion: </div>
                  <div className="text">{this.state.descripcion}</div>
                </div>
                <div className="info-doc">
                  <div className="title">Formato de importacion: </div>
                  <div className="text">{this.state.formatoImportacion}</div>
                </div>
                <div className="info-doc">
                  <div className="title">Nombre de archivo C/ metadato: </div>
                  <div className="text">{this.state.metaDatoFormato}</div>
                </div>
                <div className="info-doc">
                  <div className="title">Origen lote: </div>
                  <div className="text">{this.state.origenLote}</div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <p className="form-date">
                  {" "}
                  <strong>Opciones de firma</strong>
                </p>
                <div className="info-doc">
                  <div className="title">Firma empresa:</div>
                  <div className="text">{!this.state.firmaEmpresa ? 'No' : 'Si'}</div>
                </div>
                <div className="info-doc">
                  <div className="title">Acciones del colaborador:</div>
                  <div className="text">{this.state.accionesEmpleado}</div>
                </div>
                <div className="info-doc">
                  <div className="title">Comentarios del colaborador:</div>
                  <div className="text">{!this.state.isComentarioColaborador ? 'No' : 'Si'}</div>
                </div>

                <div className="info-doc">
                  <div className="title">Tipo envio MTESS:</div>
                  <div className="text">{this.state.tipoEnvioMtess}</div>
                </div>
                {
                  this.state.isCronJobActive &&

                  <div className="info-doc">
                    <div className="title">Recordatorio de firma:</div>
                    <div className="text">
                      <ul>
                        {this.state.intervalo > 0 
                        ?
                          <li>Notificar después de {this.state.intervalo} dias de publicación</li>
                        :
                          <li>Notificar al publicar</li>
                        }
                        <li>Enviar notificacion cada {this.state.dias} dias, {this.state.horas} horas, {this.state.minutos} minutos</li>
                        
                      </ul>
                    </div>
                  </div>

                }
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Container>

    );
  }
}

export default TiposDocumentoDatos;
