import React from "react";
import { authenticateUser } from "../login/SesionService";
import { connect } from "react-redux";
import "../../styles/Login.css";
import logo from "../../images/Logo.svg";
import imgLogin from "../../images/loginImage.png"
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Form, Modal, Container, Row, Col, ModalFooter, Button } from 'react-bootstrap'
import xCircle from '../../styles/icons/x-circle.svg'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      mostrarMensaje: false,
      mensaje: "",
      openPantallaCarga: false
    };
  }
  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };

  handleAuthentication = (event) => {
    event.preventDefault();
    this.setState({ openPantallaCarga: true })
    const json = {
      username: this.state.username,
      password: this.state.password,
    };
    this.props.authenticateUser(json).then((respuesta) => {
      if (respuesta.error) {
        this.setState({
          mostrarMensaje: true,
          mensaje: respuesta.mensaje,
          openPantallaCarga: false
        });
      } else {
        this.setState({ openPantallaCarga: false })
      }
    });
  };

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensaje: false,
    });
  };

  defaultProps = {
    bgcolor: "background.paper",
    borderColor: "text.primary",
    m: 1,
    border: 1,
  };

  render() {
    return (
      <div className="loginPage">
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.mostrarMensaje}
          backdrop="static"
          keyboard="false"
        >
          <Container>
            <Modal.Body>
              <div>
                <Row className="justify-content-center">
                  <Col className="text-center text-md-right">
                  <img src={xCircle} style={{margin: '40px'}}></img>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col className="text-center text-md-right">
                    <p>{this.state.mensaje}</p>
                  </Col>
                </Row>
              </div>
              <Modal.Footer>
                <Button className="text-center" autoFocus color="primary" onClick={() => this.handleCloseMensajeFlash()}>
                  Aceptar
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Container>
        </Modal>
        <div className="content-left">
          <img src={imgLogin} className="img-fluid" alt="" />
        </div>
        <div className="content-right">
          <Form className="loginForm" onSubmit={this.handleAuthentication}>
            <img src={logo} className="img-fluid img-brand" alt="" />
            <h1> Iniciar Sesión </h1>
            <div className="form-group md-input" style={{ marginTop: "20px" }}>
              <Form.Group>
                <input
                  id="username"
                  label="Nombre de Usuario"
                  name="username"
                  autoComplete="username"
                  placeholder="Nombre de Usuario"
                  onChange={this.handleInput}
                  value={this.state.username}
                  className="form-control md-form-control"
                  required
                />
              </Form.Group>
            </div>
            <div className="form-group md-input">
              <Form.Group>
                <input
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  placeholder="Contraseña"
                  onChange={this.handleInput}
                  value={this.state.password}
                  className="form-control md-form-control"
                  required
                />
              </Form.Group>
            </div>
            <button
              type="submit"
              class="btn btn-lg btn-primary"
            >
              Ingresar
            </button>

          </Form>
          <div className="poweredby">
            <span> Producto desarrollado por
              <a href="https://www.isaportal.com.py"> ISA Paraguay </a>
            </span>
          </div>
          <PantallaCarga open={this.state.openPantallaCarga} mensaje="Iniciando sesion" />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (value) => dispatch(authenticateUser(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
