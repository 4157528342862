import { createStore } from "redux";
import { applyMiddleware, compose} from "redux";//middleware
import thunk from "redux-thunk";//middleware
import rootReducer from "../reducers/index";

//config redux without middleware
//const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

//config redux with middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;