import React from "react";
import { Form, Button, Row, Col, Container, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import logo from "../../images/Logo.svg";
import imgLogin from "../../images/login-bg.png"
import { Redirect, Link } from "react-router-dom"
import axios from "axios";
import { URL } from "../../constans/constants"
import checkCircle from '../../styles/icons/check-circle.svg'
import xCircle from '../../styles/icons/x-circle.svg'

class SuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      apellidos: "",
      username: "",
      contrasenha: "",
      confirmarContrasenha: "",
      correo: "",
      registrado: false,
      openMsgVentana: false,
      msgVentana: "",
      error: false
    };
  }
  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };
  componentDidMount() {
    axios
      .get(URL + "usuario/verificarAdministrador")
      .then((res) => {
        this.setState({ registrado: res.data.datos })
      }, error => {
        return error;
      });
  }

  handleMsgVentana = (value) => {
    this.setState({ openMsgVentana: value })
  }

  guardarSuperAdmin = (event) => {
    event.preventDefault();
    var json = {
      username: this.state.username,
      name: this.state.nombre,
      lastName: this.state.apellidos,
      email: this.state.correo,
      passwd: this.state.contrasenha
    }
    axios.post(URL + 'usuario/registrarAdministrador', json)
      .then(response => {
        if (response.data.error) {
          this.setState({
            error: true,
            openMsgVentana: true,
            msgVentana: response.data.mensaje
          })
        } else {
          this.setState({ error: false,
            registrado: true})
        }
      }, error => {
        return error;
      });
  }



  render() {
    if (this.state.registrado) {
      return (<Redirect to="/login" />)
    }
    return (
      <div className="loginPage">
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.openMsgVentana}
          backdrop="static"
          keyboard="false"
        >
          <Container>
            <Modal.Body>
              {
                <div>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                      {this.state.error ?
                        <img src={xCircle} style={{margin: '40px'}}></img>
                        :
                        <img src={checkCircle} style={{margin: '40px'}}></img>
                      }
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                      <p><strong>{this.state.msgVentana}</strong></p>
                    </Col>
                  </Row>
                </div>
              }
              <Modal.Footer>
                <Button variant="primary" onClick={() => this.handleMsgVentana(false)}>
                  Aceptar
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Container>
        </Modal>
        <div className="content-left">
          <img src={imgLogin} className="img-fluid" alt="" />
        </div>
        <div className="content-right">
          <Form className="loginForm" onSubmit={this.guardarSuperAdmin}>
            <img src={logo} className="img-fluid img-brand" alt="" />
            <h1> Registrar Administrador </h1>
            <div className="form-group md-input" style={{ marginTop: "20px" }}>
              <Form.Group >
                <Form.Label>Nombre:</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={this.state.nombre}
                  name="nombre"
                  variant="outlined"
                  onChange={this.handleInput}
                  required
                />
              </Form.Group>
            </div>
            <div className="form-group md-input">
              <Form.Group>
                <Form.Label>Apellidos:</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={this.state.apellidos}
                  name="apellidos"
                  variant="outlined"
                  onChange={this.handleInput}
                  required
                />
              </Form.Group>
            </div>
            <div className="form-group md-input">
              <Form.Group>
                <Form.Label>Nombre de Usuario:</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={this.state.username}
                  name="username"
                  variant="outlined"
                  onChange={this.handleInput}
                  required
                />
              </Form.Group>
            </div>
            <div className="form-group md-input">
              <Form.Group >
                <Form.Label>Correo electrónico:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder=""
                  className="form-control"
                  value={this.state.correo}
                  name="correo"
                  variant="outlined"
                  onChange={this.handleInput}
                  required
                />
              </Form.Group>
            </div>
            <div className="form-group md-input">
              <Form.Group>
                <Form.Label>Contraseña:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder=""
                  className="form-control"
                  value={this.state.contrasenha}
                  name="contrasenha"
                  variant="outlined"
                  onChange={this.handleInput}
                  required
                />
              </Form.Group>
            </div>
            <div className="form-group md-input">
              <Form.Group>
                <Form.Label>Confirmar Contraseña:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder=""
                  className="form-control"
                  value={this.state.confirmarContrasenha}
                  name="confirmarContrasenha"
                  variant="outlined"
                  onChange={this.handleInput}
                  required
                />
              </Form.Group>
            </div>
            <Button
              autoFocus
              color="primary"
              type="submit"
              style={{ marginTop: "20px" }}
            >
              Guardar
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

export default connect(mapStateToProps, null)(SuperAdmin);
