import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Empresa.css";
import { breadcrumBandeja, breadcrumLotes, breadcrumAdmin, breadcrumReportes, breadcrumConfig, verificarRol } from "../../constans/constants";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
    }

    setActiveLink = (actual, url, rol, rolesData) => {
        if (actual.includes(url))
            return 'nav-item active'
        else if(!verificarRol(rolesData, rol))
            return 'nav-item d-none'
        else
            return 'nav-item'
    }

    getItemsMenu = (seccionActual) => {
        switch (seccionActual) {
            case 'lotes':
                return breadcrumLotes
            case 'bandeja':
                return breadcrumBandeja
            case 'configuracion':
                return breadcrumConfig
            case 'administracion':
                return breadcrumAdmin()
            case 'reportes':
                return breadcrumReportes
        }
    }

    render() {
        var itemsMenu = this.getItemsMenu(this.props.seccionActual)
        var rolesData = JSON.parse(localStorage.getItem("roles"));
        return (
            <nav className="navbar navbar-expand-lg navbar-interno">
                <ul className="navbar-nav">
                    {
                        itemsMenu.map((item) =>
                            <li className={this.setActiveLink(this.props.menuActual, item.url, item.rol, rolesData )}>
                                <Link to={{ pathname: item.url }}
                                    style={{ textDecoration: 'none' }}><a className="nav-link">{item.nombre}</a></Link>
                            </li>

                        )
                    }
                </ul>
            </nav>
        )
    }
}
export default Navbar;